import type React from 'react';
import { useCallback } from 'react';
import { useTextEmphasis } from '../../../../../Hooks/UseTextEmphasis';
import { TextEmphasisAvailability } from '../../../../../Types/TextEmphasisAvailability';
import ItalicIcon from '../../../../General/Icon/ItalicIcon';
import { IconSize, TextEditableGroupItem } from '../../TextEditableGroup/TextEditableGroupItem/TextEditableGroupItem';

const TextEmphasisItalicEditable: React.FC = () => {
    const { isItalic, italicAvailability, setItalic } = useTextEmphasis();

    const onSelect = useCallback(() => {
        if (italicAvailability === TextEmphasisAvailability.ALLOWED) {
            setItalic(!isItalic);
        }
    }, [isItalic, italicAvailability, setItalic]);

    return (
        <TextEditableGroupItem
            active={isItalic}
            disabled={italicAvailability === TextEmphasisAvailability.DISABLED}
            label="Italic"
            iconSize={IconSize.SMALL}
            onSelect={onSelect}
        >
            <ItalicIcon />
        </TextEditableGroupItem>
    );
};

export default TextEmphasisItalicEditable;

import type React from 'react';
import { useImageUrl } from '../../../Hooks/UseImageUrl';
import CloseQuickEditorButton from '../FloatingActionBar/Button/CloseQuickEditorButton/CloseQuickEditorButton';
import { FloatingActionBar, HorizontalSlot } from '../FloatingActionBar/FloatingActionBar';
import './LoadFailed.scss';
import useTranslate from '../../../../general/Translation/hooks/UseTranslate';

interface Props {
    onClose?: () => void;
}

const LoadFailed: React.FC<Props> = ({ onClose }) => {
    const translate = useTranslate();
    const imageUrl = useImageUrl('editor/broken-pencil.png');

    return (
        <div className="app__load-failed">
            {onClose && (
                <FloatingActionBar horizontalSlot={HorizontalSlot.LEFT}>
                    <CloseQuickEditorButton onClose={onClose} />
                </FloatingActionBar>
            )}
            <img alt="Failed to load" src={imageUrl} />
            <span className="app__load-failed__text">{translate('GiftEditorWidget', 'article_unavailable')}</span>
        </div>
    );
};

export default LoadFailed;

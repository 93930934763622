import type React from 'react';
import type { PropsWithChildren } from 'react';
import './Slider.scss';

const Slider: React.FC<PropsWithChildren> = ({ children }) => (
    <div className="slider-container">
        {children}
    </div>
);

export default Slider;

import type React from 'react';
import './StagedPhoto.scss';
import { Circle } from 'rc-progress';
import type { SerializedPhoto } from '@yoursurprise/gift-editor';
import CloseIcon from '../../General/Icon/CloseIcon';
import { usePhoto } from '../../../Hooks/UsePhoto';

const StagedPhoto: React.FC<SerializedPhoto> = ({ id, isUploaded, isUploading, thumbUrl, uploadProgress }) => {
    const { abortUpload, removePhoto, selectPhoto } = usePhoto();

    const onSelect = (photoId: SerializedPhoto['id']): void => {
        if (isUploaded) {
            selectPhoto(photoId);
        }
    };

    const onRemove = (photoId: SerializedPhoto['id']): void => {
        if (isUploaded) {
            removePhoto(photoId);
        } else {
            abortUpload(photoId);
        }
    };

    return (
        <div className="user-photo photo-tile">
            <div role="button" aria-label="Toggle photo" tabIndex={0} className="user-photo__wrapper" onKeyDown={() => onSelect(id)} onClick={() => onSelect(id)}>
                <img className="user-photo__image" loading="lazy" src={thumbUrl} alt="" />
                { isUploading && <Circle className="user-photo__progress" percent={uploadProgress * 100} strokeWidth={11} strokeColor="green" />}
            </div>
            <div role="button" aria-label="Remove photo" tabIndex={0} onKeyDown={() => onRemove(id)} className="user-photo__remove" onClick={() => onRemove(id)}>
                <CloseIcon />
            </div>
        </div>
    );
};

export default StagedPhoto;

interface CacheProperties extends Record<string, number | string | null | boolean> {
    productId: number;
    variantId: number;
}

export default class InstanceProxy<Instance> {
    private cacheProperties: CacheProperties | null = null;

    private instance?: Instance | null;

    public getInstance(): Instance | undefined | null {
        return this.instance;
    }

    public getCachedInstance(cacheProperties: CacheProperties): Instance | undefined {
        if (this.cachePropertiesAreEqual(cacheProperties)) {
            return this.instance as NonNullable<Instance>;
        }

        return undefined;
    }

    public setInstance(cacheProperties: CacheProperties, instance: Instance): void {
        this.destroyInstance();
        this.cacheProperties = cacheProperties;
        this.instance = instance;
    }

    public destroyInstance(): void {
        this.cacheProperties = null;
        this.instance = null;
    }

    public getCacheProperties(): CacheProperties | null {
        return this.cacheProperties;
    }

    private cachePropertiesAreEqual(cacheProperties: CacheProperties): boolean {
        if (!this.cacheProperties) {
            return false;
        }

        return Object.keys(cacheProperties).every((cacheKey) => cacheProperties[cacheKey] === (this.cacheProperties as CacheProperties)[cacheKey]);
    }
}

import type React from 'react';
import { useCallback } from 'react';
import { useTextContent } from '../../../../Hooks/UseTextContent';
import './TextContentEditable.scss';
import { TextContentLine } from './TextContentLine';

interface TextContentEditableProps {
    autoFocusedLine?: number;
    onConfirm?: () => void;
    onFocus?: (lineIndex: number) => void;
    readOnly: boolean;
}

const TextContentEditable: React.FC<TextContentEditableProps> = ({ autoFocusedLine, onConfirm, onFocus, readOnly }) => {
    const textContentHook = useTextContent();
    const { maxCharacters, minCharacters, setTextContent, textContent } = textContentHook;

    const setTextContentLine = useCallback((index: number, newValue: string) => {
        const newTextContent = textContent.map((existingValue: string, currentIndex: number) => (currentIndex === index ? newValue : existingValue));

        setTextContent(newTextContent);
    }, [textContent, setTextContent]);

    return (
        <div className="text-content">
            {textContent.map((textContentLine, index) => (
                <TextContentLine
                    // eslint-disable-next-line react/no-array-index-key
                    key={String(index)}
                    autoFocus={index === autoFocusedLine && !readOnly}
                    onFocus={() => onFocus && onFocus(index)}
                    onConfirm={onConfirm}
                    readOnly={readOnly}
                    minLength={minCharacters}
                    maxLength={maxCharacters}
                    textContent={textContentLine}
                    onChange={(value: string) => setTextContentLine(index, value)}
                />
            ))}
        </div>
    );
};

export default TextContentEditable;

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { GalleryState } from './state';

interface GalleryAction extends UnknownAction {
    payload: string;
    type: GalleryActionType;
}

export enum GalleryActionType {
    ACTIVE_GALLERY_SLIDE_SET = 'ACTIVE_GALLERY_SLIDE_SET',
    GALLERY_LOADED = 'GALLERY_LOADED',
}

type GalleryLoadedPayload = Pick<GalleryState, | 'thumbs' | 'issuuId' | 'media'>;
export type ActiveGallerySlideSetPayload = { position: number };

export const activeGallerySlideSet = createAction<ActiveGallerySlideSetPayload, GalleryActionType>(GalleryActionType.ACTIVE_GALLERY_SLIDE_SET);
export const galleryLoaded = createAction<GalleryLoadedPayload, GalleryActionType>(GalleryActionType.GALLERY_LOADED);

export type { GalleryAction, GalleryLoadedPayload };

import type React from 'react';
import { useEffect, useRef } from 'react';
import './Composer.scss';
import type QuickEditorApiContext from '../quickEditor/Provider/QuickEditorApiProvider/QuickEditorApiContext';
import { useGiftEditorApi } from './Hooks/UseGiftEditorApi';
import type UpsellEditorApiContext from '../checkout/Components/Upsell/Editor/UpsellEditorApiContext';

interface Props {
    context: typeof QuickEditorApiContext | typeof UpsellEditorApiContext;
}

const Composer: React.FC<Props> = ({ context }) => {
    const composer = useRef<HTMLDivElement>(null);
    const { setElement } = useGiftEditorApi(context);

    useEffect(() => {
        if (composer.current) {
            composer.current.innerHTML = '';

            setElement(composer.current);
        }
    }, [setElement]);

    return <div className="composer" ref={composer} data-clarity-mask="True"/>;
};

export default Composer;

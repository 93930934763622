import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { Hit } from 'instantsearch.js';
import type { AlgoliaDesignHit } from '../../Components/DesignGalleryBlock/DesignGalleryBlockVersionB';
import type { ProductState } from './state';
import type { Version } from '../MetaData/state';

export interface ProductAction extends UnknownAction {
    payload: string;
    type: ProductActionType;
}

export enum ProductActionType {
    ALGOLIA_DESIGN_RESULTS_LOADED = 'ALGOLIA_DESIGN_RESULTS_LOADED',
    CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED = 'CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED',
    DESIGN_SELECTED = 'DESIGN_SELECTED',
    DESIGN_SELECTED_CLEARED = 'DESIGN_SELECTED_CLEARED',
    PRODUCT_LOADED = 'PRODUCT_LOADED',
    PRODUCT_SWITCHED = 'PRODUCT_SWITCHED',
    UPDATE_EDITOR_TYPE = 'UPDATE_EDITOR_TYPE',
    UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON = 'UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON',
}

export const canBeCombinedWithOtherProductsInCartLoaded = createAction<boolean, ProductActionType>(ProductActionType.CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED);
export const productLoaded = createAction<ProductState, ProductActionType>(ProductActionType.PRODUCT_LOADED);
export const productSwitched = createAction<void, ProductActionType>(ProductActionType.PRODUCT_SWITCHED);
export const updateHasBlockedCombinedProductButton = createAction<boolean, ProductActionType>(ProductActionType.UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON);
export const updateEditorType = createAction<void, ProductActionType>(ProductActionType.UPDATE_EDITOR_TYPE);

export type DesignSelectedPayload = { algoliaData: { designHit: Hit<AlgoliaDesignHit>; testVersion: Version }; designId: number };
export const designSelected = createAction<DesignSelectedPayload, ProductActionType>(ProductActionType.DESIGN_SELECTED);
export const designSelectedCleared = createAction<void, ProductActionType>(ProductActionType.DESIGN_SELECTED_CLEARED);

export type AlgoliaDesignResultsLoadedPayload = { hits: Array<Hit<AlgoliaDesignHit>>; index: string; queryID: string | undefined };
export const algoliaDesignResultsLoaded = createAction<AlgoliaDesignResultsLoadedPayload, ProductActionType>(ProductActionType.ALGOLIA_DESIGN_RESULTS_LOADED);

import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './List.module.scss';

interface ListProps {
    children: ReactNode | ReactNode[];
    gap?: 'base' | 'default';
    isHorizontalDesktop?: boolean;
    isHorizontalMobile?: boolean;
}

const Item: React.FC<PropsWithChildren> = ({ children }) => <li className={styles.List__item}>{children}</li>;

const List: React.FC<ListProps> & { Item: typeof Item } = (
    {
        children,
        gap,
        isHorizontalDesktop,
        isHorizontalMobile,
    },
) => <ul className={classNames(
    styles.List,
    { [styles.List_gap_base as string]: gap === 'base' },
    { [styles.List_gap_default as string]: gap === 'default' },
    { [styles.List_horizontal as string]: isHorizontalMobile || isHorizontalDesktop },
    { [styles.List_horizontal_mobile as string]: isHorizontalMobile },
    { [styles.List_horizontal_desktop as string]: isHorizontalDesktop },
)}>
    {children}
</ul>;

List.Item = Item;

export default List;

export enum Environment {
    BROWSER = 'BROWSER',
    NODE = 'NODE',
}

interface EnvironmentState {
    environment: Environment;
}

const initialEnvironmentState: EnvironmentState = {
    environment: Environment.NODE,
};

export type { EnvironmentState };
export default initialEnvironmentState;

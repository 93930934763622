import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTextFont } from '../../../../Hooks/UseTextFont';
import Slider from '../../../General/Slider/Slider';
import './TextFontEditable.scss';
import { TextFontTile } from './TextFontTile';
import { useTypedSelector } from '../../../../../productInformation/Store/connectors';

const TextFontEditable: React.FC = () => {
    const fontConstraints = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.constraints?.font);
    const availableFonts = useMemo(() => fontConstraints?.availableFonts || [], [fontConstraints]);
    const { setTextFontId, textFontId } = useTextFont();
    const [firstFont] = useState<number>(textFontId);

    const sortedFonts = useCallback(() => {
        const fonts = [...availableFonts];
        fonts.sort((a, b) => Number(b.id === firstFont) - Number(a.id === firstFont));
        return fonts;
    }, [availableFonts, firstFont]);

    if (!fontConstraints?.allowed || availableFonts.length === 0) {
        return null;
    }

    const tiles = sortedFonts().map((font) => (
        <TextFontTile
            key={font.id}
            font={font}
            active={font.id === textFontId}
            onSelect={() => setTextFontId(font.id)}
        />
    ));

    return (
        <div className="text-font-tiles">
            <Slider>
                {tiles}
            </Slider>
        </div>
    );
};

export default TextFontEditable;

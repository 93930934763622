import type { RemoveNotificationEvent, ShowNotificationEvent } from '@yoursurprise/gift-editor';
import { createReducer } from '@reduxjs/toolkit';
import { initialNotificationState } from './state';
import { addNotification, clearNotifications, removeNotification, setNotificationVisibility } from './action';

const createUid = (event: Pick<ShowNotificationEvent | RemoveNotificationEvent, 'canvasId' | 'graphicId' | 'type' | 'id'>) => `${event.canvasId}${event.graphicId}${event.type}${event.id}`;

export const notificationReducer = createReducer(initialNotificationState, (builder) => {
    builder.addCase(addNotification, (state, { payload }) => ({
        ...state,
        notifications: [...state.notifications.filter(({ uid }) => uid !== createUid(payload)), {
            ...payload,
            uid: createUid(payload),
        }],
    }));

    builder.addCase(removeNotification, (state, { payload }) => ({
        ...state,
        notifications: state.notifications.filter(({ uid }) => uid !== createUid(payload)),
    }));

    builder.addCase(clearNotifications, (state) => ({ ...state, notifications: [] }));
    builder.addCase(setNotificationVisibility, (state, { payload }) => ({
        ...state,
        visible: payload,
    }));
});

import type { GiftEditorApi } from '@yoursurprise/gift-editor';
import '@yoursurprise/gift-editor/dist/css/GiftEditorApi.css';
import type React from 'react';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { editorTypeSet } from '../../../productInformation/Store/MetaData/action';
import { EditorType } from '../../../productInformation/Store/MetaData/state';
import { getGiftEditorApi } from '../../../gift-editor-api/Factory/GiftEditorApiFactory';
import { getPreviewGiftEditorApi } from '../../../gift-editor-api/Factory/GiftEditorApiPreviewFactory';
import QuickEditorApiContext from './QuickEditorApiContext';
import { useTypedDispatch, useTypedSelector } from '../../../productInformation/Store/connectors';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

interface Props {
    loadFailed?: JSX.Element | false;
    loader?: JSX.Element | false;
    previewMode?: boolean;
}

const QuickEditorApiProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    loadFailed = false,
    loader = false,
    previewMode = false,
}) => {
    const [giftEditorApi, setGiftEditorApi] = useState<GiftEditorApi | undefined>();
    const cart = useTypedSelector((state) => state.cart);
    const product = useTypedSelector((state) => state.product);
    const yimp = useTypedSelector((state) => state.personalisation.yimp);
    const dispatch = useTypedDispatch();
    const [loadingHasFailed, setLoadingHasFailed] = useState<boolean>(false);

    useEffect(() => {
        if (product.quickEditorActive) {
            const giftEditorApiFactory = previewMode ? getPreviewGiftEditorApi(product, cart, yimp) : getGiftEditorApi(product, cart);

            giftEditorApiFactory
                .then((api) => setGiftEditorApi(api))
                .catch((err: Error) => {
                    setLoadingHasFailed(true);
                    ErrorLogger.createFromGlobals()?.log(err);
                    dispatch(editorTypeSet(EditorType.ADVANCED_EDITOR));
                });
        }
    }, [cart, product, yimp, previewMode, dispatch]);

    if (loadingHasFailed) {
        return loadFailed || null;
    }

    if (!giftEditorApi) {
        return loader || null;
    }

    return (
        <QuickEditorApiContext.Provider value={giftEditorApi}>
            {children}
        </QuickEditorApiContext.Provider>
    );
};

export default QuickEditorApiProvider;

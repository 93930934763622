export enum ShippingMethodType {
    DEFAULT_METHOD = 'defaultMethod',
    EXPRESS_METHOD = 'expressMethod',
    PICKUP_POINT_METHOD = 'pickupPointMethod',
}

interface ShippingMethod {
    additionalInfoAvailable: boolean;
    method: ShippingMethodType;
}

export interface DeliveryMethod {
    categoryId: number;
    deadline: string;
    deadlineText: string;
    deliveryOptionId: number;
    deliveryText: string;
    deliveryTypeId: number;
    firstDeliveryMoment: string;
    lastDeliveryMoment: string;
    salePrice: string;
    salePriceFormatted: string;
    shippingServiceProvider: number;
    stStringName: string;
    traceable: number;
    trackingText: string;
}
export interface DeliveryInformation {
    deadlineText: string;
    defaultMethod: DeliveryMethod | null;
    expressMethod: DeliveryMethod | null;
    pickupPointMethod: DeliveryMethod | null;
    replaceClass: string;
    shippingCostsText: string;
}

type ShippingState = {
    deadlineText: string;
    deliveryInformationLoaded: boolean;
    hasFreeCoolerNotification: boolean;
    hasPaidCoolerNotification: boolean;
    hasTemperatureNotification: boolean;
    isDelayed: boolean;
    isOutOfStock: boolean;
    isSendByEmail: boolean;
    isSoldOut: boolean;
    methods: Array<ShippingMethod & Partial<DeliveryMethod>>;
    outOfStockMessage: string | null;
    shippingCostsText: string;
    showShippingBlockTime: boolean;
    testDateTime: string | null;
} & DeliveryInformation;

const initialShippingState: ShippingState = {
    deadlineText: '',
    defaultMethod: null,
    deliveryInformationLoaded: false,
    expressMethod: null,
    hasFreeCoolerNotification: false,
    hasPaidCoolerNotification: false,
    hasTemperatureNotification: false,
    isDelayed: false,
    isOutOfStock: false,
    isSendByEmail: false,
    isSoldOut: false,
    methods: [],
    outOfStockMessage: null,
    pickupPointMethod: null,
    replaceClass: '',
    shippingCostsText: '',
    showShippingBlockTime: true,
    testDateTime: null,
};

export type { ShippingMethod, ShippingState };
export default initialShippingState;

import { createReducer } from '@reduxjs/toolkit';
import {
    designSelected,
    designSelectedCleared,
    productLoaded,
    algoliaDesignResultsLoaded,
    canBeCombinedWithOtherProductsInCartLoaded,
    updateHasBlockedCombinedProductButton,
} from './action';
import initialProductState from './state';

const productReducer = createReducer(initialProductState, (builder) => {
    builder.addCase(canBeCombinedWithOtherProductsInCartLoaded, (state, { payload }) => (
        {
            ...state,
            strongAlcoholCheck: {
                ...state.strongAlcoholCheck,
                canBeCombinedWithOtherProductsInCart: payload,
                canBeCombinedWithOtherProductsInCartRequestLoaded: true,
            },
        }
    ));
    builder.addCase(productLoaded, (state, { payload }) => (
        {
            ...state,
            ...payload,
            strongAlcoholCheck: {
                ...state.strongAlcoholCheck,
                ...payload.strongAlcoholCheck,
                canBeCombinedWithOtherProductsInCart: state.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart,
            },
        }
    ));

    builder.addCase(designSelected, (state, { payload }) => (
        {
            ...state,
            selectedDesignId: payload.designId,
        }
    ));

    builder.addCase(designSelectedCleared, (state) => (
        {
            ...state,
            selectedDesignId: undefined,
        }
    ));

    builder.addCase(updateHasBlockedCombinedProductButton, (state, { payload }) => (
        {
            ...state,
            hasBlockedCombinedProductButton: payload,
        }
    ));

    builder.addCase(algoliaDesignResultsLoaded, (state, { payload }) => (
        {
            ...state,
            algoliaHits: payload.hits,
            algoliaIndexName: payload.index,
            algoliaQueryId: payload.queryID,
        }
    ));
});

export default productReducer;

import React, { useEffect, Suspense } from 'react';
import UseVimeo from './UseVimeo';
import type { VideoPlayerOptions } from './Video';

export interface VimeoVideoProps extends VideoPlayerOptions {
    vimeoId: string;
}

const Video = React.lazy(() => import('./Video'));

const VimeoVideo: React.FC<VimeoVideoProps> = ({
    aspectRatio,
    autoplay,
    controls,
    fluid,
    loop,
    muted,
    responsive,
    vimeoId,
}) => {
    const { getVideoSources, videoSources } = UseVimeo();

    useEffect(() => {
        getVideoSources(vimeoId);
    }, [vimeoId, getVideoSources]);

    if (videoSources) {
        return <Suspense fallback={null}>
            <Video
                videoSources={videoSources}
                aspectRatio={aspectRatio}
                autoplay={autoplay}
                controls={controls}
                fluid={fluid}
                loop={loop}
                muted={muted}
                responsive={responsive}
            />
        </Suspense>;
    }

    return <div data-testid="vimeo-player"/>;
};

export default VimeoVideo;

import { createAction } from '@reduxjs/toolkit';
import type { RemoveNotificationEvent, ShowNotificationEvent } from '@yoursurprise/gift-editor';

export enum NotificationActionType {
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    SET_NOTIFICATION_VISIBILITY = 'SET_NOTIFICATION_VISIBILITY',
}

export const addNotification = createAction<ShowNotificationEvent, NotificationActionType>(NotificationActionType.ADD_NOTIFICATION);
export const clearNotifications = createAction<void, NotificationActionType>(NotificationActionType.CLEAR_NOTIFICATIONS);
export const removeNotification = createAction<RemoveNotificationEvent, NotificationActionType>(NotificationActionType.REMOVE_NOTIFICATION);
export const setNotificationVisibility = createAction<boolean, NotificationActionType>(NotificationActionType.SET_NOTIFICATION_VISIBILITY);

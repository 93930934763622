import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Navigation } from 'swiper/modules';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperOptions } from 'swiper/types';
import { getImageUrl } from '../../../../quickEditor/Hooks/UseImageUrl';
import { useEnvironment } from '../../../Hooks/UseEnvironment';
import { useSwiperNavigation } from '../../../Hooks/UseSwiperNavigation';
import { activeGallerySlideSet } from '../../../Store/Gallery/action';
import type { ProductPageRootState } from '../../../Store/store';
import { useTypedDispatch, useTypedSelector } from '../../../Store/connectors';

interface Props {
    orientation: 'horizontal' | 'vertical';
    showMobileQuickEditorPreview: boolean;
}

const selector = (showMobileQuickEditorPreview: boolean) => createSelector(
    ({ gallery }: ProductPageRootState) => gallery.activeGallerySlide,
    ({ meta }: ProductPageRootState) => meta.language,
    ({ meta }: ProductPageRootState) => meta.cdnUrl,
    ({ gallery }: ProductPageRootState) => gallery.thumbs,
    ({ product }: ProductPageRootState) => product.productTitle,
    (activeGallerySlide, language, cdnUrl, thumbs, productTitle) => {
        const quickEditorPreviewThumbImageSrc = getImageUrl(cdnUrl, `quickeditor/qe_thumb_logo-${language}.jpg`);

        const galleryThumbs = [...thumbs];
        if (showMobileQuickEditorPreview) {
            galleryThumbs.unshift({
                url: quickEditorPreviewThumbImageSrc,
            });
        }

        return { activeGallerySlide, productTitle, thumbs: galleryThumbs };
    },
);

const ImageThumbGallery: FC<Props> = ({ orientation, showMobileQuickEditorPreview }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const { isBrowser } = useEnvironment();
    const { activeGallerySlide, productTitle, thumbs } = useTypedSelector(selector(showMobileQuickEditorPreview));
    const { showNavigation } = useSwiperNavigation(thumbs.length, 6, swiperInstance);
    const dispatch = useTypedDispatch();

    const prevSlideButtonClass = orientation === 'vertical' ? 'swiperVerticalPrev' : 'swiperHorizontalPrev';
    const nextSlideButtonClass = orientation === 'vertical' ? 'swiperVerticalNext' : 'swiperHorizontalNext';

    useEffect(() => {
        if (swiperInstance && swiperInstance.realIndex !== activeGallerySlide) {
            swiperInstance?.slideTo(activeGallerySlide);
        }
    }, [swiperInstance, activeGallerySlide]);

    const slidePrev = useCallback(() => {
        const prevSlide = activeGallerySlide - 1;

        if (prevSlide >= 0) {
            dispatch(activeGallerySlideSet({ position: prevSlide }));
        }
    }, [dispatch, activeGallerySlide]);

    const slideNext = useCallback(() => {
        const nextSlide = activeGallerySlide + 1;
        const lastSlide = thumbs.length - 1;

        if (nextSlide <= lastSlide) {
            dispatch(activeGallerySlideSet({ position: nextSlide }));
        }
    }, [dispatch, activeGallerySlide, thumbs.length]);

    const prevSlideButton = (
        <button type="button" onClick={ slidePrev } className={ prevSlideButtonClass } aria-label="Previous image">
            <i className={ classNames({ 'fa-light fa-chevron-left': orientation === 'horizontal', 'fa-light fa-chevron-up': orientation === 'vertical' }) }/>
        </button>
    );

    const nextSlideButton = (
        <button type="button" onClick={ slideNext } className={ nextSlideButtonClass } aria-label="Next image">
            <i className={ classNames({
                'fa-light fa-chevron-down': orientation === 'vertical',
                'fa-light fa-chevron-right': orientation === 'horizontal',
            }) }
            />
        </button>
    );

    const swiperConfig: SwiperOptions = {
        direction: orientation,
        modules: [Navigation],
        slidesPerView: 'auto',
    };

    if (showNavigation) {
        swiperConfig.navigation = {
            enabled: isBrowser,
            nextEl: `.${nextSlideButtonClass}`,
            prevEl: `.${prevSlideButtonClass}`,
        };
    }

    const onThumbSelect = (index: number) => dispatch(activeGallerySlideSet({ position: index }));

    return (
        <section aria-label="Gallery thumbnails">
            <Swiper onSwiper={setSwiperInstance} className={ classNames('swiper-container gallerySliderThumbsContainer', { noNavigation: !showNavigation }) } { ...swiperConfig }>
                { thumbs.map(({ url }, index) => (
                    <SwiperSlide
                        key={ `${url}-${String(index)}` }
                        role="button"
                        tabIndex={ 0 }
                        onClick={ () => onThumbSelect(index) }
                        className={ activeGallerySlide === index ? 'slideActive' : '' }
                    >
                        <img width="58" height="58" loading="lazy" src={ url } alt={ productTitle } title={ productTitle }/>
                    </SwiperSlide>
                )) }
                { showNavigation && nextSlideButton }
                { showNavigation && prevSlideButton }
            </Swiper>
        </section>
    );
};

export default ImageThumbGallery;

import type React from 'react';
import type { CombinedArticleAttribute } from '../../../../Store/CombinedArticle/state';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';

interface ImageProps {
    attribute: CombinedArticleAttribute;
    disabledValues: string[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
}

const Image: React.FC<ImageProps> = ({ attribute, disabledValues, onChange, selectedValues, valuePrices }) => {
    const translate = useTranslate();

    return (<>
        {attribute.values.map((value) => {
            let imageClassName = 'combinedArticleAttributeImage';
            if (disabledValues.includes(String(value.id))) {
                imageClassName += ' hidden';
            }

            let location = value.imgLocation;
            if (!disabledValues.includes(String(value.id)) && selectedValues[attribute.id] === value.id) {
                imageClassName += ' selected';
                location = value.imgSelectedLocation;
            }

            return (
                <div className={imageClassName} onClick={() => onChange(attribute.id, value.id)} data-testid="image" key={value.id}>
                    <div className="relative">
                        <img src={location} alt={value.name} />
                        {value.ourChoice && (
                            <div className="ourChoice">
                                {translate('productinformatie', 'ourChoice')}
                            </div>
                        )}
                    </div>
                    <div className="valueName">{value.name}</div>
                    {valuePrices[value.id] && <div className="valuePrice">{String(valuePrices[value.id]?.price)}</div>}
                </div>
            );
        })}
    </>);
};

export default Image;

import type {
    AvailableFont,
    ContentTypeEnum,
    FontEmphasisConstraints,
    TextGraphicConstraints,
} from '@yoursurprise/gift-editor';

export type GraphicConstraints = Omit<TextGraphicConstraints, 'font'> & {
    font: Omit<TextGraphicConstraints['font'], 'availableFontIds'> & {
        availableFonts: AvailableFont[];
        emphasis: FontEmphasisConstraints;
    };
};

export interface GraphicId {
    graphicId?: string;
}

export type QuickEditorGraphicState = GraphicId & {
    constraints?: GraphicConstraints;
    contentType?: ContentTypeEnum;
};

export const initialQuickEditorGraphicState: QuickEditorGraphicState = {
    constraints: undefined,
    contentType: undefined,
    graphicId: undefined,
};

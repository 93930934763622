import type { SearchClient } from 'algoliasearch';
import type { FC, ReactNode } from 'react';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-core';
import type { InstantSearchOptions } from 'instantsearch.js';

type Props = {
    algoliaSearchClient: SearchClient;
    algoliaServerState?: InstantSearchServerState;
    children: ReactNode;
    indexName: string;
} & Pick<InstantSearchOptions, 'initialUiState' | 'onStateChange' | 'routing'>;

const AlgoliaProvider: FC<Props> = ({
    algoliaSearchClient,
    algoliaServerState,
    children,
    indexName,
    initialUiState,
    onStateChange,
    routing = false,
}) => {
    const wrapWithClientProvider = () => (
        /**
             * Starting from the next major version, InstantSearch will change how widgets state is preserved when they are removed. InstantSearch will keep the state
             * of unmounted widgets to be usable by other widgets with the same attribute.
             * To stay with the current behaviour and remove this warning, set the option to false.
             * See documentation: https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-future
             */
        <InstantSearch
            routing={routing}
            searchClient={algoliaSearchClient}
            indexName={indexName}
            future={{ preserveSharedStateOnUnmount: true }}
            initialUiState={initialUiState}
            onStateChange={onStateChange}
        >
            {children}
        </InstantSearch>
    );
    // You should spread the whole server state object returned by getServerState()
    const wrapWithServerProvider = (initialResults: InstantSearchServerState) => (
        <InstantSearchSSRProvider {...initialResults}>
            {wrapWithClientProvider()}
        </InstantSearchSSRProvider>
    );

    return algoliaServerState !== undefined
        ? wrapWithServerProvider(algoliaServerState)
        : wrapWithClientProvider();
};

export default AlgoliaProvider;

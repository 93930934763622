import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ProductPageRootState, TypedDispatch } from './store';

export const useTypedDispatch = useDispatch.withTypes<TypedDispatch>();
export const useTypedSelector = useSelector.withTypes<ProductPageRootState>();
export const createTypedAsyncThunk = createAsyncThunk.withTypes<{
    dispatch: TypedDispatch;
    state: ProductPageRootState;
}>();

import type { GiftEditorApi } from '@yoursurprise/gift-editor';
import { useContext, useEffect } from 'react';
import QuickEditorApiContext from '../Provider/QuickEditorApiProvider/QuickEditorApiContext';
import { useTypedDispatch } from '../../productInformation/Store/connectors';
import { composerLoaded } from '../../productInformation/Store/QuickEditorUi/action';

export const useGiftEditorComposerListener = (): void => {
    const giftEditorApi = useContext<GiftEditorApi>(QuickEditorApiContext);
    const dispatch = useTypedDispatch();

    useEffect(() => giftEditorApi.listenForComposerFinished(() => dispatch(composerLoaded())));
};

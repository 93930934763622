import { useEffect, useMemo, useState } from 'react';
import type { Design, Theme } from '@yoursurprise/gift-editor';
import { useQuickEditorApi } from './UseQuickEditorApi';

interface UseDesigns {
    availableDesigns: Design[];
    availableThemes: Theme[];
    designId: number;
    designsBackgroundColor: string;
    setDesignId: (designId: number) => void;
}

export const useDesigns = (): UseDesigns => {
    const { designApi } = useQuickEditorApi();
    const [designId, setDesignId] = useState<number>(designApi.getDesign());

    const availableDesigns = useMemo(() => designApi.getAvailableDesigns(), [designApi]);
    const availableThemes = useMemo(() => designApi.getAvailableThemes(), [designApi]);
    const designsBackgroundColor = useMemo(() => designApi.getDesignsBackgroundColor(), [designApi]);

    useEffect(() => {
        if (designId !== designApi.getDesign()) {
            designApi.setDesign(designId);
        }
    }, [designApi, designId, setDesignId]);

    return {
        availableDesigns,
        availableThemes,
        designId,
        designsBackgroundColor,
        setDesignId,
    };
};

import type React from 'react';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import { useTypedSelector } from '../../../Store/connectors';
import List from '../../../../../stories/Molecules/List/List';

const Usps: React.FC = () => {
    const { usps } = useTypedSelector(({ product }) => (product));

    return (
        <div className="block block--boxed block--grey">
            <List isHorizontalDesktop gap="default">
                {usps.map((usp) => (
                    <List.Item key={usp.id}>
                        <Icon style='fa-thin' name="fa-badge-check" size="fa-xl" className="c-primary" />
                        <span dangerouslySetInnerHTML={{ __html: usp.content }}/>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default Usps;

import type React from 'react';
import classNames from 'classnames';
import './ActionBar.scss';
import type { PropsWithChildren } from 'react';

const ActionBar: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
    <div className={classNames('action-bar', className)}>
        {children}
    </div>
);

export default ActionBar;

import type React from 'react';
import ActionBar from '../../../../ActionBar/ActionBar';
import ActionButton from '../../../../ActionBar/ActionButton/ActionButton';
import TextContentEditable from '../../../TextContentEditable/TextContentEditable';
import useTranslate from '../../../../../../../general/Translation/hooks/UseTranslate';

interface Props {
    autoFocusedLine?: number;
    onClose: () => void;
}

const TextControlsFullScreenDisplay: React.FC<Props> = ({ autoFocusedLine, onClose }) => {
    const appDoneTranslation = useTranslate()('GiftEditorWidget', 'app_done') || 'Done';

    return (
        <>
            <ActionBar>
                <ActionButton label={appDoneTranslation} onClick={onClose}>
                    {appDoneTranslation}
                </ActionButton>
            </ActionBar>
            <div className="text-controls__fullscreen">
                <TextContentEditable autoFocusedLine={autoFocusedLine} readOnly={false} onConfirm={onClose} />
            </div>
        </>
    );
};

export default TextControlsFullScreenDisplay;

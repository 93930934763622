import { useEffect, useState } from 'react';
import type {
    FailedPhotos,
    SerializedPhoto,
    StagedPhotos,
} from '@yoursurprise/gift-editor';
import { useQuickEditorApi } from './UseQuickEditorApi';

interface UsePhotoCollection {
    failedPhotos: FailedPhotos;
    hasFailedPhotos: () => boolean;
    hasStagedPhotos: () => boolean;
    stagedPhotos: StagedPhotos;
}

export const usePhotoCollection = (): UsePhotoCollection => {
    const { photoApi } = useQuickEditorApi();

    const usedPhotos = photoApi.getUsedPhotos();
    const [failedPhotos, failedPhotosDispatch] = useState<FailedPhotos>(usedPhotos.failed);
    const [stagedPhotos, stagedPhotosDispatch] = useState<StagedPhotos>(usedPhotos.staged);

    const hasFailedPhotos = () => failedPhotos.size > 0;
    const hasStagedPhotos = () => stagedPhotos.size > 0;

    useEffect(() => {
        const removePhotoUploadListener = photoApi.listenToUploadChanges(
            (photos) => {
                failedPhotosDispatch(photos.failed);
                stagedPhotosDispatch(photos.staged);
            },
            (photo: SerializedPhoto) => {
                const currentStagedPhotos = new Map(photoApi.getUsedPhotos().staged);
                currentStagedPhotos.set(photo.id, photo);
                stagedPhotosDispatch(currentStagedPhotos);
            },
        );

        return () => removePhotoUploadListener();
    }, [photoApi]);

    return {
        failedPhotos,
        hasFailedPhotos,
        hasStagedPhotos,
        stagedPhotos,
    };
};

import classNames from 'classnames';
import type { MutableRefObject } from 'react';
import React, { useState } from 'react';
import { PrimaryCtaButton } from '../../../Store/UI/state';
import { useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import ButtonStack from '../../../../general/Button/ButtonStack';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../Store/connectors';

const AddProductToCartButton = React.forwardRef<HTMLElement, { asPrimary?: boolean } >(({ asPrimary }, buttonRef) => {
    const productId = useTypedSelector(({ product }) => product.productId);
    const variantId = useTypedSelector(({ product }) => product.variantId);
    const primaryCtaButton = useTypedSelector(({ ui }) => ui.primaryCtaButton);
    const canBeCombinedWithOtherProductsInCart = useTypedSelector(({ product }) => product.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart);
    const personalisationText = useTypedSelector(({ ui }) => ui.personalisationText);
    const selectedDesignId = useTypedSelector(({ product }) => product.selectedDesignId);
    const yimpDesignYimpId = useTypedSelector(({ product }) => product.algoliaHits?.find((hit) => hit.designId === selectedDesignId)?.yimpDesignYimpId);

    const withSiteUrl = useWithSiteUrl();
    const [isAddingToCart, setIsAddingToCart] = useState(false);

    return (
        <form
            name="add-product"
            method="POST"
            action={withSiteUrl('/checkout/addProductToCart')}
            onSubmit={() => setIsAddingToCart(true)}
        >
            <input type="hidden" name="productId" value={productId} />
            {personalisationText && yimpDesignYimpId
                ? <>
                    <input type="hidden" name="personalisationText" value={personalisationText} />
                    <input type="hidden" name="yimpDesignYimpId" value={yimpDesignYimpId} />
                </>
                : null
            }

            {variantId > 0 && (
                <input type="hidden" data-testid="variant-id" name="variantId" value={variantId} />
            )}
            <ButtonStack isFluid isMd>
                <button
                    ref={buttonRef as MutableRefObject<HTMLButtonElement>}
                    type="submit"
                    disabled={isAddingToCart}
                    className={classNames('button', {
                        'button--ghost': !asPrimary && primaryCtaButton !== PrimaryCtaButton.AddToCart,
                        'button--happy-path': asPrimary || primaryCtaButton === PrimaryCtaButton.AddToCart,
                        disabled: !canBeCombinedWithOtherProductsInCart || isAddingToCart,
                    })}
                >
                    <span>
                        <Translation pageString="productinformatie" stringId="in_winkelwagen" />
                    </span>
                </button>
            </ButtonStack>
        </form>
    );
});

export default AddProductToCartButton;

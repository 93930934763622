import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedSelector } from '../../productInformation/Store/connectors';

interface UseTextContent {
    maxCharacters: number;
    maximumLines: number;
    minCharacters: number;
    setTextContent: (textContent: string[]) => void;
    textContent: string[];
}

export const useTextContent = (): UseTextContent => {
    const { textApi } = useQuickEditorApi();
    const graphicId = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.graphicId);
    const initialTextContent = useMemo(() => {
        if (graphicId) {
            const emptyMaximumLines = [...Array<string>(textApi.getTextGraphic(graphicId).getMaximumLines())].map(() => '');
            return emptyMaximumLines.map((value: string, index: number) => textApi.getTextGraphic(graphicId).getTextLines()?.[index] || value);
        }

        return [];
    }, [textApi, graphicId]);

    const [textContent, setInMemoryTextContent] = useState<string[]>(initialTextContent);

    useEffect(() => setInMemoryTextContent(initialTextContent), [initialTextContent]);

    const setTextContent = useCallback((newTextContent: string[]) => {
        setInMemoryTextContent(newTextContent);
        textApi.getActiveTextGraphic().setTextLines(newTextContent);
    }, [textApi]);

    return {
        maxCharacters: textApi.getActiveTextGraphic().getMaxCharacters(),
        maximumLines: textApi.getActiveTextGraphic().getMaximumLines(),
        minCharacters: textApi.getActiveTextGraphic().getMinCharacters(),
        setTextContent,
        textContent,
    };
};

import type React from 'react';

const AlignRightIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 42" width="39" height="42">
        <path
            d="M5.6 34.1c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7l27.7-.1c1 0 1.7.8 1.7 1.7 0 1-.8
            1.7-1.7 1.7l-27.7.1zM33.4 26.4H12.8c-1 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7h20.6c1 0 1.7.8 1.7 1.7s-.8 1.7-1.7
            1.7zM33.4 18.9H7c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7h26.4c1 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7zM33.4
            11.3H19.8c-1 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7h13.6c1 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7z"
        />
    </svg>
);

export default AlignRightIcon;

import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { Slider as BaseSlider } from '@yoursurprise/slider';
import type { SliderTypes } from '@yoursurprise/slider';
import '@yoursurprise/slider/dist/index.css';
import './Slider.scss';

type BaseSliderSettings = Parameters<typeof BaseSlider>[0] & { key?: string | number | null };

const Slider = forwardRef<SliderTypes.API, PropsWithChildren<BaseSliderSettings>>(({ children, ...props }, ref) => (
    <BaseSlider {...props} ref={ref}>
        {children}
    </BaseSlider>
));

export default Slider;

import type { TypedMiddleware } from '../store';
import { productPageLoaded } from '../UI/action';
import { getGiftEditorApi } from '../../../gift-editor-api/Factory/GiftEditorApiFactory';
import { cartEditPersonalisationLoaded } from '../Personalisation/action';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

export const cartEditMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (productPageLoaded.match(action)) {
        const { cart, product } = storeApi.getState();

        if (cart.isCartEdit) {
            getGiftEditorApi(product, cart)
                .then((api) => {
                    storeApi.dispatch(cartEditPersonalisationLoaded({
                        isPersonalised: api.isPersonalized(),
                        yimp: api.getYimp(),
                    }));
                })
                .catch((error: Error) => ErrorLogger.createFromGlobals()?.log(error));
        }
    }

    next(action);
};

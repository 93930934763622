import { createAction } from '@reduxjs/toolkit';
import type { ControlsDisplayOption } from './state';

export enum QuickEditorUiActionType {
    COMPOSER_LOADED = 'COMPOSER_LOADED',
    OVERLAY_DISPLAY_SET = 'OVERLAY_DISPLAY_SET',
}

export const overlayDisplaySet = createAction<ControlsDisplayOption, QuickEditorUiActionType>(QuickEditorUiActionType.OVERLAY_DISPLAY_SET);
export const composerLoaded = createAction<void, QuickEditorUiActionType>(QuickEditorUiActionType.COMPOSER_LOADED);

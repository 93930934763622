import type { GiftEditorApi } from '@yoursurprise/gift-editor';
import type React from 'react';
import { useContext } from 'react';
import { useGiftEditorComposerListener } from '../../Hooks/UseGiftEditorComposerListener';
import { useGiftEditorConstraintsListener } from '../../Hooks/UseGiftEditorConstraintsListener';
import { useGiftEditorGraphicFocusListener } from '../../Hooks/UseGiftEditorGraphicFocusListener';
import { useGiftEditorNotificationsListener } from '../../Hooks/UseGiftEditorNotificationsListener';
import { useGiftEditorPersonalisationListener } from '../../Hooks/UseGiftEditorPersonalisationListener';
import QuickEditorApiContext from '../../Provider/QuickEditorApiProvider/QuickEditorApiContext';
import MobileQuickEditor from '../Mobile/App/MobileQuickEditor';
import './QuickEditor.scss';
import { useTypedDispatch } from '../../../productInformation/Store/connectors';
import { finishedPersonalising } from '../../../productInformation/Store/Personalisation/action';

interface Props {
    onClose: () => void;
}

const QuickEditor: React.FC<Props> = ({ onClose }) => {
    const dispatch = useTypedDispatch();
    const giftEditorApi = useContext<GiftEditorApi>(QuickEditorApiContext);

    useGiftEditorConstraintsListener();
    useGiftEditorGraphicFocusListener();
    useGiftEditorNotificationsListener();
    useGiftEditorPersonalisationListener();
    useGiftEditorComposerListener();

    const onQuickEditorClose = () => {
        dispatch(finishedPersonalising({
            isPersonalised: giftEditorApi.isPersonalized(),
            yimp: giftEditorApi.getYimp(),
        }));

        onClose();
    };

    return <MobileQuickEditor onClose={onQuickEditorClose} />;
};

export default QuickEditor;

import type React from 'react';
import { useEffect, useState } from 'react';
import TapInstructor from '../../General/TapInstructor/TapInstructor';
import DesignPicker from '../DesignPicker/DesignPicker';
import AddToCartButton from '../FloatingActionBar/Button/AddToCartButton/AddToCartButton';
import CloseQuickEditorButton from '../FloatingActionBar/Button/CloseQuickEditorButton/CloseQuickEditorButton';
import NotificationButton from '../FloatingActionBar/Button/NotificationButton/NotificationButton';
import { FloatingActionBar, HorizontalSlot } from '../FloatingActionBar/FloatingActionBar';
import NotificationOverlay from '../NotificationOverlay/NotificationOverlay';
import ControlsOverlay from '../Overlay/ControlsOverlay';
import './MobileQuickEditor.scss';
import Composer from '../../../../gift-editor-api/Composer';
import QuickEditorApiContext from '../../../Provider/QuickEditorApiProvider/QuickEditorApiContext';
import { useTypedSelector } from '../../../../productInformation/Store/connectors';

interface Props {
    onClose: () => void;
}

const MobileQuickEditor: React.FC<Props> = ({ onClose }) => {
    const graphicId = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.graphicId);
    const [isEditingGraphic, setIsEditingGraphic] = useState<boolean>(false);
    const [hasEditedGraphic, setHasEditedGraphic] = useState<boolean>(false);

    useEffect(() => {
        setIsEditingGraphic(Boolean(graphicId));
        if (graphicId) {
            setHasEditedGraphic(true);
        }
    }, [graphicId]);

    return (
        <>
            <div className="app__composer-container">
                <Composer context={QuickEditorApiContext} />
                {!isEditingGraphic && !hasEditedGraphic && <TapInstructor />}
            </div>
            {!isEditingGraphic && (
                <>
                    <FloatingActionBar horizontalSlot={HorizontalSlot.LEFT}>
                        <CloseQuickEditorButton onClose={onClose} />
                    </FloatingActionBar>
                    <FloatingActionBar horizontalSlot={HorizontalSlot.RIGHT}>
                        <AddToCartButton />
                        <NotificationButton />
                    </FloatingActionBar>
                </>
            )}
            {!isEditingGraphic && <DesignPicker />}
            {isEditingGraphic && <ControlsOverlay />}
            <NotificationOverlay />
        </>
    );
};

export default MobileQuickEditor;

import type { GiftEditorApi, GiftEditorApiOptions } from '@yoursurprise/gift-editor';
import { window } from '../../../js/globals';
import GiftEditorApiProxy from '../Proxy/GiftEditorApiProxy';
import { getGiftEditorApiOptions } from './GiftEditorApiOptionsFactory';

const giftEditorApiProxy = new GiftEditorApiProxy();

export interface GiftEditorCart {
    editHash: string | null;
    isCartEdit: boolean;
    uniqueId: string | null;
}

export interface GiftEditorProduct {
    productId: number;
    variantId: number;
}

export const getGiftEditorApi = async (product: GiftEditorProduct, cart: GiftEditorCart, allowPersonalisationTransfer = true): Promise<GiftEditorApi> => {
    const cacheProperties = {
        editHash: cart.editHash,
        isCartEdit: cart.isCartEdit,
        productId: product.productId,
        uniqueId: cart.uniqueId,
        variantId: product.variantId,
    };

    const proxyInstance = giftEditorApiProxy.getCachedInstance(cacheProperties);

    if (proxyInstance) {
        return proxyInstance;
    }

    const createNewInstance = async (): Promise<GiftEditorApi> => {
        const existingPersonalisation = allowPersonalisationTransfer ? (await giftEditorApiProxy.getInstance())?.getExistingPersonalisation() : undefined;

        const apiOptions = await getGiftEditorApiOptions(cacheProperties, cart, existingPersonalisation);

        const options: GiftEditorApiOptions = { ...apiOptions };

        const { GiftEditorApiFactory } = await import('@yoursurprise/gift-editor');

        return new GiftEditorApiFactory((window as Window).siteMetadata.siteUrl).createFromApiOptions(options);
    };

    const newInstance = createNewInstance();

    giftEditorApiProxy.setInstance(cacheProperties, newInstance);

    return newInstance;
};

import type React from 'react';
import OneFingerTapIcon from '../Icon/OneFingerTapIcon';
import './TapInstructor.scss';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../../productInformation/Store/connectors';

const TapInstructor: React.FC = () => {
    const loaded = useTypedSelector(({ quickEditorUi }) => quickEditorUi.composer.loaded);

    if (!loaded) {
        return null;
    }

    return (
        <div className="instruction">
            <div className="instruction__content">
                <OneFingerTapIcon />
                <span className="instruction__content__text">
                    <Translation pageString="GiftEditorWidget" stringId="tap_instructor_text" />
                </span>
            </div>
        </div>
    );
};

export default TapInstructor;

import type React from 'react';

const BoldIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.935 36" width="27" height="36">
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 425" fill="none" d="M0 0h26.935v36H0z" />
            </clipPath>
        </defs>
        <g data-name="Group 607" clipPath="url(#a)">
            <path
                data-name="Path 7"
                d="M0 0h13.244c7.2 0 12.235 3.225 12.235 9.419a8.8 8.8 0 01-4.179 7.65v.1c4.127 1.263 5.635 4.935 5.635
                8.1C26.935 33.132 20.391 36 13.244 36H0zm13.341 13.944a3.041 3.041 0
                003.173-3.318c0-1.661-.953-3.073-3.322-3.073h-4.38v6.391zm.756 14.5a3.576 3.576 0 003.825-3.777A3.457
                3.457 0 0014.149 21H8.812v7.449z"
                fill="#161615"
            />
        </g>
    </svg>
);

export default BoldIcon;

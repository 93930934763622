import type React from 'react';

const AlignCenterIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 42" width="39" height="42">
        <path
            d="M5.6 34.1c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7l27.7-.1c1 0 1.7.8 1.7 1.7 0 1-.8 1.7-1.7
            1.7l-27.7.1zM29.8 26.4H9.2c-1 0-1.7-.8-1.7-1.7S8.3 23 9.2 23h20.6c1 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7zM32.7
            18.9H6.3c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7h26.4c1 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7zM26.3 11.3H12.7c-1
            0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7h13.6c1 0 1.7.8 1.7 1.7s-.7 1.7-1.7 1.7z"
        />
    </svg>
);

export default AlignCenterIcon;

import type { SearchResults } from 'algoliasearch-helper';
import type { BaseHit, Hit } from 'instantsearch.js';
import type { FC } from 'react';
import { useConfigure, useInstantSearch } from 'react-instantsearch-core';
import useHasAnalytics from '../../../algolia/general/Analytics/useHasAnalytics';
import ImageGalleryBlock from '../GalleryBlock/ImageGalleryBlock';
import IssuuPreview from '../IssuuPreview/IssuuPreview';
import DesignThumbGallery from './DesignThumbGallery/DesignThumbGallery';
import { Version } from '../../Store/MetaData/state';
import ProductAmountGalleryLabel from './ProductAmountGalleryLabel';
import DesignGallery from './DesignGallery/DesignGallery';
import { useTypedSelector } from '../../Store/connectors';
import useFixAnalytics from '../../../algolia/general/Analytics/useFixAnalytics';

export interface AlgoliaDesignHit extends BaseHit {
    designId: number | null;
    designPath: string;
    imageUrl: string;
    thumbUrl: string;
    yimpDesignYimpId: number;
}

/**
 * This gallery has been set up as an A/B Test for the following articles: 93027, 93028, 93079 and 93072.
 *
 * The articles named above only have basic photos of the front view. In the A/B test they will not have atmospheric photos.
 *
 * Different views of the same design/article are not supported within this gallery, nor are videos. There is also no support for the quick editor.
 * For this reason this component should not be re-used outside of this A/B test and not be extended to other articles.
 *
 * This component (Version A) renders the standard image gallery, but instead of the configured images we'll show the designs ranked by Algolia.
 * We'll do this to have a pure comparison with Version B where we show the same designs and images.
 */
const DesignGalleryBlockVersionA: FC = () => {
    const productId = useTypedSelector(({ product }) => product.productId);
    const locale = useTypedSelector(({ meta }) => meta.locale.replace('-', '_'));
    const { results: untypedResults, status } = useInstantSearch();
    const results = untypedResults as SearchResults<Hit<AlgoliaDesignHit>>;
    const hasAnalytics = useHasAnalytics();

    useFixAnalytics();

    useConfigure({
        analytics: hasAnalytics,
        analyticsTags: ['version_a'],
        clickAnalytics: hasAnalytics,
        facetFilters: [`productId:${productId}`, `locale:${locale}`, 'isB2C:true'],
        hitsPerPage: 12,
    });

    const isFetching = status === 'loading' || status === 'stalled';
    const isErroneous = status === 'error';
    const isCompleted = status === 'idle';

    if (isFetching) {
        return null;
    }

    if (isErroneous) {
        return <ImageGalleryBlock />;
    }

    if (isCompleted && !results?.nbHits) {
        return <ImageGalleryBlock />;
    }

    return (
        <div id="galleryBlock" className="galleryBlock product-information__gallery">
            <div className="productPageImageGallery">
                <div className={'productPageImageGalleryThumbs noSelectHighlight'}>
                    {results.nbHits !== 1 && (
                        <DesignThumbGallery algoliaResults={results} testVersion={Version.A} />
                    )}
                </div>
                <div className="productPageImageGallery__container">
                    <DesignGallery algoliaResults={results} version={Version.A} />
                    <IssuuPreview/>
                    <ProductAmountGalleryLabel/>
                </div>
            </div>
        </div>
    );
};

export default DesignGalleryBlockVersionA;

import type React from 'react';
import { useState } from 'react';
import ControlsDisplayWrapper from '../../Display/ControlsDisplayWrapper';
import TextControlsFullScreenDisplay from './Display/TextControlsFullScreenDisplay/TextControlsFullScreenDisplay';
import TextControlsOverlayDisplay from './Display/TextControlsOverlayDisplay/TextControlsOverlayDisplay';
import './TextControls.scss';
import { useTypedDispatch, useTypedSelector } from '../../../../../productInformation/Store/connectors';
import { overlayDisplaySet } from '../../../../../productInformation/Store/QuickEditorUi/action';
import { ControlsDisplayOption } from '../../../../../productInformation/Store/QuickEditorUi/state';

const TextControls: React.FC = () => {
    const {
        currentDisplay,
        prevDisplay,
    } = useTypedSelector(({ quickEditorUi }) => quickEditorUi.overlay);
    const dispatch = useTypedDispatch();
    const [autoFocusedLine, setAutoFocusedLine] = useState<number>(0);

    const setDisplayToOverlay = () => dispatch(overlayDisplaySet(ControlsDisplayOption.OVERLAY));
    const setDisplayToFullscreen = (lineIndex: number) => {
        setAutoFocusedLine(lineIndex);
        dispatch(overlayDisplaySet(ControlsDisplayOption.FULLSCREEN));
    };

    if (currentDisplay === ControlsDisplayOption.FULLSCREEN) {
        return (
            <ControlsDisplayWrapper shouldZoomPreview={false} prevDisplay={prevDisplay} classNamePrefix="text-controls">
                <TextControlsFullScreenDisplay autoFocusedLine={autoFocusedLine} onClose={setDisplayToOverlay} />
            </ControlsDisplayWrapper>
        );
    }

    if (currentDisplay === ControlsDisplayOption.OVERLAY) {
        return (
            <ControlsDisplayWrapper shouldZoomPreview prevDisplay={prevDisplay} classNamePrefix="text-controls">
                <TextControlsOverlayDisplay onTextContentFocus={setDisplayToFullscreen} />
            </ControlsDisplayWrapper>
        );
    }

    return null;
};

export default TextControls;

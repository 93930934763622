import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { TranslationsState } from './state';

interface TranslationsAction extends UnknownAction {
    payload: string;
    type: TranslationsActionType;
}

export enum TranslationsActionType {
    TRANSLATIONS_LOADED = 'TRANSLATIONS_LOADED',
}

export const translationsLoaded = createAction<TranslationsState, TranslationsActionType>(TranslationsActionType.TRANSLATIONS_LOADED);

export type { TranslationsAction };

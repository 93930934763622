import { getGiftEditorApi } from '../../../gift-editor-api/Factory/GiftEditorApiFactory';
import { QuickEditorGraphicActionType, graphicConstraintsLoaded } from './action';
import { createTypedAsyncThunk } from '../connectors';

export const loadTextGraphicConstraintsThunk = createTypedAsyncThunk(QuickEditorGraphicActionType.LOAD_GRAPHIC_CONSTRAINTS, async (arg, thunkAPI) => {
    const { cart, product } = thunkAPI.getState();
    const giftEditorApi = await getGiftEditorApi(product, cart);
    const textApi = giftEditorApi.getTextApi();

    const [availableFonts, fontEmphasisConstraints] = await Promise.all([
        textApi.getActiveTextGraphic().getAvailableFonts(),
        textApi.getActiveTextGraphic().getFontEmphasisConstraints(textApi.getActiveTextGraphic().getFont()),
    ]);

    const graphicConstraints = giftEditorApi.getTextApi().getActiveTextGraphic().getConstraints();
    thunkAPI.dispatch(graphicConstraintsLoaded({
        ...graphicConstraints,
        font: {
            allowed: graphicConstraints.font.allowed,
            availableFonts,
            emphasis: fontEmphasisConstraints,
        },
    }));
});

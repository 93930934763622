import type React from 'react';
import CloseIcon from '../../../../General/Icon/CloseIcon';
import FloatingActionButton from '../../FloatingActionButton';
import './CloseQuickEditorButton.scss';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';

interface Props {
    onClose: () => void;
}

const CloseQuickEditorButton: React.FC<Props> = ({ onClose }) => {
    const translate = useTranslate();

    return (
        <FloatingActionButton label={translate('GiftEditorWidget', 'app_done') || 'Done'} className="close-quick-editor-button" onClick={onClose}>
            <CloseIcon />
        </FloatingActionButton>
    );
};

export default CloseQuickEditorButton;

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { Yimp } from '@yoursurprise/gift-editor';

interface PersonalisationAction extends UnknownAction {
    payload: string;
    type: PersonalisationActionType;
}

export enum PersonalisationActionType {
    CART_EDIT_PERSONALISATION_LOADED = 'CART_EDIT_PERSONALISATION_LOADED',
    FINISHED_PERSONALISING = 'FINISHED_PERSONALISING',
}

interface PersonalisationLoaded {
    isPersonalised: boolean;
    yimp: Yimp;
}

export const cartEditPersonalisationLoaded = createAction<PersonalisationLoaded, PersonalisationActionType>(PersonalisationActionType.CART_EDIT_PERSONALISATION_LOADED);
export const finishedPersonalising = createAction<PersonalisationLoaded, PersonalisationActionType>(PersonalisationActionType.FINISHED_PERSONALISING);

export type { PersonalisationAction };

import type React from 'react';
import { PriceDisplay } from './PriceDisplay/PriceDisplay';
import { window } from '../../../../../js/globals';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../Store/connectors';

interface Props {
    isSoldOut: boolean;
}

const PriceBlock: React.FC<Props> = ({ isSoldOut }) => {
    const dealPromotionPrice = useTypedSelector(({ product }) => product.dealPromotionPrice);
    const discountPercentage = useTypedSelector(({ product }) => product.discountPercentage);
    const hasDealPromotionShipping = useTypedSelector(({ product }) => product.hasDealPromotionShipping);
    const isDealPromotion = useTypedSelector(({ product }) => product.isDealPromotion);
    const isDiscountPrice = useTypedSelector(({ product }) => product.isDiscountPrice);
    const price = useTypedSelector(({ product }) => product.price);
    const priceFrom = useTypedSelector(({ product }) => product.priceFrom);
    const productId = useTypedSelector(({ product }) => product.productId);
    const shippingCostsText = useTypedSelector(({ shipping }) => shipping.shippingCostsText);
    const siteUrl = useTypedSelector(({ meta }) => meta.siteUrl);

    return (
        <div className="product-information-pricing">
            <div className="priceDetails">
                <PriceDisplay
                    isSoldOut={isSoldOut}
                    isDiscountPrice={isDiscountPrice}
                    isDealPromotion={isDealPromotion}
                    hasDealPromotionShipping={hasDealPromotionShipping}
                    priceFrom={priceFrom}
                    price={price}
                    dealPromotionPrice={dealPromotionPrice}
                    discountPercentage={discountPercentage}
                    siteUrl={siteUrl}
                    productId={productId}
                    shippingCostsText={shippingCostsText}
                />
            </div>

            {!isSoldOut
                && (
                    <div className="business-order">
                        <a
                            href="#giftBusinessOrders"
                            onClick={() => window?.analytics.track(
                                'Element Clicked',
                                { category: 'Product Page - Business Order', label: productId },
                            )}
                        >
                            <Translation pageString="productinformatie" stringId="zakelijk_bestellen"/>
                        </a>
                    </div>
                )}
        </div>
    );
};

export default PriceBlock;

import { useEffect } from 'react';
import { document, window } from '../../../js/globals';
import {
    quickEditorHidden,
    quickEditorShown,
    svgEditorHidden,
    svgEditorShown,
} from '../Store/UI/action';
import { useTypedDispatch, useTypedSelector } from '../Store/connectors';

export interface EditorPopStateEvent extends PopStateEvent {
    readonly state: {
        showQuickEditor: boolean;
        showSvgEditor: boolean;
    } | null;
}

export const useOnPageEditorHistoryListener = (): void => {
    const shouldShowQuickEditor = useTypedSelector(({ ui }) => ui.shouldShowQuickEditor);
    const shouldShowSvgEditor = useTypedSelector(({ ui }) => ui.shouldShowSvgEditor);

    const quickEditorIsActive = useTypedSelector(({ product }) => product.quickEditorActive);
    const svgEditorIsActive = useTypedSelector(({ product }) => product.svgEditorActive);
    const onPageEditorIsActive = quickEditorIsActive || svgEditorIsActive;

    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (onPageEditorIsActive && window && document) {
            window.history.replaceState({
                showQuickEditor: shouldShowQuickEditor,
                showSvgEditor: shouldShowSvgEditor,
            }, document.title, window.location.href);
        }
    }, [onPageEditorIsActive, shouldShowQuickEditor, shouldShowSvgEditor]);

    useEffect(() => {
        const handleHistoryChange = ({ state }: EditorPopStateEvent) => {
            const showQuickEditor = Boolean(state?.showQuickEditor);
            const showSvgEditor = Boolean(state?.showSvgEditor);

            if (quickEditorIsActive) {
                if (showQuickEditor) {
                    dispatch(quickEditorShown());
                } else {
                    dispatch(quickEditorHidden());
                }
            }

            if (svgEditorIsActive) {
                if (showSvgEditor) {
                    dispatch(svgEditorShown());
                } else {
                    dispatch(svgEditorHidden());
                }
            }
        };

        if (window && document && onPageEditorIsActive) {
            window.addEventListener('popstate', handleHistoryChange);
        }

        return () => window?.removeEventListener('popstate', handleHistoryChange);
    }, [dispatch, onPageEditorIsActive, quickEditorIsActive, svgEditorIsActive]);
};

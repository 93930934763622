import type { Theme } from '@yoursurprise/gift-editor';
import type { ChangeEvent } from 'react';
import type React from 'react';
import ChevronDownIcon from '../../General/Icon/ChevronDownIcon';
import './DesignFilter.scss';

interface DesignFilterProps {
    activeDesignCategory: number | undefined;
    handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    themes: Theme[];
}

const DesignFilter: React.FC<DesignFilterProps> = ({ activeDesignCategory, handleChange, themes }) => (
    <div className="design-filter">
        <select
            aria-label="Design category filter"
            className="design-filter__select"
            onChange={handleChange}
            value={activeDesignCategory}
        >
            {themes.map((theme) => (
                <option key={theme.id} value={theme.id}>
                    {' '}
                    {theme.name}
                    {' '}
                </option>
            ))}
        </select>
        <div className="design-filter__icon">
            <ChevronDownIcon />
        </div>
    </div>
);

export default DesignFilter;

import type { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import type { IconName, IconStyle } from '../../Atoms/Icon/Icon';
import Icon from '../../Atoms/Icon/Icon';
import styles from './NotificationBox.module.scss';

type NotificationType = 'info' | 'warning' | 'success' | 'error';

interface NotificationBoxProps {
    className?: string;
    customIcon?: IconName;
    hasDefaultIcon?: boolean;
    iconStyle?: IconStyle;
    padding?: 'p-0' | 'p-6';
    type: NotificationType;
}

const getDefaultIcon = (type: NotificationType) => {
    const iconMap: Record<NotificationType, IconName> = {
        error: 'fa-circle-xmark',
        info: 'fa-circle-info',
        success: 'fa-circle-check',
        warning: 'fa-circle-exclamation',
    };
    return iconMap[type];
};

const NotificationBox: FC<PropsWithChildren<NotificationBoxProps>> = (
    {
        children,
        className,
        customIcon,
        hasDefaultIcon = true,
        iconStyle = 'fa-solid',
        padding = 'p-6',
        type,
    },
) => <div
    role={type === 'error' ? 'alert' : 'status'}
    aria-live={type === 'error' ? 'assertive' : 'polite'}
    className={classNames(
        styles.NotificationBox,
        { [styles.NotificationBox_warning as string]: type === 'warning' },
        { [styles.NotificationBox_info as string]: type === 'info' },
        { [styles.NotificationBox_error as string]: type === 'error' },
        { [styles.NotificationBox_success as string]: type === 'success' },
        padding,
        className,
    )}>
    <div className={styles.NotificationBox__content}>
        {(hasDefaultIcon || customIcon) && (
            <div className={styles.NotificationBox__content__icon}>
                {(hasDefaultIcon && !customIcon) && <Icon name={getDefaultIcon(type)} style={iconStyle} size="fa-lg" />}
                {customIcon && <Icon name={customIcon} style={iconStyle} size="fa-lg" />}
            </div>
        )}
        <div className={styles.NotificationBox__content__message}>
            {children}
        </div>
    </div>
</div>;

export default NotificationBox;

import type React from 'react';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';
import { ctaButtonClicked, productPageLoaded } from '../../../Store/UI/action';
import useEditorType from '../../../Hooks/UseEditorType';
import { useStickyFooterButton } from '../../../Hooks/UseStickyFooterButton';
import AddPersonalisationToCartButton from '../AddPersonalisationToCartButton/AddPersonalisationToCartButton';
import NoPersonalisationWarning
    from '../AddPersonalisationToCartButton/NoPersonalisationWarning/NoPersonalisationWarning';
import AddProductToCartButton from '../AddProductToCartButton/AddProductToCartButton';
import StartPersonalisingButton from '../StartPersonalisingButton/StartPersonalisingButton';
import StickyFooterButton from '../StickyFooterButton/StickyFooterButton';
import CtaNotifications from './CtaNotifications';
import { useTypedDispatch, useTypedSelector } from '../../../Store/connectors';
import { window } from '../../../../../js/globals';

interface Props {
    isCartEdit: boolean;
    onStartPersonalising: () => void;
    showAddToCartButton: boolean;
}

const CtaButtons: React.FC<Props> = ({ isCartEdit, onStartPersonalising, showAddToCartButton }) => {
    const allowPersonalisationWarning = useTypedSelector(({ ui }) => ui.allowPersonalisationWarning);
    const isPersonalised = useTypedSelector(({ personalisation }) => personalisation.isPersonalised);
    const isPersonalizable = useTypedSelector(({ product }) => product.isPersonalizable);

    const { isQuickEditorProduct } = useEditorType();
    const dispatch = useTypedDispatch();
    const buttonRef = useRef<HTMLElement | null>(null);
    useStickyFooterButton(buttonRef);

    useEffect(() => {
        /**
         * Whenever we navigate to a persisted version of the product page (browser cache) we will reload all product info.
         * This ensures that changes from the cart are applied when navigating back to the product page from cart.
         *
         * @see https://yoursurprise.atlassian.net/browse/YW-17665
         */
        const handlePageshow = (event: PageTransitionEvent) => {
            if (event.persisted) {
                dispatch(productPageLoaded());
            }
        };

        window?.addEventListener('pageshow', handlePageshow);

        return () => window?.removeEventListener('pageshow', handlePageshow);
    }, [dispatch]);

    const onStartPersonalisingCallback = () => {
        onStartPersonalising();

        dispatch(ctaButtonClicked());
    };

    const WithStickyFooterButton: React.FC<PropsWithChildren> = ({ children }) => (
        <>
            {children}
            <StickyFooterButton isCartEdit={isCartEdit} onStartPersonalising={onStartPersonalisingCallback}/>
        </>
    );

    if (!isPersonalizable) {
        return (
            <WithStickyFooterButton>
                <CtaNotifications/>
                <AddProductToCartButton ref={buttonRef}/>
            </WithStickyFooterButton>
        );
    }

    return (
        <>
            {isQuickEditorProduct && showAddToCartButton && (
                <>
                    {allowPersonalisationWarning && !isPersonalised && <NoPersonalisationWarning/>}
                    <CtaNotifications/>
                    <AddPersonalisationToCartButton isCartEdit={isCartEdit}/>
                </>
            )}

            <WithStickyFooterButton>
                <CtaNotifications/>
                <StartPersonalisingButton ref={buttonRef} isCartEdit={isCartEdit}
                    onClick={onStartPersonalisingCallback}/>
            </WithStickyFooterButton>

        </>
    );
};

export default CtaButtons;

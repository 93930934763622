import type { CartApi } from '@yoursurprise/gift-editor';
import { window } from '../../../js/globals';
import type { Tracker } from '../Analytics/Tracker';
import { useQuickEditorTracker } from './UseQuickEditorTracker';
import { useQuickEditorApi } from './UseQuickEditorApi';
import ErrorLogger from '../../../js/error-logger/ErrorLogger';

interface UseCartApi {
    addToCart: () => Promise<void>;
}

export const addToCart = (cartApi: CartApi, tracker: Tracker | undefined): Promise<void> => {
    tracker?.personalisationConfirmed();

    return cartApi.addToCart()
        .then((response) => {
            if (response.action === 'goto' && response.goto) {
                window?.location.assign(response.goto);
            }
        });
};

export const useCartApi = (): UseCartApi => {
    const tracker = useQuickEditorTracker();
    const { cartApi } = useQuickEditorApi();

    const add = () => new Promise<void>((resolve, reject) => {
        addToCart(cartApi, tracker)
            .then(() => resolve())
            .catch((e: Error) => {
                ErrorLogger.createFromGlobals()?.log(e);
                reject();
            });
    });

    return {
        addToCart: add,
    };
};

import { useEffect, useState } from 'react';
import { window } from '../../../js/globals';
import { Environment } from '../Store/Environment/state';
import { Device, getDeviceInfo, Orientation } from '../Util/Device';
import { useTypedSelector } from '../Store/connectors';

interface UseEnvironment {
    device: Device | undefined;
    isBrowser: boolean;
    isDesktopBrowser: boolean;
    isLandscape: boolean;
    isMobileBrowser: boolean;
    isNode: boolean;
    isPortrait: boolean;
    orientation: Orientation | undefined;
}

/**
 * We should always default to the node environment as the application will be rendered on the node server first.
 * When the client application is being hydrated on the web the first render should exactly match the version that has been rendered on the node server.
 *
 * After the first render we'll update the actual environment, which will make components specifically designed for web rerender.
 *
 * @see https://reactjs.org/docs/react-dom.html#hydrate
 */
export const useEnvironment = (): UseEnvironment => {
    const newEnv = useTypedSelector(({ environment }) => environment.environment);
    const [env, setEnv] = useState<Environment>(Environment.NODE);
    const [{
        device,
        orientation,
    }, setDeviceInfo] = useState<{ device: Device; orientation: Orientation }>(getDeviceInfo());

    useEffect(() => setEnv(newEnv), [newEnv]);

    useEffect(() => {
        const onResize = () => setDeviceInfo(getDeviceInfo());

        window?.addEventListener('resize', onResize);

        return () => window?.removeEventListener('resize', onResize);
    }, []);

    return {
        device: env === Environment.BROWSER ? device : undefined,
        isBrowser: env === Environment.BROWSER,
        isDesktopBrowser: env === Environment.BROWSER && device === Device.DESKTOP,
        isLandscape: env === Environment.BROWSER && orientation === Orientation.LANDSCAPE,
        isMobileBrowser: env === Environment.BROWSER && device === Device.MOBILE,
        isNode: env === Environment.NODE,
        isPortrait: env === Environment.BROWSER && orientation === Orientation.PORTRAIT,
        orientation: env === Environment.BROWSER ? orientation : undefined,
    };
};

export type { UseEnvironment };

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { Environment } from './state';

interface EnvironmentAction extends UnknownAction {
    payload: string;
    type: EnvironmentActionType;
}

export enum EnvironmentActionType {
    ENVIRONMENT_CHANGED = 'ENVIRONMENT_CHANGED',
}

export const environmentChanged = createAction<Environment, EnvironmentActionType>(EnvironmentActionType.ENVIRONMENT_CHANGED);

export type { EnvironmentAction };

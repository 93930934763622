import type React from 'react';
import { useMemo } from 'react';
import type { StagedPhotos } from '@yoursurprise/gift-editor';
import StagedPhoto from '../StagedPhoto';

interface Props {
    stagedPhotos: StagedPhotos;
}

const PhotoCollection: React.FC<Props> = ({ stagedPhotos }) => {
    const photoViews = useMemo(() => Array.from(stagedPhotos.values()).map((photo) => (
        <StagedPhoto thumbUrl={photo.thumbUrl} key={photo.id} id={photo.id} uploadProgress={photo.uploadProgress} isUploading={photo.isUploading} isUploaded={photo.isUploaded} />
    )), [stagedPhotos]);

    if (photoViews.length === 0) {
        return null;
    }

    return (
        <>
            { photoViews }
        </>
    );
};

export default PhotoCollection;

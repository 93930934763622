import { createSelector } from '@reduxjs/toolkit';
import type { SearchResults } from 'algoliasearch-helper';
import type { Hit } from 'instantsearch.js';
import type React from 'react';
import { useState } from 'react';
import { designSelected } from '../../../Store/Product/action';
import { activeGallerySlideSet } from '../../../Store/Gallery/action';
import type { ProductPageRootState } from '../../../Store/store';
import type { AlgoliaDesignHit } from '../DesignGalleryBlockVersionB';
import { Version } from '../../../Store/MetaData/state';
import DesignThumbSliderVersionB from './DesignThumbSliderVersionB';
import DesignThumbSliderVersionA from './DesignThumbSliderVersionA';
import Translation from '../../../../general/Translation/Translation';
import { useTypedDispatch, useTypedSelector } from '../../../Store/connectors';

interface Props {
    algoliaResults: SearchResults<Hit<AlgoliaDesignHit>>;
    testVersion: Version;
}

export interface GalleryThumb {
    url: string;
    yimpDesignYimpId?: number;
}

const selector = (algoliaResults: SearchResults<Hit<AlgoliaDesignHit>>) => createSelector(
    ({ gallery }: ProductPageRootState) => gallery.activeGallerySlide,
    ({ product }: ProductPageRootState) => product.productTitle,
    (activeGallerySlide, productTitle) => {
        const thumbs = algoliaResults.hits.map<GalleryThumb>((hit) => ({
            url: hit.thumbUrl,
            yimpDesignYimpId: hit.yimpDesignYimpId,
        }));

        return { activeGallerySlide, productTitle, thumbs };
    },
);

const DesignThumbGallery: React.FC<Props> = ({ algoliaResults, testVersion }) => {
    const {
        activeGallerySlide,
        productTitle,
        thumbs,
    } = useTypedSelector(selector(algoliaResults));
    const dispatch = useTypedDispatch();
    const [initalSliderIndex] = useState<number>(activeGallerySlide);

    const onThumbSelect = (index: number) => {
        if (activeGallerySlide !== index) {
            dispatch(activeGallerySlideSet({ position: index }));
        }
        const designHit = algoliaResults.hits[index];
        if (designHit && designHit.designId && activeGallerySlide !== index) {
            dispatch(designSelected({ algoliaData: { designHit, testVersion }, designId: designHit.designId }));
        }
    };

    const imageFastlyParams = new URLSearchParams({
        'bg-color': 'fcf8f4',
        format: 'jpg',
        height: '312',
        width: '312',
    });

    return (
        <section aria-label="Design thumbnails" className="design-selection">
            {testVersion === Version.B ? (
                <>
                    <p className="attributeTitle"><Translation pageString="productinformatie" stringId="design_choice"/>
                    </p>
                    <p className="attributeText"><Translation pageString="productinformatie"
                        stringId="design_choice_text"/></p>
                    <DesignThumbSliderVersionB initialSlideIndex = {initalSliderIndex} activeGallerySlide={activeGallerySlide}
                        imageFastlyParams={imageFastlyParams} onThumbSelect={onThumbSelect} productTitle={productTitle} thumbs={thumbs}/>
                </>
            )
                : <DesignThumbSliderVersionA activeGallerySlide={activeGallerySlide} imageFastlyParams={imageFastlyParams} onThumbSelect={onThumbSelect} productTitle={productTitle} thumbs={thumbs}/>
            }
        </section>
    );
};

export default DesignThumbGallery;

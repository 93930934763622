import { isMobileLandscape, isTabletPortrait } from '../../../js/util/sizes';

export interface DeviceInfo {
    device: Device;
    orientation: Orientation;
}

export enum Device {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
}

export enum Orientation {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT',
}

export const getDeviceInfo = (): DeviceInfo => {
    if (isTabletPortrait()) {
        return {
            device: Device.MOBILE,
            orientation: Orientation.PORTRAIT,
        };
    }

    if (isMobileLandscape()) {
        return {
            device: Device.MOBILE,
            orientation: Orientation.LANDSCAPE,
        };
    }

    return {
        device: Device.DESKTOP,
        orientation: Orientation.LANDSCAPE,
    };
};

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import type { SliderTypes } from '@yoursurprise/slider';
import styles from './DesignThumbGallery.module.scss';
import Slider from '../../../../general/Slider/Slider';
import type { GalleryThumb } from './DesignThumbGallery';
import DesignThumbSliderProgressBar from '../DesignThumbSliderProgressBar/DesignThumbSliderProgressBar';

type DesignThumbSliderVersionBProps = {
    activeGallerySlide: number;
    imageFastlyParams: URLSearchParams;
    initialSlideIndex: number;
    onThumbSelect: (index: number) => void;
    productTitle: string;
    thumbs: GalleryThumb[];
};

const DesignThumbSliderVersionB: React.FC<DesignThumbSliderVersionBProps> = (
    {
        activeGallerySlide,
        imageFastlyParams,
        initialSlideIndex,
        onThumbSelect,
        productTitle,
        thumbs,
    },
) => {
    const sliderApi = useRef<SliderTypes.API>(null);
    const [firstFullyVisibleSlideIndex, setFirstFullyVisibleSlideIndex] = useState<number | undefined>(undefined);
    const [lastFullyVisibleSlideIndex, setLastFullyVisibleSlideIndex] = useState<number | undefined>(undefined);

    const onSlide = () => {
        if (sliderApi.current) {
            setFirstFullyVisibleSlideIndex(sliderApi.current.getFirstFullyVisibleSlideIndex());
            setLastFullyVisibleSlideIndex(sliderApi.current.getLastFullyVisibleSlideIndex());
        }
    };

    useEffect(() => {
        if (sliderApi.current && (activeGallerySlide < sliderApi.current.getFirstFullyVisibleSlideIndex() || activeGallerySlide > sliderApi.current.getLastFullyVisibleSlideIndex())) {
            sliderApi.current.scrollToSlide(activeGallerySlide, 'smooth');
        }
    }, [activeGallerySlide]);

    return (
        <>
            <Slider ref={sliderApi} initialSlideIndex={initialSlideIndex} onSlide={onSlide}>
                {thumbs.map((thumb, index) => (
                    <div
                        key={`${thumb.url}-${String(index)}`}
                        role="button"
                        tabIndex={0}
                        onClick={() => onThumbSelect(index)}
                        className={classNames(activeGallerySlide === index ? styles.ActiveThumb : '', styles.DesignThumb)}
                    >
                        <img
                            loading="lazy"
                            src={`${thumb.url}?${imageFastlyParams.toString()}`}
                            alt={productTitle}
                            title={productTitle}
                            draggable={false}
                        />
                    </div>
                ))}
            </Slider>
            <DesignThumbSliderProgressBar
                amountOfDesigns={thumbs.length}
                firstFullyVisibleSlideIndex={firstFullyVisibleSlideIndex}
                lastFullyVisibleSlideIndex={lastFullyVisibleSlideIndex}
            />
        </>
    );
};

export default DesignThumbSliderVersionB;

import type {
    CartApi,
    ConstraintsApi,
    DesignApi,
    FocusApi,
    GiftEditorApi,
    NotificationApi,
    PersonalisationEventsApi,
    PreviewApi,
    TextApi,
    PhotoApi,
} from '@yoursurprise/gift-editor';

import { useCallback, useContext } from 'react';
import type QuickEditorApiContext from '../../quickEditor/Provider/QuickEditorApiProvider/QuickEditorApiContext';
import type UpsellEditorApiContext from '../../checkout/Components/Upsell/Editor/UpsellEditorApiContext';

interface GiftEditorApiHook {
    cartApi: CartApi;
    constraintsApi: ConstraintsApi;
    designApi: DesignApi;
    destroy: () => void;
    focusApi: FocusApi;
    hasPersonalization: boolean;
    notificationApi: NotificationApi;
    personalisationEventsApi: PersonalisationEventsApi;
    photoApi: PhotoApi;
    previewApi: PreviewApi;
    setElement: (refElement: HTMLElement) => void;
    textApi: TextApi;
}

/**
 * Should only be used within the GiftEditorApiProvider.
 */
export const useGiftEditorApi = (context: typeof QuickEditorApiContext | typeof UpsellEditorApiContext): GiftEditorApiHook => {
    const giftEditorApi = useContext<GiftEditorApi>(context);

    const setElement = useCallback((element: HTMLElement) => {
        giftEditorApi.mount(element);
    }, [giftEditorApi]);

    return {
        cartApi: giftEditorApi.getCartApi(),
        constraintsApi: giftEditorApi.getConstraintsApi(),
        designApi: giftEditorApi.getDesignApi(),
        destroy: () => giftEditorApi.destroy(),
        focusApi: giftEditorApi.getFocusApi(),
        hasPersonalization: giftEditorApi.isPersonalized(),
        notificationApi: giftEditorApi.getNotificationApi(),
        personalisationEventsApi: giftEditorApi.getPersonalisationEventsApi(),
        photoApi: giftEditorApi.getPhotoApi(),
        previewApi: giftEditorApi.getPreviewApi(),
        setElement,
        textApi: giftEditorApi.getTextApi(),
    };
};

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { ShippingState, DeliveryInformation } from './state';

interface ShippingAction extends UnknownAction {
    payload: string;
    type: ShippingActionType;
}

export enum ShippingActionType {
    DELIVERY_INFORMATION_LOADED = 'DELIVERY_INFORMATION_LOADED',
    SHIPPING_LOADED = 'SHIPPING_LOADED',
}

export const shippingLoaded = createAction<ShippingState, ShippingActionType>(ShippingActionType.SHIPPING_LOADED);
export const deliveryInformationLoaded = createAction<DeliveryInformation, ShippingActionType>(ShippingActionType.DELIVERY_INFORMATION_LOADED);

export type { ShippingAction };

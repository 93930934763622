import classNames from 'classnames';
import type React from 'react';
import { useState } from 'react';
import CircleWarningOutlineIcon from '../../../../General/Icon/CircleWarningOutlineIcon';
import FloatingActionButton from '../../FloatingActionButton';
import './NotificationButton.scss';
import { useTypedDispatch, useTypedSelector } from '../../../../../../productInformation/Store/connectors';
import { setNotificationVisibility } from '../../../../../../productInformation/Store/Notification/action';

const notificationButtonClasses = (visibleOverlay: boolean, buttonHasBeenClicked: boolean) => classNames('notification-button', {
    'notification-button--slide-in': !visibleOverlay && buttonHasBeenClicked,
    'notification-button--slide-out': visibleOverlay,
});

const NotificationButton: React.FC = () => {
    const { notifications, visible } = useTypedSelector(({ notification }) => notification);
    const dispatch = useTypedDispatch();
    const [buttonHasBeenClicked, setButtonClicked] = useState<boolean>(false);

    if (notifications.length === 0) {
        return null;
    }

    const onClick = () => {
        setButtonClicked(true);
        dispatch(setNotificationVisibility(true));
    };

    return (
        <FloatingActionButton
            label="Warning"
            className={notificationButtonClasses(visible, buttonHasBeenClicked)}
            onClick={onClick}
        >
            <CircleWarningOutlineIcon />
        </FloatingActionButton>
    );
};

export default NotificationButton;

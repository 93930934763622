import { useEffect, useState } from 'react';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedSelector } from '../../productInformation/Store/connectors';

interface UseTextColor {
    setTextColor: (textColor: string) => void;
    textColor: string;
}

export const useTextColor = (): UseTextColor => {
    const { textApi } = useQuickEditorApi();
    const [textColor, setTextColor] = useState<string>(textApi.getActiveTextGraphic().getColor());
    const graphicId = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.graphicId);

    useEffect(() => setTextColor(textApi.getActiveTextGraphic().getColor()), [textApi, graphicId]);

    useEffect(() => {
        if (textColor !== textApi.getActiveTextGraphic().getColor()) {
            textApi.getActiveTextGraphic().setColor(textColor);
        }
    }, [textApi, textColor]);

    return { setTextColor, textColor };
};

import { EditorType } from '../Store/MetaData/state';
import { useTypedSelector } from '../Store/connectors';

interface UseEditorType {
    isAdvancedEditorProduct: boolean;
    isQuickEditorProduct: boolean;
    isSvgEditorProduct: boolean;
}

const useEditorType = (): UseEditorType => {
    const editorType = useTypedSelector(({ meta }) => meta.editorType);

    return {
        isAdvancedEditorProduct: editorType === EditorType.ADVANCED_EDITOR,
        isQuickEditorProduct: editorType === EditorType.QUICK_EDITOR,
        isSvgEditorProduct: editorType === EditorType.SVG_EDITOR,
    };
};

export default useEditorType;

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { SpecificationsState } from './state';

interface SpecificationsAction extends UnknownAction {
    payload: string;
    type: SpecificationsActionType;
}

export enum SpecificationsActionType {
    SPECIFICATIONS_LOADED = 'SPECIFICATIONS_LOADED',
}

export const specificationsLoaded = createAction<SpecificationsState, SpecificationsActionType>(SpecificationsActionType.SPECIFICATIONS_LOADED);

export type { SpecificationsAction };

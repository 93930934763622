import type { GalleryMediaTypes } from '../../Types/GalleryMediaTypes';

interface GalleryImage {
    type: GalleryMediaTypes.IMAGE;
    url: string;
}

interface GalleryVideo {
    type: GalleryMediaTypes.VIDEO;
    vimeoId: string;
}

interface GalleryThumb {
    url: string;
}

interface GalleryState {
    activeGallerySlide: number;
    issuuId: string | undefined;
    media: Array<GalleryImage | GalleryVideo>;
    thumbs: GalleryThumb[];
}

const initialGalleryState: GalleryState = {
    activeGallerySlide: 0,
    issuuId: undefined,
    media: [],
    thumbs: [],
};

export type { GalleryState, GalleryImage, GalleryThumb, GalleryVideo };
export default initialGalleryState;

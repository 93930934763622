import type { Hit } from 'instantsearch.js';
import type { TypedMiddleware } from '../store';
import { quickEditorHidden, quickEditorShown } from '../UI/action';
import { createTracker } from '../../../quickEditor/Analytics/QuickEditorTrackerFactory';
import { createDesignTracker } from '../../Analytics/DesignTrackerFactory';
import { algoliaDesignResultsLoaded, designSelected } from '../Product/action';
import { Version } from '../MetaData/state';
import type { AlgoliaDesignHit } from '../../Components/DesignGalleryBlock/DesignGalleryBlockVersionB';

export const trackDesignClicked = (
    algoliaIndexName: string,
    designHit: Hit<AlgoliaDesignHit>,
    queryID: string,
    position: number,
): void => {
    createDesignTracker().designClicked({
        designHit,
        index: algoliaIndexName,
        objectID: designHit.objectID,
        position,
        queryID,
    });
};

export const trackerMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    const { cart, gallery, meta, product } = storeApi.getState();
    const { isCartEdit } = cart;
    const { algoliaIndexName, algoliaQueryId, productId, variantId } = product;
    const { partnerId } = meta;
    const { activeGallerySlide } = gallery;
    const tracker = createTracker(isCartEdit, partnerId, productId, variantId);

    if (quickEditorShown.match(action)) {
        tracker.editorOpened();
    }

    if (quickEditorHidden.match(action)) {
        tracker.editorClosed();
    }

    if (designSelected.match(action)) {
        const { payload } = action;
        if (payload.algoliaData.testVersion === Version.B) {
            const { designHit } = payload.algoliaData;

            if (storeApi.getState().product.selectedDesignId !== payload.designId && algoliaIndexName && algoliaQueryId) {
                trackDesignClicked(algoliaIndexName, designHit, algoliaQueryId, activeGallerySlide + 1);
            }
        }
    }

    if (algoliaDesignResultsLoaded.match(action)) {
        const { payload } = action;

        if (storeApi.getState().product.algoliaQueryId !== payload.queryID) {
            createDesignTracker().designViewed(payload.hits, payload.index, payload.queryID);

            if (storeApi.getState().product.selectedDesignId) {
                const designHit = payload.hits.find((hit) => hit.designId === storeApi.getState().product.selectedDesignId);
                if (designHit && payload.queryID) {
                    trackDesignClicked(payload.index, designHit, payload.queryID, activeGallerySlide + 1);
                }
            }
        }
    }

    next(action);
};

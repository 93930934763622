import type { ChangeEvent } from 'react';
import type React from 'react';
import { usePhoto } from '../../../../Hooks/UsePhoto';
import CirclePlusIcon from '../../../General/Icon/CirclePlusIcon';
import './PhotoUploadInput.scss';

interface Props {
    allowMultipleFiles: boolean;
}

const PhotoUploadInput: React.FC<Props> = ({ allowMultipleFiles }) => {
    const { allowedFileTypeExpression, uploadPhotos } = usePhoto();

    const onFileChosen = (event: ChangeEvent) => {
        const fileUploadElement = (event.target as HTMLInputElement);
        const photosToUpload = fileUploadElement.files as FileList;
        uploadPhotos(photosToUpload);

        // Reset the value of the file input to make sure the same file can be picked more than once
        fileUploadElement.value = '';
    };

    return (
        <div className="photo-upload photo-tile">
            <label className="photo-upload__label" htmlFor="photo-upload">
                <div className="photo-upload__label__icon">
                    <CirclePlusIcon />
                </div>
                <input
                    aria-label="Photo upload"
                    type="file"
                    id="photo-upload"
                    className="photo-upload__input"
                    multiple={allowMultipleFiles}
                    accept={allowedFileTypeExpression}
                    onChange={onFileChosen}
                />
            </label>
        </div>
    );
};

export default PhotoUploadInput;

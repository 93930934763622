export enum ControlsDisplayOption {
    FULLSCREEN = 'FULLSCREEN',
    HIDDEN = 'HIDDEN',
    OVERLAY = 'OVERLAY',
}

interface Overlay {
    currentDisplay: ControlsDisplayOption;
    prevDisplay: ControlsDisplayOption;
}

export interface QuickEditorUiState {
    composer: {
        loaded: boolean;
    };
    overlay: Overlay;
}

export const initialQuickEditorUiState: QuickEditorUiState = {
    composer: {
        loaded: false,
    },
    overlay: {
        currentDisplay: ControlsDisplayOption.HIDDEN,
        prevDisplay: ControlsDisplayOption.HIDDEN,
    },
};

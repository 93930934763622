import { createReducer } from '@reduxjs/toolkit';
import { deliveryInformationLoaded, shippingLoaded } from './action';
import type { DeliveryInformation } from './state';
import initialShippingState, { ShippingMethodType } from './state';

const shippingReducer = createReducer(initialShippingState, (builder) => {
    builder.addCase(shippingLoaded, (state, { payload }) => {
        const methodsInCurrentState = state.methods.map((m) => m.method).sort().join(',');
        const methodsInNewState = payload.methods.map((m) => m.method).sort().join(',');
        const methodsHaveChanged = methodsInCurrentState !== methodsInNewState;

        return ({
            ...state,
            ...payload,
            deadlineText: state.deadlineText,
            deliveryInformationLoaded: state.deliveryInformationLoaded,
            methods: methodsHaveChanged ? payload.methods : state.methods,
        });
    });
    builder.addCase(deliveryInformationLoaded, (state, { payload }) => {
        const enrichedDeliveryInformation = state.methods.map((shippingMethod) => {
            const deliveryInformationForMethod = payload?.[shippingMethod.method as keyof DeliveryInformation] as DeliveryInformation | null ?? {
                additionalInfoAvailable: false,
            };

            return {
                ...shippingMethod,
                additionalInfoAvailable: true,
                ...deliveryInformationForMethod,
            };
        });

        enrichedDeliveryInformation.sort((a, b) => {
            if (!a.additionalInfoAvailable || !a.firstDeliveryMoment) {
                return 1;
            }

            if (!b.additionalInfoAvailable || !b.firstDeliveryMoment) {
                return -1;
            }

            // First we sort by price, lowest first
            if (Number(a.salePrice) !== Number(b.salePrice)) {
                return Number(a.salePrice) - Number(b.salePrice);
            }

            // Then by speed, fastest first
            if (a.firstDeliveryMoment !== b.firstDeliveryMoment) {
                return new Date(a.firstDeliveryMoment).getTime() - new Date(b.firstDeliveryMoment).getTime();
            }

            // Then by methods, first home delivery
            if (a.method === ShippingMethodType.DEFAULT_METHOD || b.method === ShippingMethodType.DEFAULT_METHOD) {
                return a.method === ShippingMethodType.DEFAULT_METHOD ? -1 : 1;
            }

            // Then pickup points
            if (a.method === ShippingMethodType.PICKUP_POINT_METHOD || b.method === ShippingMethodType.PICKUP_POINT_METHOD) {
                return a.method === ShippingMethodType.PICKUP_POINT_METHOD ? -1 : 1;
            }

            // And lastly the express method
            if (a.method === ShippingMethodType.EXPRESS_METHOD || b.method === ShippingMethodType.EXPRESS_METHOD) {
                return a.method === ShippingMethodType.EXPRESS_METHOD ? -1 : 1;
            }

            return 0;
        });

        return {
            ...state,
            ...payload,
            deliveryInformationLoaded: true,
            methods: enrichedDeliveryInformation,
        };
    });
});

export default shippingReducer;

import { createReducer } from '@reduxjs/toolkit';
import { combinedArticleLoaded, updateDisabledValues, updateSelectedValues, updateValuePrices } from './action';
import initialCombinedArticleState from './state';

const combinedArticleReducer = createReducer(initialCombinedArticleState, (builder) => {
    builder.addCase(combinedArticleLoaded, (state, { payload }) => payload);
    builder.addCase(updateDisabledValues, (state, { payload }) => (state ? { ...state, disabledValues: payload } : null));
    builder.addCase(updateSelectedValues, (state, { payload }) => (state ? { ...state, selectedValues: payload } : null));
    builder.addCase(updateValuePrices, (state, { payload }) => (state ? { ...state, valuePrices: payload } : null));
});

export default combinedArticleReducer;

import type React from 'react';
import type { CombinedArticleAttribute, CombinedArticleValue } from '../../../../Store/CombinedArticle/state';
import Badge from '../../../../../general/Badge/Badge';
import Translation from '../../../../../general/Translation/Translation';

interface ButtonGroupProps {
    attribute: CombinedArticleAttribute;
    disabledValues: string[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    value: CombinedArticleValue;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
}

const ButtonGroupItem: React.FC<ButtonGroupProps> = ({
    attribute, disabledValues, onChange, selectedValues, value, valuePrices,
}) => (
    <>
        <input type="radio" className="radioBar__replacedInput"
            id={`combinedArticle_${value.id}`}
            name={`combinedArticle_${attribute.id}`}
            disabled={disabledValues.includes(String(value.id))}
            checked={!disabledValues.includes(String(value.id)) && selectedValues[attribute.id] === value.id}
            onChange={() => onChange(attribute.id, value.id)}
        />
        <label htmlFor={`combinedArticle_${value.id}`} data-value-id={value.id} data-input-name={`combinedArticle_${value.id}`}
            className="radioBar__button pointer">
            {value.name}
            {valuePrices[value.id] && <span className="margin__left--extraSmall radioBar__button__price">{`(${String(valuePrices[value.id]?.price)})`}</span>}
            {valuePrices[value.id] && valuePrices[value.id]?.hasDiscount && <Badge customClassNames="radioBar__button__badge" colorScheme="transparent" lowerCase>
                <Translation pageString="artikel_icon" stringId="sale" />
            </Badge>}
        </label>
    </>
);

export default ButtonGroupItem;

import type React from 'react';
import classNames from 'classnames';
import type { Design } from '@yoursurprise/gift-editor';
import './DesignTile.scss';
import CheckmarkIcon from '../Icon/CheckmarkIcon';

interface Props {
    active: boolean;
    backgroundColor: string;
    design: Design;
    setActiveDesign: (designId: number) => void;
}

const tileClasses = (active: boolean) => classNames('design-tile', {
    'design-tile--active': active,
});

const DesignTile: React.FC<Props> = ({ active, backgroundColor, design, setActiveDesign }) => {
    const onClick = () => {
        setActiveDesign(design.id);
    };

    return (
        <div
            role="button"
            style={{ backgroundColor }}
            className={tileClasses(active)}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex={0}
            aria-current={active}
        >
            <img loading="lazy" className="design-tile__image" src={design.thumbUrl} alt="" />
            { active && <CheckmarkIcon /> }
        </div>
    );
};

export default DesignTile;

import type React from 'react';
import { useTextEmphasis } from '../../../../Hooks/UseTextEmphasis';
import { TextEmphasisAvailability } from '../../../../Types/TextEmphasisAvailability';
import TextEditableGroup from '../TextEditableGroup/TextEditableGroup';
import TextEmphasisBoldEditable from './TextEmphasisBoldEditable/TextEmphasisBoldEditable';
import TextEmphasisItalicEditable from './TextEmphasisItalicEditable/TextEmphasisItalicEditable';

const TextEmphasisEditable: React.FC = () => {
    const { boldAvailability, italicAvailability } = useTextEmphasis();

    const boldAvailable = boldAvailability !== TextEmphasisAvailability.UNAVAILABLE;
    const italicAvailable = italicAvailability !== TextEmphasisAvailability.UNAVAILABLE;

    if (!boldAvailable && !italicAvailable) {
        return null;
    }

    return (
        <TextEditableGroup>
            {boldAvailable && <TextEmphasisBoldEditable />}
            {italicAvailable && <TextEmphasisItalicEditable />}
        </TextEditableGroup>
    );
};

export default TextEmphasisEditable;

import { useEffect, useState } from 'react';
import { window } from '../../../../js/globals';
import { localStorage } from '../../../../js/util/storage';
import fetch from '../../../../js/api/fetch';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';
import { withSiteUrl } from '../../../../js/api/url';

export const ALGOLIA_HAS_ANALYTICS_LOCAL_STORAGE_KEY = 'algolia_has_analytics';

const useHasAnalytics = (): boolean => {
    const [hasAnalytics, setHasAnalytics] = useState<boolean | undefined>(undefined);

    const localData = localStorage.getItem(ALGOLIA_HAS_ANALYTICS_LOCAL_STORAGE_KEY);

    useEffect(() => {
        if (!window) {
            setHasAnalytics(false);
            return;
        }

        if (hasAnalytics !== undefined) {
            return;
        }

        if (localData !== null) {
            setHasAnalytics(localData === '1');

            return;
        }

        setHasAnalytics(true);
        fetch(withSiteUrl('/user-context'))
            .then((response) => response.json() as { atHQ?: boolean })
            .then((data) => {
                const atHq = data?.atHQ === true;

                setHasAnalytics(!atHq);
                localStorage.setItem(ALGOLIA_HAS_ANALYTICS_LOCAL_STORAGE_KEY, atHq ? '0' : '1');
            }).catch((e: Error) => {
                ErrorLogger.createFromGlobals()?.log(e);
            });
    }, [hasAnalytics, localData]);

    if (!window) {
        return false;
    }

    if (hasAnalytics !== undefined) {
        return hasAnalytics;
    }

    return localData === '1' || localData === null;
};

export default useHasAnalytics;

import type React from 'react';
import { useEffect, useState } from 'react';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperEvents } from 'swiper/types/swiper-events';
import MobileQuickEditorPreview from '../../InformationBlock/MobileQuickEditorPreview/MobileQuickEditorPreview';
import VimeoVideo from '../../../../general/Video/VimeoVideo';
import { useShowOnPageEditor } from '../../../Hooks/useShowOnPageEditor';
import { useTypedDispatch, useTypedSelector } from '../../../Store/connectors';
import ErrorLogger from '../../../../../js/error-logger/ErrorLogger';
import { GalleryMediaTypes } from '../../../Types/GalleryMediaTypes';
import { activeGallerySlideSet } from '../../../Store/Gallery/action';

interface Props {
    showMobileQuickEditorPreview: boolean;
}

/**
 * This conflicts with the indent rule, so disable the props one
 */
/* eslint-disable react/jsx-indent-props */
const ImageGallery: React.FC<Props> = ({ showMobileQuickEditorPreview }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const activeGallerySlide = useTypedSelector(({ gallery }) => gallery.activeGallerySlide);
    const media = useTypedSelector(({ gallery }) => gallery.media);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const { openOnPageEditor } = useShowOnPageEditor();
    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (swiperInstance && swiperInstance.realIndex !== activeGallerySlide) {
            swiperInstance?.slideTo(activeGallerySlide);
        }
    }, [swiperInstance, activeGallerySlide]);

    useEffect(() => {
        const slideChangeListener: SwiperEvents['slideChange'] = (currentSwiperInstance) => {
            dispatch(activeGallerySlideSet({ position: currentSwiperInstance.realIndex }));
        };

        swiperInstance?.on('slideChange', slideChangeListener);

        return () => swiperInstance?.off('slideChange', slideChangeListener);
    }, [swiperInstance, dispatch]);

    const slides = media.map((galleryMedia, index) => {
        switch (galleryMedia.type) {
            case GalleryMediaTypes.IMAGE:
                return <SwiperSlide key={ galleryMedia.url }>
                    <img
                        fetchpriority={ activeGallerySlide === index ? 'high' : 'auto' }
                        loading={ activeGallerySlide === index ? 'eager' : 'lazy' }
                        src={ galleryMedia.url }
                        alt={ productTitle }
                        title={ productTitle }
                    />
                </SwiperSlide>;
            case GalleryMediaTypes.VIDEO:
                return <SwiperSlide key={ `${galleryMedia.vimeoId}-${String(index)}` }>
                    {({ isActive }) => (
                        <VimeoVideo
                            vimeoId={ `${galleryMedia.vimeoId}` }
                            aspectRatio="1:1"
                            autoplay={ isActive }
                            muted
                            controls={ false } loop/>
                    )}
                </SwiperSlide>;
            default:
                ErrorLogger.createFromGlobals()?.log(new Error('Found unexpected gallery media type'));

                return <div key={ index }/>;
        }
    });

    // Only render the preview when it's necessary to prevent index and active slide issues on desktop
    if (showMobileQuickEditorPreview) {
        const composerPreviewHeight = swiperInstance?.height ?? 0;

        slides.unshift(
            <SwiperSlide key="composer-preview">
                <div
                    id="mobile-quick-editor-preview"
                    style={ { minHeight: `${composerPreviewHeight}px` } }
                    role="button"
                    tabIndex={ 0 }
                    onClick={ openOnPageEditor }
                    onKeyPress={ openOnPageEditor }
                    data-testid="mobile-quick-editor-preview"
                >
                    <MobileQuickEditorPreview/>
                </div>
            </SwiperSlide>,
        );
    }

    return <Swiper
        centeredSlides={ true }
        className='swiper-container gallerySliderContainer'
        onSwiper={ setSwiperInstance }
        setWrapperSize={ false }
        watchSlidesProgress={ true }>
        { slides }
    </Swiper>;
};

export default ImageGallery;

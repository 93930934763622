import { document, window } from '../../../js/globals';

const noScrollClasses = ['util__noScroll', 'util__noScroll--noScrollbars'];

const suspendScrollingActivity = (resetScrollTo: number | undefined): void => {
    const container = document?.body;

    if (container && window) {
        container.classList.add(...noScrollClasses);

        if (resetScrollTo) {
            container.style.top = `-${resetScrollTo}px`;
        }
    }
};

const resumeScrollingActivity = (): void => {
    const container = document?.body;

    if (container) {
        container.classList.remove(...noScrollClasses);
        container.style.removeProperty('top');
    }
};

export { suspendScrollingActivity, resumeScrollingActivity };

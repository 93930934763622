import type React from 'react';
import { useCallback, useState } from 'react';
import { useCartApi } from '../../../../../Hooks/UseCartApi';
import CartIcon from '../../../../General/Icon/CartIcon';
import SpinnerIcon from '../../../../General/Icon/SpinnerIcon';
import FloatingActionButton from '../../FloatingActionButton';
import './AddToCartButton.scss';

const AddToCartButton: React.FC = () => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { addToCart } = useCartApi();

    const onClick = useCallback(() => {
        setDisabled(true);

        addToCart().catch(() => setDisabled(false));
    }, [addToCart]);

    return (
        <FloatingActionButton label="Add to cart" className="add-to-cart-button" disabled={disabled} onClick={onClick}>
            {disabled ? <SpinnerIcon /> : <CartIcon />}
        </FloatingActionButton>
    );
};

export default AddToCartButton;

import type { TypedMiddleware } from '../store';
import { algoliaDesignResultsLoaded, designSelectedCleared } from '../Product/action';
import { activeGallerySlideSet } from '../Gallery/action';

export const algoliaDesignResultsLoadedMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    next(action);

    const { gallery, product } = storeApi.getState();
    const { dispatch } = storeApi;

    if (!algoliaDesignResultsLoaded.match(action)) {
        return;
    }
    const { hits } = action.payload;
    const selectedPosition = hits.findIndex(({ designId }) => designId === product.selectedDesignId);

    if (selectedPosition && selectedPosition !== -1) {
        if (selectedPosition !== gallery.activeGallerySlide) {
            dispatch(activeGallerySlideSet({ position: selectedPosition }));
        }
        return;
    }

    dispatch(designSelectedCleared());
};

import type React from 'react';
import './SpinnerIcon.scss';

const SpinnerIcon: React.FC = () => (
    <svg className="icon icon-spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 51.9" width="50" height="51.9">
        <path
            d="M14.1 41.6c0 1-.4 1.9-1.1 2.7-.7.7-1.6 1.1-2.7 1.1-1 0-1.9-.4-2.7-1.1s-1.1-1.6-1.1-2.7c0-1 .4-1.9
            1.1-2.7s1.6-1.1 2.7-1.1 1.9.4 2.7 1.1c.7.7 1.1 1.6 1.1 2.7zm14.7 6c0 1-.4 1.9-1.1 2.7-.7.7-1.6 1.1-2.7
            1.1s-1.9-.4-2.7-1.1c-.7-.7-1.1-1.6-1.1-2.7 0-1 .4-1.9 1.1-2.7.7-.7 1.6-1.1 2.7-1.1s1.9.4 2.7 1.1c.7.8
            1.1 1.7 1.1 2.7zM8 26.9c0 1-.4 1.9-1.1 2.7-.7.7-1.6 1.1-2.7 1.1s-1.9-.4-2.7-1.1C.8 28.9.4 28 .4 26.9c0-1
            .4-1.9 1.1-2.7s1.6-1.1 2.7-1.1 1.9.4 2.7 1.1S8 25.9 8 26.9zm35.4 14.7c0 1-.4 1.9-1.1 2.7s-1.6 1.1-2.7
            1.1c-1 0-1.9-.4-2.7-1.1-.7-.7-1.1-1.6-1.1-2.7 0-1 .4-1.9 1.1-2.7.7-.7 1.6-1.1 2.7-1.1 1 0 1.9.4 2.7
            1.1.8.7 1.1 1.6 1.1 2.7zM15 12.2c0 1.3-.5 2.4-1.4 3.3-.9.9-2 1.4-3.3
            1.4s-2.4-.5-3.3-1.4c-.9-.9-1.4-2-1.4-3.3 0-1.3.5-2.4 1.4-3.3.9-.9 2-1.4 3.3-1.4s2.4.5 3.3 1.4c1 .9 1.4 2
            1.4 3.3zm34.5 14.7c0 1-.4 1.9-1.1 2.7-.7.7-1.6 1.1-2.7 1.1s-1.9-.4-2.7-1.1c-.7-.7-1.1-1.6-1.1-2.7 0-1
            .4-1.9 1.1-2.7.7-.7 1.6-1.1 2.7-1.1s1.9.4 2.7 1.1 1.1 1.7 1.1 2.7zM30.7 6.2c0 1.6-.5 2.9-1.6 4s-2.4 1.6-4
            1.6-2.9-.5-4-1.6-1.6-2.4-1.6-4 .5-2.9 1.6-4 2.4-1.6 4-1.6 2.9.5 4 1.6c1 1.1 1.6 2.4 1.6 4zm15.6 6c0 1.8-.6
            3.4-1.9 4.7-1.3 1.3-2.8 1.9-4.7 1.9-1.8 0-3.4-.6-4.7-1.9-1.3-1.3-1.9-2.8-1.9-4.7 0-1.8.6-3.4 1.9-4.7 1.3-1.3
            2.8-1.9 4.7-1.9 1.8 0 3.4.6 4.7 1.9s1.9 2.9 1.9 4.7z"
        />
    </svg>
);

export default SpinnerIcon;

export type CombinedArticleValue = {
    disabled: boolean;
    hidden: boolean;
    id: number;
    imgLocation: string;
    imgSelectedLocation: string;
    name: string;
    ourChoice: boolean;
    price: string;
    selected: boolean;
    value: string;
};

export type CombinedArticleAttribute = {
    id: number;
    level: number;
    name: string;
    type: string;
    values: CombinedArticleValue[];
};

type CombinedArticleState = {
    data: CombinedArticleAttribute[];
    disabledValues: string[];
    id: number;
    selectedValues: Record<number, number> | Record<never, never>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }> | Record<never, never>;
} | null;

const initialCombinedArticleState: CombinedArticleState = null;

export type { CombinedArticleState };
export default initialCombinedArticleState;

import { useEffect, useRef } from 'react';
import { useInstantSearch } from 'react-instantsearch-core';

/**
 * This hook forces a refresh of the search results, forcing new results on the client side
 * This makes sure we're working with a unique queryId, which is required for proper analytics
 * Ideally we'd work without a non-cached server side rendered page, but this has too much performance impact.
 */
const useFixAnalytics = () => {
    const isInitialRender = useRef<boolean>(true);
    const search = useInstantSearch();

    useEffect(() => {
        if (isInitialRender.current) {
            search.refresh();
            isInitialRender.current = false;
        }
    }, [search]);
};

export default useFixAnalytics;

import { useEffect } from 'react';
import { useQuickEditorTracker } from './UseQuickEditorTracker';
import { useQuickEditorApi } from './UseQuickEditorApi';

export const useGiftEditorPersonalisationListener = (): void => {
    const { personalisationEventsApi } = useQuickEditorApi();
    const tracker = useQuickEditorTracker();

    useEffect(() => {
        const removePersonalisationChangeListener = personalisationEventsApi.setPersonalisationChangedListener(() => {
            tracker?.personalisationStarted();
            removePersonalisationChangeListener();
        });

        return removePersonalisationChangeListener;
    }, [personalisationEventsApi, tracker]);
};

import { createReducer } from '@reduxjs/toolkit';
import { window } from '../../../../js/globals';
import {
    personalisationTextUpdated,
    primaryCtaButtonSet,
    quickEditorHidden,
    quickEditorShown,
    scrollingActivityAllowed,
    scrollingActivityBlocked,
    stickyFooterButtonToggled,
    svgEditorHidden,
    svgEditorShown,
} from './action';
import initialUiState from './state';
import { finishedPersonalising } from '../Personalisation/action';

const uiReducer = createReducer(initialUiState, (builder) => {
    builder.addCase(finishedPersonalising, (state) => ({ ...state, allowPersonalisationWarning: true }));
    builder.addCase(primaryCtaButtonSet, (state, { payload }) => ({ ...state, primaryCtaButton: payload }));
    builder.addCase(quickEditorHidden, (state) => ({ ...state, shouldShowQuickEditor: false }));
    builder.addCase(quickEditorShown, (state) => ({ ...state, shouldShowQuickEditor: true }));
    builder.addCase(svgEditorHidden, (state) => ({ ...state, shouldShowSvgEditor: false }));
    builder.addCase(svgEditorShown, (state) => ({ ...state, shouldShowSvgEditor: true }));
    builder.addCase(stickyFooterButtonToggled, (state, { payload }) => ({
        ...state,
        showStickyFooterButton: payload,
    }));
    builder.addCase(scrollingActivityAllowed, (state) => ({ ...state, scrollY: undefined }));
    builder.addCase(scrollingActivityBlocked, (state) => ({ ...state, scrollY: window?.scrollY }));
    builder.addCase(personalisationTextUpdated, (state, { payload }) => ({
        ...state,
        personalisationText: payload,
    }));
});

export default uiReducer;

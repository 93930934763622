import { useEffect } from 'react';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedDispatch } from '../../productInformation/Store/connectors';
import { activeGraphicSet } from '../../productInformation/Store/QuickEditorGraphic/action';

export const useGiftEditorGraphicFocusListener = (): void => {
    const { focusApi } = useQuickEditorApi();
    const dispatch = useTypedDispatch();

    useEffect(() => {
        const focusListener = focusApi.setFocusListener(({ contentType, graphicId }) => {
            dispatch(activeGraphicSet({ contentType, graphicId }));
        });

        return () => focusListener();
    });
};

import type React from 'react';

const Stars: React.FC<{ amount: number }> = ({ amount }) => (
    <>
        {Array.from(Array(5)).map((_, i) => (
            amount > i
                ? <i key={i} role="presentation" className="fa-solid fa-star fa-sm" />
                : <i key={i} role="presentation" className="fa-regular fa-star fa-sm" />
        ))}
    </>
);

export default Stars;

type SpecificationsState = {
    description: string;
    productPageTitle: string;
    specifications: string;
    vimeoId?: number | null;
};

const initialSpecificationsState: SpecificationsState = {
    description: '',
    productPageTitle: '',
    specifications: '',
};

export type { SpecificationsState };
export default initialSpecificationsState;

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { CartState } from './state';

interface CartAction extends UnknownAction {
    payload: string;
    type: CartActionType;
}

export enum CartActionType {
    CART_LOADED = 'CART_LOADED',
}

export const cartLoaded = createAction<CartState, CartActionType>(CartActionType.CART_LOADED);

export type { CartAction };

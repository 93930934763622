import { useEffect, useState } from 'react';
import { TextEmphasisAvailability } from '../Types/TextEmphasisAvailability';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedSelector } from '../../productInformation/Store/connectors';

export interface EmphasisConstraints {
    boldAvailability: TextEmphasisAvailability;
    italicAvailability: TextEmphasisAvailability;
}

type UseTextEmphasis = {
    isBold: boolean;
    isItalic: boolean;
    setBold: (bold: boolean) => void;
    setItalic: (italic: boolean) => void;
} & EmphasisConstraints;

export const useTextEmphasis = (): UseTextEmphasis => {
    const { textApi } = useQuickEditorApi();

    const [isBold, setBold] = useState<boolean>(textApi.getActiveTextGraphic().isBold());
    const [isItalic, setItalic] = useState<boolean>(textApi.getActiveTextGraphic().isItalic());
    const fontId = textApi.getActiveTextGraphic().getFont();

    const boldAvailability = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.constraints?.font.emphasis.bold.availability ?? TextEmphasisAvailability.DISABLED);
    const italicAvailability = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.constraints?.font.emphasis.italic.availability ?? TextEmphasisAvailability.DISABLED);
    const graphicId = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.graphicId);

    useEffect(() => setBold(textApi.getActiveTextGraphic().isBold()), [textApi, fontId, graphicId]);
    useEffect(() => setItalic(textApi.getActiveTextGraphic().isItalic()), [textApi, fontId, graphicId]);

    useEffect(() => {
        if (isBold !== textApi.getActiveTextGraphic().isBold()) {
            textApi.getActiveTextGraphic().setBold(isBold);
        }
    }, [textApi, isBold]);

    useEffect(() => {
        if (isItalic !== textApi.getActiveTextGraphic().isItalic()) {
            textApi.getActiveTextGraphic().setItalic(isItalic);
        }
    }, [textApi, isItalic]);

    return {
        boldAvailability,
        isBold,
        isItalic,
        italicAvailability,
        setBold,
        setItalic,
    };
};

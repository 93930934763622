import type React from 'react';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useQuickEditorApi } from '../../../Hooks/UseQuickEditorApi';
import { ControlsDisplayOption } from '../../../../productInformation/Store/QuickEditorUi/state';

export interface ControlsDisplayWrapperProps {
    classNamePrefix: string;
    prevDisplay: ControlsDisplayOption;
    shouldZoomPreview: boolean;
}

const ControlsDisplayWrapper: React.FC<PropsWithChildren<ControlsDisplayWrapperProps>> = ({ children, classNamePrefix, prevDisplay, shouldZoomPreview }) => {
    const { previewApi } = useQuickEditorApi();
    const displayWrapper = useRef<HTMLDivElement>(null);

    const photoControlsClasses = classNames(classNamePrefix, {
        [`${classNamePrefix}--from-fullscreen`]: prevDisplay === ControlsDisplayOption.FULLSCREEN,
        [`${classNamePrefix}--from-hidden`]: prevDisplay === ControlsDisplayOption.HIDDEN,
        [`${classNamePrefix}--from-overlay`]: prevDisplay === ControlsDisplayOption.OVERLAY,
    });

    const onAnimationEnd = useCallback(() => {
        if (shouldZoomPreview) {
            previewApi.zoomToPreview();
        }
    }, [previewApi, shouldZoomPreview]);

    useEffect(() => {
        const displayWrapperElement = displayWrapper.current;

        if (displayWrapperElement) {
            displayWrapperElement.addEventListener('animationend', onAnimationEnd);
        }

        return () => {
            if (displayWrapperElement) {
                displayWrapperElement.removeEventListener('animationend', onAnimationEnd);
            }
        };
    }, [displayWrapper, onAnimationEnd]);

    return (
        <div className={photoControlsClasses} ref={displayWrapper}>
            {children}
        </div>
    );
};

export default ControlsDisplayWrapper;

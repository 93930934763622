import type React from 'react';
import type { SerializedFailedPhoto } from '@yoursurprise/gift-editor';
import './FailedPhoto.scss';

const FailedPhoto: React.FC<SerializedFailedPhoto> = ({ errorCaption, errorMessage, filename, thumbUrl }) => (
    <div className="failed-photo">
        <img className="failed-photo__image" src={thumbUrl} alt="Upload failed" />
        <div className="failed-photo__notice">
            <div className="failed-photo__notice__caption">{ errorCaption }</div>
            <div className="failed-photo__notice__message">{ errorMessage }</div>
            <div className="failed-photo__notice__filename">{ `(${filename})` }</div>
        </div>
    </div>
);

export default FailedPhoto;

import type React from 'react';

export interface RegularPriceProps {
    isSoldOut: boolean;
    price: string;
    productId: number;
    shippingCostsText: string;
    siteUrl: string;
}

export const RegularPrice: React.FC<RegularPriceProps> = ({ isSoldOut, price, productId, shippingCostsText, siteUrl }) => (
    <div className="article-price">
        <span className="article-price__total">
            { price }
        </span>
        {!isSoldOut && (
            <div>
                <a
                    rel="nofollow"
                    className="fancybox-iframe"
                    href={`${siteUrl}deadline/shipping-costs?productId=${productId}`}
                >
                    <div className="article-price__shipping">
                        { shippingCostsText }
                    </div>
                </a>
            </div>
        )}
    </div>
);

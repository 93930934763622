import type { TypedMiddleware } from '../store';
import { productSwitched } from '../Product/action';
import { deliveryInformationLoaded } from '../Shipping/action';
import fetch from '../../../../js/api/fetch';
import { productPageLoaded } from '../UI/action';
import { isObject } from '../../../../js/typeguards';
import { Environment } from '../Environment/state';
import { document } from '../../../../js/globals';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

export interface DeliveryMethodResponse {
    categoryId: number;
    deadline: string;
    deadlineText: string;
    deliveryOptionId: number;
    deliveryText: string;
    deliveryTypeId: number;
    firstDeliveryMoment: string;
    lastDeliveryMoment: string;
    salePrice: string;
    salePriceFormatted: string;
    shippingServiceProvider: number;
    stStringName: string;
    traceable: number;
    trackingText: string;
}

export interface DeliveryInformationResponse {
    deadlineText: string;
    defaultMethod: DeliveryMethodResponse | null;
    expressMethod: DeliveryMethodResponse | null;
    pickupPointMethod: DeliveryMethodResponse | null;
    replaceClass: string;
    shippingCostsText: string;
}

function assertIsValidDeliveryInformationResponse(response: unknown): asserts response is DeliveryInformationResponse {
    const isValid = isObject(response) && isObject(response?.defaultMethod)
        && (isObject(response.pickupPointMethod) || response.pickupPointMethod === null)
        && (isObject(response.expressMethod) || response.expressMethod === null)
        && typeof response.deadlineText === 'string';

    if (!isValid) {
        throw new Error('Got invalid data when retrieving delivery information');
    }
}

const makeDeliveryInformationAvailableForLegacyShippingInfo = (deliveryInformation: DeliveryInformationResponse) => {
    const replaceElements = document?.querySelectorAll(deliveryInformation.replaceClass);

    replaceElements?.forEach((replaceElement) => {
        // eslint-disable-next-line no-param-reassign
        replaceElement.innerHTML = deliveryInformation.deadlineText;
    });
};

export const deliveryInformationMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    const { environment } = storeApi.getState().environment;

    if ((!productPageLoaded.match(action) && !productSwitched.match(action)) || environment === Environment.NODE) {
        next(action);
        return;
    }

    const { isSoldOut, productId } = storeApi.getState().product;
    const { testDateTime } = storeApi.getState().shipping;

    if (isSoldOut) {
        return;
    }

    const queryParams = new URLSearchParams({
        isProductPage: String(1),
        productId: String(productId),
    });

    if (testDateTime) {
        queryParams.set('dateTimeMachine', testDateTime);
    }

    const fetchUrl = `deadline/shipping-methods?${queryParams.toString()}`;

    fetch(fetchUrl)
        .then((res) => res.json()
            .then((parsedResponse) => {
                assertIsValidDeliveryInformationResponse(parsedResponse);

                storeApi.dispatch(deliveryInformationLoaded(parsedResponse));

                makeDeliveryInformationAvailableForLegacyShippingInfo(parsedResponse);
            })).catch((e: Error) => {
            ErrorLogger.createFromGlobals()?.log(e);
        });

    next(action);
};

import { useCallback } from 'react';
import { document, window } from '../../../js/globals';
import { quickEditorShown, svgEditorShown } from '../Store/UI/action';
import { useTypedDispatch, useTypedSelector } from '../Store/connectors';
import { EditorType } from '../Store/MetaData/state';

interface UseShowOnPageEditor {
    closeOnPageEditor: () => void;
    openOnPageEditor: () => void;
    shouldShowQuickEditor: boolean;
    shouldShowSvgEditor: boolean;
}

export const useShowOnPageEditor = (): UseShowOnPageEditor => {
    const editorType = useTypedSelector(({ meta }) => meta.editorType);
    const svgEditorActive = editorType === EditorType.SVG_EDITOR;
    const quickEditorActive = editorType === EditorType.QUICK_EDITOR;

    const shouldShowQuickEditor = useTypedSelector(({ ui }) => ui.shouldShowQuickEditor);
    const shouldShowSvgEditor = useTypedSelector(({ ui }) => ui.shouldShowSvgEditor);

    const dispatch = useTypedDispatch();

    const openOnPageEditor = useCallback(() => {
        if (svgEditorActive) {
            if (window && document) {
                window.history.pushState({ showSvgEditor: true }, document.title, window.location.href);
            }
            dispatch(svgEditorShown());
            return;
        }

        if (quickEditorActive && !svgEditorActive) {
            if (window && document) {
                window.history.pushState({ showQuickEditor: true }, document.title, window.location.href);
            }
            dispatch(quickEditorShown());
        }
    }, [svgEditorActive, quickEditorActive, dispatch]);

    const closeOnPageEditor = () => {
        if (quickEditorActive || svgEditorActive) {
            window?.history.back();
        }
    };

    return {
        closeOnPageEditor,
        openOnPageEditor,
        shouldShowQuickEditor,
        shouldShowSvgEditor,
    };
};

import classNames from 'classnames';
import type React from 'react';
import { useCallback, useState } from 'react';
import { getGiftEditorApi } from '../../../../gift-editor-api/Factory/GiftEditorApiFactory';
import { addToCart } from '../../../../quickEditor/Hooks/UseCartApi';
import { useQuickEditorTracker } from '../../../../quickEditor/Hooks/UseQuickEditorTracker';
import { PrimaryCtaButton } from '../../../Store/UI/state';
import ButtonStack from '../../../../general/Button/ButtonStack';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../Store/connectors';
import ErrorLogger from '../../../../../js/error-logger/ErrorLogger';

interface Props {
    isCartEdit: boolean;
}

const AddPersonalisationToCartButton: React.FC<Props> = ({ isCartEdit }) => {
    const tracker = useQuickEditorTracker();
    const cart = useTypedSelector((state) => state.cart);
    const primaryCtaButton = useTypedSelector((state) => state.ui.primaryCtaButton);
    const product = useTypedSelector((state) => state.product);
    const [disabled, setDisabled] = useState<boolean>(false);

    const onClick = useCallback(() => {
        if (!disabled) {
            setDisabled(true);
            getGiftEditorApi(product, cart)
                .then((giftEditorApi) => giftEditorApi.getCartApi())
                .then((cartApi) => addToCart(cartApi, tracker))
                .catch((error: Error) => ErrorLogger.createFromGlobals()?.log(error)) // @TODO YHE-1888 Add feedback to the ui on failure
                .finally(() => setDisabled(false));
        }
    }, [cart, disabled, product, tracker]);

    return (
        <ButtonStack isSm isFluid>
            <button
                type="button"
                className={classNames('button', {
                    'button--ghost': primaryCtaButton !== PrimaryCtaButton.AddToCart,
                    'button--happy-path': primaryCtaButton === PrimaryCtaButton.AddToCart,
                })}
                disabled={disabled}
                onClick={onClick}
            >
                <span>
                    {isCartEdit
                        ? <Translation pageString="GiftEditorWidget" stringId="save"/>
                        : <Translation pageString="GiftEditorWidget" stringId="in_shoppingbasket"/>}
                </span>
            </button>
        </ButtonStack>
    );
};

export default AddPersonalisationToCartButton;

import type { Product } from '@yoursurprise/segment-analytics';

type Input = { combiArticleCount: number; combinedId: number | string; image: string; name: string; price: { current: number }; productId: number | string; url: string };
const mapHitToProduct = (input: Input, currency: string, position: number | undefined): Product & { objectID: string } => {
    const product: Product & { objectID: string } = {
        currency,
        image: input.image,
        name: input.name,
        objectID: String(input.productId),
        position,
        price: input.price.current,
        productId: Number(input.productId),
        url: input.url,
    };

    if (input.combiArticleCount > 1 && input.combinedId) {
        product.combinedProductId = Number(input.combinedId);
    }

    return product;
};

export default mapHitToProduct;

import type React from 'react';
import { useTextAlignment } from '../../../../Hooks/UseTextAlignment';
import { TextAlignment } from '../../../../Types/TextAlignment';
import AlignCenterIcon from '../../../General/Icon/AlignCenterIcon';
import AlignLeftIcon from '../../../General/Icon/AlignLeftIcon';
import AlignRightIcon from '../../../General/Icon/AlignRightIcon';
import TextEditableGroup from '../TextEditableGroup/TextEditableGroup';
import { TextEditableGroupItem } from '../TextEditableGroup/TextEditableGroupItem/TextEditableGroupItem';

const TextAlignmentEditable: React.FC = () => {
    const { isAllowed, setTextAlignment, textAlignment } = useTextAlignment();

    if (!isAllowed) {
        return null;
    }

    return (
        <TextEditableGroup>
            <TextEditableGroupItem
                active={textAlignment === TextAlignment.LEFT}
                disabled={false}
                label="Align left"
                onSelect={() => setTextAlignment(TextAlignment.LEFT)}
            >
                <AlignLeftIcon />
            </TextEditableGroupItem>
            <TextEditableGroupItem
                active={textAlignment === TextAlignment.CENTER}
                disabled={false}
                label="Align center"
                onSelect={() => setTextAlignment(TextAlignment.CENTER)}
            >
                <AlignCenterIcon />
            </TextEditableGroupItem>
            <TextEditableGroupItem
                active={textAlignment === TextAlignment.RIGHT}
                disabled={false}
                label="Align right"
                onSelect={() => setTextAlignment(TextAlignment.RIGHT)}
            >
                <AlignRightIcon />
            </TextEditableGroupItem>
        </TextEditableGroup>
    );
};

export default TextAlignmentEditable;

import type React from 'react';
import Translation from '../../../../../general/Translation/Translation';
import Badge from '../../../../../general/Badge/Badge';

export interface DiscountPriceProps {
    discountPercentage: number;
    isSoldOut: boolean;
    price: string;
    priceFrom: string;
    productId: number;
    shippingCostsText: string;
    siteUrl: string;
}

export const DiscountPrice: React.FC<DiscountPriceProps> = (
    {
        discountPercentage,
        isSoldOut,
        price,
        priceFrom,
        productId,
        shippingCostsText,
        siteUrl,
    },
) => (
    <>
        <div>
            <Translation pageString="productinformatie" stringId="of" />
            {' '}
            <del>{ priceFrom }</del>
        </div>

        <div className="article-price">
            <div className="article-price__total">
                { price }
                <Badge customClassNames="bold ml-2">
                    { `-${discountPercentage}%` }
                </Badge>
            </div>

            {!isSoldOut && (
                <div>
                    <a
                        rel="nofollow"
                        className="fancybox-iframe"
                        href={`${siteUrl}deadline/shipping-costs?productId=${productId}`}
                    >
                        <div className="article-price__shipping">
                            { shippingCostsText }
                        </div>
                    </a>
                </div>
            )}
        </div>
    </>
);

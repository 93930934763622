import { Tracker } from '@yoursurprise/segment-analytics';
import type { TypedMiddleware } from '../store';
import { canBeCombinedWithOtherProductsInCartLoaded, productSwitched } from '../Product/action';
import { isBoolean } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';
import { productPageLoaded } from '../UI/action';
import { Environment } from '../Environment/state';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

function assertIsProductAllowedToBeAddedResponse(response: unknown): asserts response is boolean {
    const isValid = isBoolean(response);

    if (!isValid) {
        throw new Error('Could not determine whether product can be added');
    }
}

export const productCanBeCombinedWithOtherProductsInCartMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    const { environment } = storeApi.getState().environment;

    if ((!productPageLoaded.match(action) && !productSwitched.match(action)) || environment === Environment.NODE) {
        next(action);
        return;
    }

    const { shouldCheck } = storeApi.getState().product.strongAlcoholCheck;

    if (!shouldCheck) {
        next(action);
        return;
    }

    const { productId } = storeApi.getState().product;

    const fetchUrl = `cart-content/isAllowedToAddProduct?productId=${String(productId)}`;

    fetch(fetchUrl)
        .then((res) => res.json()
            .then((parsedResponse) => {
                assertIsProductAllowedToBeAddedResponse(parsedResponse);
                storeApi.dispatch(canBeCombinedWithOtherProductsInCartLoaded(parsedResponse));

                if (parsedResponse === false) {
                    (new Tracker()).track('Product can not be combined with items in cart', {
                        label: String(productId),
                        productId: String(productId),
                    });
                }
            })).catch(() => {
            // We'll assume it's allowed to add the product here
            // As a second line of defense, we also check if it's allowed in the cart
            storeApi.dispatch(canBeCombinedWithOtherProductsInCartLoaded(true));

            ErrorLogger.createFromGlobals()?.log(new Error('Failed to retrieve whether product may be added to cart'), {
                productId,
            });
        });

    next(action);
};

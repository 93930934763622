import type React from 'react';

const CircleCheckmarkIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
        <path
            d="M27.2 12.6c.1.1.1.3 0 .5L15.7 24.6c-.3.3-.6.4-1 .4s-.7-.2-.9-.4L9 19.8c-.1-.1-.2-.3-.1-.5l.1-.1
            1.5-1.5c.2-.1.4-.1.5 0l3.8 3.9 10.5-10.5c.2-.1.4-.1.5 0l1.4 1.5zm1.7-5.5C26 4.2 22.1 2.5 18 2.6c-4.1-.1-8
            1.6-10.9 4.5-3 2.9-4.6 6.8-4.6 10.9-.1 4.1 1.6 8 4.5 10.9 2.8 3 6.8 4.6 10.9 4.5 4.1.1 8-1.6 10.9-4.5
            3-2.8 4.6-6.8 4.5-10.9.2-4.1-1.5-8-4.4-10.9zM5.4 5.4C8.7 2 13.2.1 18 .2 22.7.1 27.3 2 30.6 5.4c3.4 3.3 5.3
            7.9 5.2 12.6.1 4.7-1.8 9.3-5.2 12.6-3.3 3.4-7.9 5.3-12.6 5.2-4.7.1-9.3-1.8-12.6-5.2C2 27.3.1 22.8.1 18 .1 13.3 2 8.7 5.4 5.4z"
        />
    </svg>
);

export default CircleCheckmarkIcon;

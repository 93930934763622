import { useEffect } from 'react';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedDispatch } from '../../productInformation/Store/connectors';
import { loadTextGraphicConstraintsThunk } from '../../productInformation/Store/QuickEditorGraphic/thunk';
import ErrorLogger from '../../../js/error-logger/ErrorLogger';

export const useGiftEditorConstraintsListener = (): void => {
    const { constraintsApi } = useQuickEditorApi();
    const dispatch = useTypedDispatch();

    useEffect(() => {
        const constraintListener = constraintsApi.listenToConstraintChanges(() => {
            dispatch(loadTextGraphicConstraintsThunk()).catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
        });

        return () => constraintListener();
    });
};

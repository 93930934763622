import { createReducer } from '@reduxjs/toolkit';
import { activeGallerySlideSet, galleryLoaded } from './action';
import initialGalleryState from './state';
import { finishedPersonalising } from '../Personalisation/action';

const galleryReducer = createReducer(initialGalleryState, (builder) => {
    builder.addCase(activeGallerySlideSet, (state, { payload }) => ({ ...state, activeGallerySlide: payload.position }));

    builder.addCase(galleryLoaded, (state, { payload }) => ({
        ...state,
        activeGallerySlide: 0,
        issuuId: payload.issuuId,
        media: payload.media,
        thumbs: payload.thumbs,
    }));

    builder.addCase(finishedPersonalising, (state) => ({ ...state, activeGallerySlide: 0 }));
});

export default galleryReducer;

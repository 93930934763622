import type React from 'react';
import classNames from 'classnames';
import { shallowEqual } from 'react-redux';
import scrollToElement from '../../../../js/util/scrollToElement';
import { window } from '../../../../js/globals';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';

interface Props {
    minimumRequiredRatingsToShow?: number;
}

const SpecificationsLink: React.FC<Props> = ({ minimumRequiredRatingsToShow = 5 }) => {
    const { ratings, ratingsFormatted } = useTypedSelector(({ product }) => ({
        ratings: product.ratings,
        ratingsFormatted: product.ratingsFormatted,
    }), shallowEqual);

    const handleSpecificationLinkClick = (SpecificationLabel: string) : void => {
        scrollToElement(SpecificationLabel);
        window?.analytics.track('Element Clicked', {
            category: 'product page',
            eventType: 'click',
            label: SpecificationLabel,
        });
    };

    return (
        <div className="wrapper">
            <div className="specifications-container is-grid has-col-4 has-no-row-gap">
                <button data-testid='specifications' title="specifications" className="specifications-link" onClick={() => handleSpecificationLinkClick('specifications')}>
                    <Icon style='fa-duotone' name='fa-gift' />
                    <div>
                        <span><Translation pageString="productinformatie" stringId="gift_specifications"/></span>
                        <span><Translation pageString="productinformatie" stringId="more_product_information"/></span>
                    </div>
                    <Icon style='fa-regular' name='fa-chevron-right' size='fa-xs' />
                </button>
                <button data-testid='giftReviews' className={classNames('specifications-link', {
                    'specifications-link--disabled': minimumRequiredRatingsToShow > ratings,
                })} onClick={() => handleSpecificationLinkClick('giftReviews')}>
                    <Icon style='fa-duotone' name='fa-stars' />
                    <div>
                        <span><Translation pageString="productinformatie" stringId="reviews"/></span>
                        <span><Translation pageString="productinformatie" stringId="read_more"/> ({minimumRequiredRatingsToShow > ratings ? '0' : ratingsFormatted})</span>
                    </div>
                    <Icon style='fa-regular' name='fa-chevron-right' size='fa-xs' />
                </button>
                <button data-testid="giftBusinessOrders" className="specifications-link" onClick={() => handleSpecificationLinkClick('giftBusinessOrders')}>
                    <Icon style='fa-duotone' name='fa-file-signature' />
                    <div>
                        <span><Translation pageString="productinformatie" stringId="business_quotation"/></span>
                        <span><Translation pageString="offerte" stringId="offerte_aanvragen"/></span>
                    </div>
                    <Icon style='fa-regular' name='fa-chevron-right' size='fa-xs' />
                </button>
                <button data-testid="productpageFaq" className="specifications-link" onClick={() => handleSpecificationLinkClick('productpageFaq')}>
                    <Icon style='fa-duotone' name='fa-circle-info' />
                    <div>
                        <span><Translation pageString="productinformatie" stringId="faq"/></span>
                        <span><Translation pageString="class.bestelstatus" stringId="veelgestelde_vragen"/></span>
                    </div>
                    <Icon style='fa-regular' name='fa-chevron-right' size='fa-xs' />
                </button>
            </div>
        </div>
    );
};

export default SpecificationsLink;

import { useEffect, useState } from 'react';
import type { Tracker } from '../Analytics/Tracker';
import { createTracker } from '../Analytics/QuickEditorTrackerFactory';
import { useTypedSelector } from '../../productInformation/Store/connectors';

export const useQuickEditorTracker = (): Tracker | undefined => {
    const isCartEdit = useTypedSelector(({ cart }) => cart.isCartEdit);
    const partnerId = useTypedSelector(({ meta }) => meta.partnerId);
    const productId = useTypedSelector(({ product }) => product.productId);
    const variantId = useTypedSelector(({ product }) => product.variantId);
    const [tracker, setTracker] = useState<Tracker>();

    useEffect(() => {
        setTracker(createTracker(isCartEdit, partnerId, productId, variantId));
    }, [isCartEdit, partnerId, productId, variantId]);

    return tracker;
};

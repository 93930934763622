import type React from 'react';
import type { FailedPhotos, SerializedFailedPhoto } from '@yoursurprise/gift-editor';
import { usePhoto } from '../../../../Hooks/UsePhoto';
import { PhotoUploadErrorLevel } from '../../../../Types/PhotoUploadErrorLevel';
import FailedPhoto from '../FailedPhoto';
import CircleWarningOutlineIcon from '../../../General/Icon/CircleWarningOutlineIcon';
import FailedPhotoControls from './FailedPhotoControls';
import './FailedPhotoCollection.scss';
import Translation from '../../../../../general/Translation/Translation';

interface Props {
    failedPhotos: FailedPhotos;
}

const FailedPhotoCollection: React.FC<Props> = ({ failedPhotos }) => {
    const { removeFailedPhotos, retryFailedPhotos } = usePhoto();

    const createFailedPhoto = (photo: SerializedFailedPhoto) => (
        <FailedPhoto
            filename={photo.filename}
            id={photo.id}
            key={photo.id}
            thumbUrl={photo.thumbUrl}
            errorLevel={photo.errorLevel}
            errorCaption={photo.errorCaption}
            errorMessage={photo.errorMessage}
        />
    );

    const fatalFailedPhotoViews = Array.from(failedPhotos.values())
        .filter((photo) => (photo.errorLevel as PhotoUploadErrorLevel === PhotoUploadErrorLevel.FATAL))
        .map((photo) => createFailedPhoto(photo));

    const warningFailedPhotoViews = Array.from(failedPhotos.values())
        .filter((photo) => (photo.errorLevel as PhotoUploadErrorLevel === PhotoUploadErrorLevel.WARNING))
        .map((photo) => createFailedPhoto(photo));

    if (warningFailedPhotoViews.length === 0 && fatalFailedPhotoViews.length === 0) {
        return null;
    }

    return (
        <>
            {fatalFailedPhotoViews.length > 0 && (
                <>
                    <div className="failed-photo-collection__caption--fatal failed-photo-collection__caption">
                        <CircleWarningOutlineIcon />
                        <Translation stringId="photo_upload_failed_error" pageString="GiftEditorWidget" />
                    </div>
                    {fatalFailedPhotoViews}
                </>
            )}
            {warningFailedPhotoViews.length > 0 && (
                <>
                    <div className="failed-photo-collection__caption--warning failed-photo-collection__caption">
                        <CircleWarningOutlineIcon />
                        <Translation stringId="photo_upload_failed_warning" pageString="GiftEditorWidget" />
                    </div>
                    {warningFailedPhotoViews}
                </>
            )}
            <FailedPhotoControls continueAction={removeFailedPhotos} retryAction={retryFailedPhotos} allowRetry={warningFailedPhotoViews.length > 0} />
        </>
    );
};

export default FailedPhotoCollection;

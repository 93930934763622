import type { GiftEditorApi } from '@yoursurprise/gift-editor';
import InstanceProxy from './InstanceProxy';
import ErrorLogger from '../../../js/error-logger/ErrorLogger';

export default class GiftEditorApiProxy extends InstanceProxy<Promise<GiftEditorApi>> {
    public destroyInstance(): void {
        this.getInstance()?.then((api) => api.destroy()).catch((err: Error) => ErrorLogger.createFromGlobals()?.log(err));
        super.destroyInstance();
    }
}

import classNames from 'classnames';
import type React from 'react';
import type { PropsWithChildren } from 'react';

export enum IconSize {
    NORMAL = 'normal',
    SMALL = 'small',
}

export interface TextEditableGroupItemProps {
    active: boolean;
    disabled: boolean;
    iconSize?: IconSize;
    label: string;
    onSelect: () => void;
}

export const TextEditableGroupItem: React.FC<PropsWithChildren<TextEditableGroupItemProps>> = ({
    active,
    children,
    disabled,
    iconSize,
    label,
    onSelect,
}) => (
    <div
        role="checkbox"
        tabIndex={0}
        aria-label={label}
        aria-checked={active}
        aria-disabled={disabled}
        className={classNames(
            'text-editable-group__item',
            { 'text-editable-group__item--active': active },
            { 'text-editable-group__item--disabled': disabled },
            { 'text-editable-group__item--small-icons': iconSize === IconSize.SMALL },
        )}
        onClick={onSelect}
        onKeyPress={onSelect}
    >
        {children || label}
    </div>
);

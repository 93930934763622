import type { Middleware } from '@reduxjs/toolkit';
import { isRejected } from '@reduxjs/toolkit';
import type { ProductPageRootState } from '../store';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

export const loggerMiddleware: Middleware<never, ProductPageRootState> = () => (next) => (action): void => {
    if (isRejected(action)) {
        ErrorLogger.createFromGlobals()?.log(action.error as Error);
    }

    next(action);
};

import type { Hit } from 'instantsearch.js';
import type { AlgoliaDesignHit } from '../../Components/DesignGalleryBlock/DesignGalleryBlockVersionB';

export type Usp = {
    content: string;
    id: number;
};
interface ProductState {
    algoliaHits?: Array<Hit<AlgoliaDesignHit>>;
    algoliaIndexName?: string;
    algoliaQueryId?: string;
    ctaButton: {
        editorUrl: string;
    };
    currency: string;
    dealPromotionPrice: number;
    discountPercentage: number;
    hasBlockedCombinedProductButton: boolean;
    hasDealPromotionShipping: boolean;
    hasFreeCoolerNotification: boolean;
    hasPaidCoolerNotification: boolean;
    isDealPromotion: boolean;
    isDiscountPrice: boolean;
    isGiftVoucher: boolean;
    isPersonalizable: boolean;
    isSoldOut: boolean;
    price: string;
    priceFrom: string;
    productId: number;
    productQuantity: number;
    productSVG: string;
    productSubtitle: string;
    productTitle: string;
    quickEditorActive: boolean;
    ratings: number;
    ratingsFormatted: string;
    reviewsCount: number;
    selectedDesignId?: number;
    sizesPopupLink: { text: string; url: string } | null;
    stars: number;
    strongAlcoholCheck: {
        canBeCombinedWithOtherProductsInCart: boolean;
        canBeCombinedWithOtherProductsInCartRequestLoaded: boolean;
        shouldCheck: boolean;
    };
    svgEditorActive: boolean;
    usps: Usp[];
    variantId: number;
}

const initialProductState: ProductState = {
    algoliaHits: [],
    algoliaIndexName: undefined,
    algoliaQueryId: undefined,
    ctaButton: { editorUrl: '' },
    currency: 'EUR',
    dealPromotionPrice: 0,
    discountPercentage: 0,
    hasBlockedCombinedProductButton: false,
    hasDealPromotionShipping: false,
    hasFreeCoolerNotification: false,
    hasPaidCoolerNotification: false,
    isDealPromotion: false,
    isDiscountPrice: false,
    isGiftVoucher: false,
    isPersonalizable: true,
    isSoldOut: false,
    price: '',
    priceFrom: '',
    productId: 0,
    productQuantity: 0,
    productSubtitle: '',
    productSVG: '',
    productTitle: '',
    quickEditorActive: false,
    ratings: 0,
    ratingsFormatted: '',
    reviewsCount: 0,
    selectedDesignId: undefined,
    sizesPopupLink: null,
    stars: 0,
    strongAlcoholCheck: {
        canBeCombinedWithOtherProductsInCart: true,
        canBeCombinedWithOtherProductsInCartRequestLoaded: false,
        shouldCheck: false,
    },
    svgEditorActive: false,
    usps: [],
    variantId: 0,
};

export type { ProductState };
export default initialProductState;

import type React from 'react';

const CirclePlusIcon: React.FC = () => (
    <svg version="1.1" className="icon" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 36 36">
        <path d="M18,0.2c-2.4,0-4.8,0.5-6.9,1.4c-4.3,1.8-7.7,5.2-9.5,9.5c-0.9,2.2-1.4,4.6-1.4,6.9c0,2.4,0.5,4.8,1.4,6.9
              c1.8,4.3,5.2,7.7,9.5,9.5c2.2,0.9,4.6,1.4,6.9,1.4c2.4,0,4.8-0.5,6.9-1.4c4.3-1.8,7.7-5.2,9.5-9.5c0.9-2.2,1.4-4.6,1.4-6.9
              c0-2.4-0.5-4.8-1.4-6.9c-1.8-4.3-5.2-7.7-9.5-9.5C22.8,0.6,20.4,0.2,18,0.2z M18,33.6c-2.1,0-4.2-0.4-6.1-1.2
              c-3.7-1.6-6.7-4.6-8.3-8.3c-0.8-1.9-1.2-4-1.2-6.1c0-2.1,0.4-4.2,1.2-6.1c1.6-3.7,4.6-6.7,8.3-8.3c1.9-0.8,4-1.2,6.1-1.2
              c2.1,0,4.2,0.4,6.1,1.2c3.7,1.6,6.7,4.6,8.3,8.3c0.8,1.9,1.2,4,1.2,6.1c0,2.1-0.4,4.2-1.2,6.1c-1.6,3.7-4.6,6.7-8.3,8.3
              C22.2,33.2,20.1,33.6,18,33.6z M24.7,16.9h-5.6v-5.6c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1v5.6h-5.6c-0.6,0-1.1,0.5-1.1,1.1
              s0.5,1.1,1.1,1.1h5.6v5.6c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-5.6h5.6c0.6,0,1.1-0.5,1.1-1.1S25.3,16.9,24.7,16.9z"
        />
    </svg>
);

export default CirclePlusIcon;

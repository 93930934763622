import type { SearchClient } from 'algoliasearch';
import type { FC } from 'react';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import ImageGalleryBlock from './ImageGalleryBlock';
import DesignGalleryBlockVersionA from '../DesignGalleryBlock/DesignGalleryBlockVersionA';
import { Version } from '../../Store/MetaData/state';
import DesignGalleryBlockVersionB from '../DesignGalleryBlock/DesignGalleryBlockVersionB';
import AlgoliaProvider from '../../../algolia/general/InstantSearch/AlgoliaProvider';
import { useTypedSelector } from '../../Store/connectors';

interface Props {
    algoliaSearchClient?: SearchClient;
    algoliaServerState?: InstantSearchServerState;
}

const createGalleryBlockForVersion = (version: Version) => {
    if (version === Version.B) {
        return <DesignGalleryBlockVersionB />;
    }

    return <DesignGalleryBlockVersionA/>;
};

const AbTestGalleryBlock: FC<Props> = ({ algoliaSearchClient, algoliaServerState }) => {
    const version = useTypedSelector(({ meta }) => meta.galleryAbTestVersion);

    if (!algoliaSearchClient) {
        return <ImageGalleryBlock/>;
    }

    return (
        <AlgoliaProvider
            algoliaServerState={algoliaServerState}
            algoliaSearchClient={algoliaSearchClient}
            indexName="webshop-designs"
        >
            {createGalleryBlockForVersion(version)}
        </AlgoliaProvider>
    );
};

export default AbTestGalleryBlock;

import $ from '../util/fancybox-jquery';
import { isMobilePortrait } from '../util/sizes';
import { document } from '../globals';
import { withSiteUrl } from '../api/url';

export default class Productpage {
    public static init(): void {
        Productpage.loadPreviousGiftBox();
        Productpage.initIssuu();
        document?.addEventListener('combinedArticleChanged', () => Productpage.initIssuu());
    }

    private static loadPreviousGiftBox(): void {
        const previousGiftViewer = $('#previous-cadeau-viewer');
        let giftViewerUrl = withSiteUrl('/product-page/recently-viewed');
        if (isMobilePortrait()) {
            giftViewerUrl += '?mobileLimit=true';
        }
        if (previousGiftViewer.length) {
            $.ajax({
                dataType: 'html',
                method: 'GET',
                success(data) {
                    if (data !== '') {
                        $(data).insertBefore(previousGiftViewer);
                    }
                },
                url: giftViewerUrl,
            }).catch(() => null);
        }
    }

    private static initIssuu(): void {
        $('.js-issuuButton').on('click', (e) => {
            $.fancybox({
                autoSize: false,
                height: (isMobilePortrait()) ? 400 : 600,
                href: withSiteUrl(`product-page/issuu?issuuId=${String(e.currentTarget.getAttribute('data-issuu-id'))}`),
                padding: (isMobilePortrait()) ? 0 : 25,
                swf: { wmode: 'transparent' },
                type: 'iframe',
                width: 780,
                wrapCSS: 'ysp-fancybox-dark',
            });
        });
    }
}

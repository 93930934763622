import type { FC } from 'react';
import styles from './ProductAmountGalleryLabel.module.scss';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';

const ProductAmountGalleryLabel: FC = () => {
    const productQuantity = useTypedSelector(({ product }) => product.productQuantity);

    if (productQuantity !== 4 && productQuantity !== 6) {
        return null;
    }

    return (
        <div className={styles.ProductAmountGalleryLabel}>
            {productQuantity}
            {' '}
            <Translation pageString="productinformatie" stringId="amount_pieces" />
        </div>
    );
};

export default ProductAmountGalleryLabel;

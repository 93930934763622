import type React from 'react';
import CircleCheckmarkIcon from '../../../../General/Icon/CircleCheckmarkIcon';
import FloatingActionButton from '../../FloatingActionButton';
import { useTypedDispatch } from '../../../../../../productInformation/Store/connectors';
import { activeGraphicCleared } from '../../../../../../productInformation/Store/QuickEditorGraphic/action';

const CloseGraphicButton: React.FC = () => {
    const dispatch = useTypedDispatch();

    return (
        <FloatingActionButton label="Done" onClick={() => dispatch(activeGraphicCleared())}>
            <CircleCheckmarkIcon />
        </FloatingActionButton>
    );
};

export default CloseGraphicButton;

import type React from 'react';
import classNames from 'classnames';
import './TextContentCount.scss';

interface TextContentCountProps {
    currentLength: number;
    maxLength: number;
}

const characterCounterClasses = (maxReached: boolean) => classNames('text-content-count', {
    'text-content-count--max-reached': maxReached,
});

const TextContentCount: React.FC<TextContentCountProps> = ({ currentLength, maxLength }) => (
    <div className={characterCounterClasses(currentLength >= maxLength)}>{`${maxLength - currentLength}`}</div>
);

export default TextContentCount;

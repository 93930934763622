import { createSelector } from '@reduxjs/toolkit';
import type React from 'react';
import useEditorType from '../../Hooks/UseEditorType';
import { useEnvironment } from '../../Hooks/UseEnvironment';
import type { ProductPageRootState } from '../../Store/store';
import IssuuPreview from '../IssuuPreview/IssuuPreview';
import ImageGallery from './ImageGallery/ImageGallery';
import ImageThumbGallery from './ImageThumbGallery/ImageThumbGallery';
import CoolerBadge from '../../../general/CoolerBadge/CoolerBadge';
import { useTypedSelector } from '../../Store/connectors';
import BadgeGroup from '../../../general/BadgeGroup/BadgeGroup';

const shouldShowQuickEditorPersonalisationPreviewSelector = (isQuickEditorProduct: boolean) => createSelector(
    ({ ui }: ProductPageRootState) => ui.shouldShowQuickEditor,
    ({ personalisation }: ProductPageRootState) => personalisation.yimp,
    (shouldShowQuickEditor, yimp) => isQuickEditorProduct && Boolean(yimp) && !shouldShowQuickEditor,
);

const ImageGalleryBlock: React.FC = () => {
    const { isQuickEditorProduct } = useEditorType();
    const showMobileQuickEditorPreview = useTypedSelector(shouldShowQuickEditorPersonalisationPreviewSelector(isQuickEditorProduct));
    const hasFreeCoolerNotification = useTypedSelector(({ shipping }) => shipping.hasFreeCoolerNotification);
    const { isDesktopBrowser } = useEnvironment();
    const thumbOrientation = isDesktopBrowser ? 'vertical' : 'horizontal';

    return (
        <div id="galleryBlock" className="galleryBlock product-information__gallery">
            <div className="productPageImageGallery">
                <div
                    className={'productPageImageGalleryThumbs noSelectHighlight'}>
                    <ImageThumbGallery
                        showMobileQuickEditorPreview={showMobileQuickEditorPreview}
                        orientation={thumbOrientation}
                    />
                </div>
                <div className="productPageImageGallery__main">
                    <ImageGallery showMobileQuickEditorPreview={showMobileQuickEditorPreview} />
                    <IssuuPreview />
                    {(hasFreeCoolerNotification) && (
                        <BadgeGroup posAbsolute="top" offset="lg">
                            <CoolerBadge isFree />
                        </BadgeGroup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageGalleryBlock;

import type React from 'react';
import TapInstructor from '../../../../quickEditor/Components/General/TapInstructor/TapInstructor';
import QuickEditorApiProvider from '../../../../quickEditor/Provider/QuickEditorApiProvider/QuickEditorApiProvider';
import Composer from '../../../../gift-editor-api/Composer';
import quickEditorApiContext from '../../../../quickEditor/Provider/QuickEditorApiProvider/QuickEditorApiContext';

const MobileQuickEditorPreview: React.FC = () => (
    <QuickEditorApiProvider previewMode>
        <Composer context={quickEditorApiContext}/>
        <TapInstructor/>
    </QuickEditorApiProvider>
);
export default MobileQuickEditorPreview;

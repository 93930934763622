import React from 'react';
import classNames from 'classnames';

type SectionProps = {
    children: React.ReactNode;
    hasBgGrey?: boolean;
    hasBorderBottom?: boolean;
    hasBorderTop?: boolean;
    hasNoBottomPadding?: boolean; // The last section has a padding bottom, this is used to negate that.
    hasNoPaddingXs?: boolean;
    hasWaveBottom?: boolean;
    hasWaveTop?: boolean;
    isBreadcrumb?: boolean;
    isMd?: boolean;
    isMtXl?: boolean;
    isRelative?: boolean;
    isSm?: boolean;
    isXl?: boolean;
    isXs?: boolean;
    noPadding?: boolean;
    wrapperMd?: boolean;
};

const Section = React.forwardRef<HTMLDivElement, SectionProps>(({
    children,
    hasBgGrey,
    hasBorderBottom,
    hasBorderTop,
    hasNoBottomPadding,
    hasWaveBottom,
    hasWaveTop,
    isBreadcrumb,
    isMd,
    isMtXl,
    isRelative,
    isSm,
    isXl,
    isXs,
    noPadding,
    wrapperMd,
}, ref) => (
    <div
        style={hasNoBottomPadding ? {
            paddingBottom: 0,
        } : undefined}
        className={classNames('section', {
            'has-border-bottom': hasBorderBottom,
            'has-border-top': hasBorderTop,
            'p-0': noPadding,
            'section--bg-grey': hasBgGrey,
            'section--breadcrumb': isBreadcrumb,
            'section--md': isMd,
            'section--mt--xl': isMtXl,
            'section--sm': isSm,
            'section--xl': isXl,
            'section--xs': isXs,
            'wave wave-bottom': hasWaveBottom,
            'wave wave-top': hasWaveTop,
        })}
        ref={ref}
    >
        <div
            className={classNames('wrapper', {
                'wrapper--md': wrapperMd,
            })}
            style={isRelative ? {
                position: 'relative',
            } : undefined}
        >
            {children}
        </div>
    </div>
));

export default Section;

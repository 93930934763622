import fetch from '../../../js/api/fetch';
import type { ProductPageRootState } from '../Store/store';
import { galleryLoaded } from '../Store/Gallery/action';
import {
    productLoaded,
    productSwitched,
    updateEditorType,
    updateHasBlockedCombinedProductButton,
} from '../Store/Product/action';
import { shippingLoaded } from '../Store/Shipping/action';
import { specificationsLoaded } from '../Store/Specifications/action';
import { combinedArticleLoaded, updateDisabledValues, updateSelectedValues, updateValuePrices } from '../Store/CombinedArticle/action';
import { document, window } from '../../../js/globals';
import type { ProductData } from '../../../js/types';
import { useTypedDispatch } from '../Store/connectors';
import { Version } from '../Store/MetaData/state';

export const COMBINED_PRODUCT_ENDPOINT = '/product-page/combined-article';

type CombinedProductData = {
    disabledValues: string[];
    productData: ProductData;
    selectedValues: string[];
    store: Partial<ProductPageRootState>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
};

type UpdateCombinedProductParams = {
    attributes: Record<number, number>;
    combinedArticleId: number;
    selectedDesignId: number;
    showUnpublished: boolean;
    variantId: number;
};

export const useCombinedProduct = () => {
    const dispatch = useTypedDispatch();

    const updateCombinedProduct = (values: UpdateCombinedProductParams) => {
        fetch(COMBINED_PRODUCT_ENDPOINT, {
            body: JSON.stringify(values),
            method: 'POST',
        })
            .then((response) => response.json())
            .then((data: CombinedProductData) => {
                const {
                    combinedArticle, gallery, meta, product, shipping, specifications,
                } = data.store;
                if (gallery && meta && !(meta.productIsAvailableForDesignSelectionAbTest && meta.galleryAbTestVersion === Version.B)) {
                    dispatch(galleryLoaded(gallery));
                }

                if (product) {
                    dispatch(productLoaded(product));
                    dispatch(updateHasBlockedCombinedProductButton(false));
                    dispatch(updateEditorType());

                    if (shipping) {
                        dispatch(shippingLoaded(shipping));
                    }

                    if (specifications) {
                        dispatch(specificationsLoaded(specifications));
                    }

                    if (combinedArticle) {
                        dispatch(combinedArticleLoaded(combinedArticle));
                    }

                    const urlSearchParams = new URLSearchParams(window?.location.search);
                    urlSearchParams.set('artikelcode', String(product.productId));
                    window?.history.replaceState(window?.history.state, '', `?${urlSearchParams.toString()}`);

                    document?.querySelector('.js-deadlineProductId')?.setAttribute('data-id', String(product.productId));
                    document?.dispatchEvent(new CustomEvent('combinedArticleChanged', { detail: { articleId: product.productId } }));
                    document?.dispatchEvent(new CustomEvent('newFancy'));

                    dispatch(productSwitched());

                    if (typeof window?.trackProductViewed === 'function') {
                        window?.trackProductViewed(data.productData);
                    }
                } else {
                    dispatch(updateHasBlockedCombinedProductButton(true));
                }

                dispatch(updateDisabledValues(data.disabledValues));
                dispatch(updateSelectedValues(data.selectedValues));
                dispatch(updateValuePrices(data.valuePrices));
            })
            .catch(() => {
                // Do nothing for now
            });
    };

    return { updateCombinedProduct };
};

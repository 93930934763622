import { useQuickEditorApi } from './UseQuickEditorApi';

interface UsePhoto {
    abortUpload: (photoReferenceId: string) => void;
    allowedFileTypeExpression: string;
    removeFailedPhoto: (photoReferenceId: string) => void;
    removeFailedPhotos: () => void;
    removePhoto: (photoReferenceId: string) => void;
    retryFailedPhoto: (photoReferenceId: string) => void;
    retryFailedPhotos: () => void;
    selectPhoto: (photoReferenceId: string) => void;
    uploadPhotos: (fileList: FileList) => void;
}

export const usePhoto = (): UsePhoto => {
    const { photoApi } = useQuickEditorApi();

    return {
        abortUpload: (photoReferenceId: string) => photoApi.abortUpload(photoReferenceId),
        allowedFileTypeExpression: photoApi.getAllowedFileTypeExpression(),
        removeFailedPhoto: (photoReferenceId: string) => photoApi.removeFailedPhoto(photoReferenceId),
        removeFailedPhotos: () => photoApi.removeFailedUploads(),
        removePhoto: (photoReferenceId: string) => photoApi.removePhoto(photoReferenceId, true),
        retryFailedPhoto: (photoReferenceId: string) => photoApi.retryUpload(photoReferenceId),
        retryFailedPhotos: () => photoApi.retryFailedUploads(),
        selectPhoto: (photoReferenceId: string) => photoApi.selectPhoto(photoReferenceId),
        uploadPhotos: (fileList: FileList) => photoApi.uploadPhotos(fileList),
    };
};

import type React from 'react';

const CartIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 39 42" width="39" height="42">
        <path
            d="M33.9 22.7l2.6-11.6c.1-.3 0-.7-.2-1s-.6-.4-.9-.4H10.3l-.7-3.2c-.1-.6-.6-1-1.1-1H3.7c-.7
            0-1.2.6-1.2 1.2S3 7.9 3.7 7.9h3.9l4.3 21.3c.1.5.6.9 1.1.9h19.7c.6 0 1.2-.5 1.2-1.2
            0-.6-.5-1.2-1.2-1.2H13.9l-.4-2.1 19.4-2.1c.5 0 .9-.3 1-.8zm-20.9.7L10.7 12h23.2l-2.1 9.4-18.8 2zM16
            30.5c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4.2c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3
            1.3-.6 1.3-1.3 1.3zM28.7 30.5c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3zm0 4.2c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3z"
        />
    </svg>
);

export default CartIcon;

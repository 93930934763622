import { window } from '../../../js/globals';
import type { ServerState } from '../types';
import { Environment } from './Environment/state';
import { getInitialPrimaryButton } from './Middleware/primaryCtaButtonMiddleware';
import type { ProductPageRootState } from './store';
import { createStore, initialProductPageState } from './store';
import initialUiState from './UI/state';

// Code is always executed in a browser environment so the window object is available
const state = window?.PRODUCT_INFORMATION_STATE as ServerState;

const productPageState: ProductPageRootState = {
    ...initialProductPageState,
    ...state,
    environment: {
        environment: Environment.BROWSER,
    },
};

const store = createStore({
    ...productPageState,
    ui: {
        ...initialUiState,
        primaryCtaButton: getInitialPrimaryButton(productPageState),
    },
});

export default store;

import type React from 'react';
import classNames from 'classnames';
import Picture from '../Picture/Picture';
import { useIsMobile } from '../hooks/useSize';
import Source from '../Picture/Source';
import { useIsInitialRequestMobile, useWithImageUrl } from '../WebshopContext/WebshopContext';

export const SHOPPING_AWARD_ALT_WEB = 'Winnaar Shopping Awards 2024 voor cadeaus';

interface ShoppingAwardProps {
    showOnDesktop: boolean;
    showOnMobile: boolean;
}

const ShoppingAward: React.FC<ShoppingAwardProps> = ({ showOnDesktop, showOnMobile }) => {
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());
    const withImageUrl = useWithImageUrl();

    if ((!showOnMobile && isMobileOrTablet) || (!showOnDesktop && !isMobileOrTablet)) {
        return null;
    }

    return (
        <div className={classNames(
            'mt-8',
            { 'mr-2 ml-2': isMobileOrTablet },
        )}>
            <Picture imageClass="u-h--auto u-w--full u-br--sm" alt={SHOPPING_AWARD_ALT_WEB} imageUrl={withImageUrl('/algemeen/shoppingawards-banner-2024.png')}>
                <Source framing={false} background={false} imageHeight={100} imageWidth={350}/>
                <Source framing={false} background={false} imageHeight={161} imageWidth={450}/>
            </Picture>
        </div>
    );
};

export default ShoppingAward;

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { CombinedArticleState } from './state';

interface CombinedArticleAction extends UnknownAction {
    payload: string;
    type: CombinedArticleActionType;
}

export enum CombinedArticleActionType {
    COMBINED_ARTICLE_LOADED = 'COMBINED_ARTICLE_LOADED',
    UPDATE_DISABLED_VALUES = 'UPDATE_DISABLED_VALUES',
    UPDATE_SELECTED_VALUES = 'UPDATE_SELECTED_VALUES',
    UPDATE_VALUE_PRICES = 'UPDATE_VALUE_PRICES',
}

export const combinedArticleLoaded = createAction<CombinedArticleState, CombinedArticleActionType>(CombinedArticleActionType.COMBINED_ARTICLE_LOADED);
export const updateDisabledValues = createAction<string[], CombinedArticleActionType>(CombinedArticleActionType.UPDATE_DISABLED_VALUES);
export const updateSelectedValues = createAction<string[], CombinedArticleActionType>(CombinedArticleActionType.UPDATE_SELECTED_VALUES);
export const updateValuePrices = createAction<Record<number, { hasDiscount: boolean; price: string }>, CombinedArticleActionType>(CombinedArticleActionType.UPDATE_VALUE_PRICES);

export type { CombinedArticleAction };

import type { Tracker } from '@yoursurprise/segment-analytics';
import type { Hit } from 'instantsearch.js';
import type { AlgoliaDesignHit } from '../Components/DesignGalleryBlock/DesignGalleryBlockVersionB';
import { sessionStorage } from '../../../js/util/storage';

export enum DesignTrackerLabels {
    DESIGN_CLICKED = 'Design Clicked',
    DESIGN_VIEWED = 'Design Viewed',
}

export class DesignTracker {
    public constructor(
        private readonly tracker: Tracker,
    ) {
    }

    public designClicked(payload: { designHit: Hit<AlgoliaDesignHit>; index: string; objectID: string; position: number; queryID?: string }): void {
        if (payload.designHit === undefined) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { __queryID, designId, objectID } = payload.designHit;

        const queryID = payload.queryID ?? __queryID;

        if (queryID !== undefined && designId !== null) {
            const designHitData = {
                designHitDesignId: designId,
                designHitObjectId: objectID,
                designHitQueryId: queryID,
            };
            sessionStorage.setItem('designHit', JSON.stringify(designHitData));
        }

        this.tracker.track(DesignTrackerLabels.DESIGN_CLICKED, payload);
    }

    public designViewed(designHits: Array<Hit<AlgoliaDesignHit>>, algoliaIndex: string, queryID?: string): void {
        this.tracker.track(DesignTrackerLabels.DESIGN_VIEWED, {
            designHits,
            index: algoliaIndex,
            objectIDs: designHits.map((hit) => hit.objectID),
            positions: designHits.map((hit, i) => i + 1),
            queryID,
        });
    }
}

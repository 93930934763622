import classNames from 'classnames';
import type React from 'react';
import './FloatingActionButton.scss';
import type { PropsWithChildren } from 'react';

interface Props {
    className?: string;
    disabled?: boolean;
    label: string;
    onClick: () => void;
}

const FloatingActionButton: React.FC<PropsWithChildren<Props>> = ({ children, className, disabled, label, onClick }) => (
    <button
        type="button"
        aria-label={label}
        className={classNames('floating-action-button', className)}
        disabled={disabled}
        onClick={onClick}
    >
        {children || label}
    </button>
);

export default FloatingActionButton;

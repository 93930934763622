import type { SearchResults } from 'algoliasearch-helper';
import type { BaseHit, Hit } from 'instantsearch.js';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useConfigure, useInstantSearch } from 'react-instantsearch-core';
import useHasAnalytics from '../../../algolia/general/Analytics/useHasAnalytics';
import ImageGalleryBlock from '../GalleryBlock/ImageGalleryBlock';
import IssuuPreview from '../IssuuPreview/IssuuPreview';
import DesignGallery from './DesignGallery/DesignGallery';
import DesignThumbGallery from './DesignThumbGallery/DesignThumbGallery';
import { Version } from '../../Store/MetaData/state';
import Delivery from '../Delivery/Delivery';
import Rating from '../InformationBlock/Rating/Rating';
import ProductAmountGalleryLabel from './ProductAmountGalleryLabel';
import { algoliaDesignResultsLoaded } from '../../Store/Product/action';
import { useTypedDispatch, useTypedSelector } from '../../Store/connectors';
import useFixAnalytics from '../../../algolia/general/Analytics/useFixAnalytics';

export interface AlgoliaDesignHit extends BaseHit {
    designId: number | null;
    designPath: string;
    imageUrl: string;
    thumbUrl: string;
    yimpDesignYimpId: number;
}

/**
 * This gallery has been set up as an A/B Test for the following articles: 93027, 93028, 93079 and 93072.
 *
 * The articles named above only have basic photos of the front view. In the A/B test they will not have atmospheric photos.
 *
 * Different views of the same design/article are not supported within this gallery, nor are videos. There is also no support for the quick editor.
 * For this reason this component should not be re-used outside of this A/B test and not be extended to other articles.
 *
 * This component (Version B) renders an altered image gallery where every slide is a design.
 * The thumbs are the design selection.
 */
const DesignGalleryBlockVersionB: FC = () => {
    const productId = useTypedSelector(({ product }) => product.productId);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const locale = useTypedSelector(({ meta }) => meta.locale.replace('-', '_'));
    const { results: untypedResults, status } = useInstantSearch();
    const results = untypedResults as SearchResults<Hit<AlgoliaDesignHit>>;
    const hasAnalytics = useHasAnalytics();
    const dispatch = useTypedDispatch();

    useFixAnalytics();

    useConfigure({
        analytics: hasAnalytics,
        analyticsTags: ['version_b'],
        clickAnalytics: hasAnalytics,
        facetFilters: [`productId:${productId}`, `locale:${locale}`, 'isB2C:true'],
        hitsPerPage: 12,
    });

    useEffect(() => {
        if (results.nbHits) {
            dispatch(algoliaDesignResultsLoaded({ hits: results.hits, index: results.index, queryID: results.queryID }));
        }
    }, [dispatch, results]);

    const isFetching = status === 'loading' || status === 'stalled';
    const isErroneous = status === 'error';
    const isCompleted = status === 'idle';

    if (isFetching) {
        return null;
    }

    if (isErroneous) {
        return <ImageGalleryBlock />;
    }

    if (isCompleted && !results.nbHits) {
        return <ImageGalleryBlock />;
    }

    return (
        <>
            <div className="product-information-desktop design-test">
                <h1 className="h3">{productTitle}</h1>
                <Rating />
                <Delivery />
            </div>
            <div id="galleryBlock" className="galleryBlock product-information__gallery design-test">
                <div className="productPageImageGallery">
                    <DesignGallery algoliaResults={results} version={Version.B} />
                    <IssuuPreview />
                    <ProductAmountGalleryLabel/>
                </div>
                {results.nbHits !== 1 && (
                    <DesignThumbGallery algoliaResults={results} testVersion={Version.B} />
                )}
            </div>
        </>
    );
};

export default DesignGalleryBlockVersionB;

import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import TextContentCount from '../TextContentCount/TextContentCount';
import './TextContentLine.scss';

export interface TextContentLineProps {
    autoFocus: boolean;
    maxLength: number;
    minLength: number;
    onChange: (textContent: string) => void;
    onConfirm?: () => void;
    onFocus?: () => void;
    readOnly: boolean;
    textContent: string;
}

export const TextContentLine: React.FC<TextContentLineProps> = ({
    autoFocus,
    maxLength,
    minLength,
    onChange,
    onConfirm,
    onFocus,
    readOnly,
    textContent,
}) => {
    const inputElement = useRef<HTMLInputElement>(null);
    const [textContentLength, setTextContentLength] = useState<number>(textContent.length);
    const confirmOnEnter = useCallback(({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputElement?.current && key === 'Enter' && !readOnly) {
            inputElement.current.blur();

            if (onConfirm) {
                onConfirm();
            }
        }
    }, [readOnly, onConfirm]);

    const onInput = () => {
        if (inputElement?.current) {
            setTextContentLength(inputElement.current.value.length);
        }
    };

    useEffect(() => {
        setTextContentLength(textContent.length);
    }, [textContent]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                inputElement.current?.focus();
            }, 500);
        }
    }, [inputElement, autoFocus]);

    return (
        <div className="text-content__textbox">
            <DebounceInput
                debounceTimeout={300}
                inputRef={inputElement}
                value={textContent}
                minLength={minLength}
                maxLength={maxLength}
                readOnly={readOnly}
                className="text-content__textbox__input"
                onFocus={() => onFocus && onFocus()}
                onKeyDown={confirmOnEnter}
                onChange={({ target }) => onChange(target.value.substr(0, maxLength))}
                onInput={onInput}
            />
            <TextContentCount currentLength={textContentLength} maxLength={maxLength} />
        </div>
    );
};

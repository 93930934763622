import { getGiftEditorApi } from '../../../gift-editor-api/Factory/GiftEditorApiFactory';
import { activeGraphicCleared, activeGraphicSet } from '../QuickEditorGraphic/action';
import { loadTextGraphicConstraintsThunk } from '../QuickEditorGraphic/thunk';
import { overlayDisplaySet } from './action';
import { ControlsDisplayOption } from './state';
import { ContentTypeEnum } from '../../../quickEditor/Types/ContentTypeEnum';
import type { TypedMiddleware } from '../store';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

type StoreApi = Parameters<typeof quickEditorUiMiddleware>[0];

const setOverlayDisplayForPhoto = (storeApi: StoreApi) => {
    storeApi.dispatch(overlayDisplaySet(ControlsDisplayOption.OVERLAY));
};

const setOverlayDisplayForText = (storeApi: StoreApi, graphicIdHasChanged: boolean) => {
    const giftEditorApiPromise = getGiftEditorApi(storeApi.getState().product, storeApi.getState().cart);

    if (graphicIdHasChanged) {
        giftEditorApiPromise.then((giftEditorApi) => {
            const textContent = giftEditorApi.getTextApi().getActiveTextGraphic().getTextLines();
            const hasTextContent = Boolean(textContent.join(''));

            if (hasTextContent) {
                storeApi.dispatch(overlayDisplaySet(ControlsDisplayOption.OVERLAY));
            } else {
                storeApi.dispatch(overlayDisplaySet(ControlsDisplayOption.FULLSCREEN));
            }
        }).catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
    }

    if (!graphicIdHasChanged && storeApi.getState().quickEditorUi.overlay.currentDisplay === ControlsDisplayOption.OVERLAY) {
        storeApi.dispatch(overlayDisplaySet(ControlsDisplayOption.FULLSCREEN));
    }
};

const loadTextGraphicConstraints = (storeApi: StoreApi): void => {
    storeApi.dispatch(loadTextGraphicConstraintsThunk()).catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
};

const hideOverlay = (storeApi: StoreApi): void => {
    storeApi.dispatch(overlayDisplaySet(ControlsDisplayOption.HIDDEN));
};

export const quickEditorUiMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    const statePriorToNext = storeApi.getState();

    next(action);

    if (activeGraphicSet.match(action)) {
        const { payload } = action;
        const graphicIdHasChanged = statePriorToNext.quickEditorGraphic.graphicId !== payload.graphicId;

        if (payload.contentType === ContentTypeEnum.TYPE_TEXT) {
            loadTextGraphicConstraints(storeApi);
            setOverlayDisplayForText(storeApi, graphicIdHasChanged);
        } else if (payload.contentType === ContentTypeEnum.TYPE_PHOTO) {
            setOverlayDisplayForPhoto(storeApi);
        }
    }

    if (activeGraphicCleared.match(action)) {
        getGiftEditorApi(storeApi.getState().product, storeApi.getState().cart)
            .then((giftEditorApi) => giftEditorApi.getFocusApi().clearFocus())
            .catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
        hideOverlay(storeApi);
    }

    if (overlayDisplaySet.match(action) && action.payload === ControlsDisplayOption.HIDDEN) {
        getGiftEditorApi(storeApi.getState().product, storeApi.getState().cart)
            .then((giftEditorApi) => giftEditorApi.getPreviewApi().resetZoomAnimated())
            .catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
    }
};

import type React from 'react';

const CircleWarningOutlineIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.899 33.899" width="33.899" height="33.899">
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 426" fill="none" d="M0 0h7.378v20.222H0z" />
            </clipPath>
        </defs>
        <g data-name="Group 617">
            <path
                data-name="ios checkmark circle outline"
                d="M23.957 10.267a.332.332 0 01.244.081s-.352-.081-.244-.081zM27.3 6.6a14.117 14.117 0
                00-10.351-4.318A14.117 14.117 0 006.6 6.6a14.117 14.117 0 00-4.318 10.349A14.117 14.117 0 006.6
                27.3a14.117 14.117 0 0010.349 4.319A14.117 14.117 0 0027.3 27.3a14.117 14.117 0 004.319-10.349A14.117
                14.117 0 0027.3 6.6zM4.971 4.971A16.326 16.326 0 0116.949 0a16.326 16.326 0 0111.979 4.971A16.326 16.326 0
                0133.9 16.949a16.326 16.326 0 01-4.971 11.979 16.326 16.326 0 01-11.98 4.972 16.326 16.326 0 01-11.978-4.972A16.326
                16.326 0 010 16.949 16.326 16.326 0 014.971 4.971zM33.9 32.595"
            />
            <g data-name="Group 609">
                <g data-name="Group 608" transform="translate(14 7)" clipPath="url(#a)" fill="#161615">
                    <path
                        data-name="Path 8"
                        d="M1.193 15.854a2.556 2.556 0 000 3.618 2.463 2.463 0 001.808.75 2.47 2.47 0 001.813-.75 2.562 2.562 0 000-3.618 2.465 2.465 0 00-1.813-.716 2.45 2.45 0 00-1.808.716"
                    />
                    <path
                        data-name="Path 9"
                        d="M.018 3.275l.937 8.552a2.015 2.015 0 00.605 1.235 1.972 1.972 0 001.254.59 1.981 1.981 0 001.493-.443
                        1.969 1.969 0 00.743-1.382l.94-8.552V2.7A2.939 2.939 0 004.895.681 2.859 2.859 0 002.713.017 2.9 2.9 0 00.689 1.091a2.853 2.853 0 00-.671 2.184"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default CircleWarningOutlineIcon;

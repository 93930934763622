import algoliasearch from 'algoliasearch';
import type { MultipleQueriesOptions, MultipleQueriesQuery, MultipleQueriesResponse, SearchResponse } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';

export const createSearchClient = (appId: string, searchApiKey: string): ReturnType<typeof algoliasearch> => algoliasearch(
    appId,
    searchApiKey,
);

export const createFacetedSearchClient = (appId: string, searchApiKey: string): ReturnType<typeof algoliasearch> => {
    const searchClient = createSearchClient(appId, searchApiKey);
    const search = <TObject>(queries: readonly MultipleQueriesQuery[], requestOptions?: RequestOptions & MultipleQueriesOptions): Readonly<Promise<MultipleQueriesResponse<TObject>>> => {
        const facetedQueries = [...queries];
        if (facetedQueries[0]) {
            facetedQueries[0] = {
                ...facetedQueries[0],
                params:
                {
                    ...facetedQueries[0].params,
                    facets: ['*'],
                },
            };
        }
        return searchClient.search<TObject>(facetedQueries, requestOptions).then((results) => ({
            ...results,
            results: (results.results as Array<SearchResponse<TObject>>).map((result) => ({
                ...result,
                userData: result.facets,
            })),
        }));
    };

    return {
        ...searchClient,
        search,
    };
};

import classNames from 'classnames';
import type React from 'react';
import './FloatingActionBar.scss';
import type { PropsWithChildren } from 'react';

export enum HorizontalSlot {
    LEFT = 'left',
    RIGHT = 'right',
}

interface Props {
    horizontalSlot: HorizontalSlot;
}

export const FloatingActionBar: React.FC<PropsWithChildren<Props>> = ({ children, horizontalSlot }) => (
    <div className={classNames('floating-action-bar', {
        'floating-action-bar--left': horizontalSlot === HorizontalSlot.LEFT,
        'floating-action-bar--right': horizontalSlot === HorizontalSlot.RIGHT,
    })}
    >
        {children}
    </div>
);

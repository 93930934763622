import { useCallback, useState } from 'react';
import fetch from '../../../js/api/fetch';
import type { VideoSources } from './Video';
import type { Potential } from '../../../js/types';
import { isObject } from '../../../js/typeguards';
import ErrorLogger from '../../../js/error-logger/ErrorLogger';

interface UseVimeoInterface {
    getVideoSources: (vimeoId: string) => void;
    videoSources: null | VideoSources;
}

function assertIsVideosSourcesResponse(response: Potential<VideoSources>): asserts response is VideoSources {
    const isValid = isObject(response)
        && typeof response?.hls === 'string'
        && typeof response?.regular === 'string'
        && typeof response?.poster === 'string';

    if (!isValid) {
        throw new Error('Got invalid video sources');
    }
}

const UseVimeo = (): UseVimeoInterface => {
    const [videoSources, setVideoSources] = useState<VideoSources | null>(null);

    const getVideoSources = useCallback((vimeoId: string) => {
        fetch(`/vimeo/get-video-sources?videoId=${vimeoId}`)
            .then<Potential<VideoSources>>((getVideoSourcesResponse) => getVideoSourcesResponse.json())
            .then((getVideoSourcesResponse) => {
                assertIsVideosSourcesResponse(getVideoSourcesResponse);
                setVideoSources(getVideoSourcesResponse);
            }).catch((e: Error) => {
                ErrorLogger.createFromGlobals()?.log(e);
            });
    }, []);

    return ({
        getVideoSources,
        videoSources,
    });
};

export default UseVimeo;

import type { TypedMiddleware } from '../store';
import { window } from '../../../../js/globals';
import { Version } from '../MetaData/state';
import { designSelected } from '../Product/action';

export const rememberDesignSelectionMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (designSelected.match(action)) {
        const { payload } = (action);

        if (storeApi.getState().meta.galleryAbTestVersion === Version.B && payload.designId && window) {
            const params = new URLSearchParams(window.location.search);

            params.set('designid', String(payload.designId));
            const newParams = `?${params.toString()}`;

            window.history.replaceState(null, '', newParams);
        }
    }

    next(action);
};

import classNames from 'classnames';
import type React from 'react';
import { useState } from 'react';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';
import type { GalleryThumb } from './DesignThumbGallery';
import { useEnvironment } from '../../../Hooks/UseEnvironment';
import { useSwiperNavigation } from '../../../Hooks/UseSwiperNavigation';

type DesignThumbSliderVersionAProps = {
    activeGallerySlide: number;
    imageFastlyParams: URLSearchParams;
    onThumbSelect: (index: number) => void;
    productTitle: string;
    thumbs: GalleryThumb[];
};

const DesignThumbSliderVersionA: React.FC<DesignThumbSliderVersionAProps> = ({
    activeGallerySlide,
    imageFastlyParams,
    onThumbSelect,
    productTitle,
    thumbs,

}) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const { isDesktopBrowser } = useEnvironment();
    const orientation = isDesktopBrowser ? 'vertical' : 'horizontal';
    const { isBrowser } = useEnvironment();
    const prevSlideButtonClass = orientation === 'vertical' ? 'swiperVerticalPrev' : 'swiperHorizontalPrev';
    const nextSlideButtonClass = orientation === 'vertical' ? 'swiperVerticalNext' : 'swiperHorizontalNext';

    const { showNavigation } = useSwiperNavigation(thumbs.length, 6, swiperInstance);

    const slidePrev = () => {
        const prevSlide = activeGallerySlide - 1;

        if (prevSlide >= 0) {
            onThumbSelect(prevSlide);
        }
    };

    const slideNext = () => {
        const nextSlide = activeGallerySlide + 1;
        const lastSlide = thumbs.length - 1;

        if (nextSlide <= lastSlide) {
            onThumbSelect(nextSlide);
        }
    };

    const prevSlideButton = (
        <button type="button" onClick={slidePrev} className={prevSlideButtonClass} aria-label="Previous image">
            <i className={classNames({ 'fa-light fa-chevron-left': orientation === 'horizontal', 'fa-light fa-chevron-up': orientation === 'vertical' })} />
        </button>
    );

    const nextSlideButton = (
        <button type="button" onClick={slideNext} className={nextSlideButtonClass} aria-label="Next image">
            <i className={classNames({
                'fa-light fa-chevron-down': orientation === 'vertical',
                'fa-light fa-chevron-right': orientation === 'horizontal',
            })}
            />
        </button>
    );

    const swiperConfig: SwiperOptions = {
        direction: orientation,
        modules: [Navigation],
        slidesPerView: 'auto',
    };

    if (showNavigation) {
        swiperConfig.navigation = {
            nextEl: `.${nextSlideButtonClass}`,
            prevEl: `.${prevSlideButtonClass}`,
        };
    }

    return (
        <section aria-label="Gallery thumbnails">
            <Swiper onSwiper={setSwiperInstance} className={ classNames('swiper-container gallerySliderThumbsContainer', { noNavigation: !showNavigation }) } { ...swiperConfig }>
                { thumbs.map(({ url }, index) => (
                    <SwiperSlide
                        key={ `${url}-${String(index)}` }
                        role="button"
                        tabIndex={ 0 }
                        onClick={ () => onThumbSelect(index) }
                        className={ activeGallerySlide === index ? 'slideActive' : '' }
                    >
                        <img loading="lazy"
                            src={ `${url}?${imageFastlyParams.toString()}` }
                            alt={ productTitle }
                            title={ productTitle }/>
                    </SwiperSlide>
                )) }
                { isBrowser && showNavigation && nextSlideButton }
                { isBrowser && showNavigation && prevSlideButton }
            </Swiper>
        </section>
    );
};

export default DesignThumbSliderVersionA;

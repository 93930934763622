import type React from 'react';
import Icon from '../../../../../../stories/Atoms/Icon/Icon';
import Translation from '../../../../../general/Translation/Translation';

const NoPersonalisationWarning: React.FC = () => (
    <div className="notification-box notification-box--warning">
        <div className="notification-box__message">
            <Icon name="fa-circle-exclamation" />
            <span>
                <Translation pageString="GiftEditorWidget" stringId="missing_required_content" />
            </span>
        </div>
    </div>
);

export default NoPersonalisationWarning;

import classNames from 'classnames';
import type React from 'react';
import DeliveryStatusInfo from './DeliveryStatusInfo';
import { useTypedSelector } from '../../Store/connectors';

const Delivery: React.FC = () => {
    const isDealPromotion = useTypedSelector(({ product }) => product.isDealPromotion);
    const testDateTime = useTypedSelector(({ shipping }) => shipping.testDateTime);

    return (
        <>
            <div className={classNames('deliveryStatus', { hidden: isDealPromotion })}>
                <DeliveryStatusInfo/>
            </div>
            {testDateTime && (
                <div className="testingDeliveryString">
                    Testing for: {testDateTime}
                </div>
            )}
        </>
    );
};

export default Delivery;

import type React from 'react';
import { useRef } from 'react';
import CircleWarningOutlineIcon from '../../General/Icon/CircleWarningOutlineIcon';
import CloseIcon from '../../General/Icon/CloseIcon';
import './NotificationOverlay.scss';
import { useTypedDispatch, useTypedSelector } from '../../../../productInformation/Store/connectors';
import { setNotificationVisibility } from '../../../../productInformation/Store/Notification/action';

/* eslint-disable react/no-danger */
const NotificationOverlay: React.FC = () => {
    const {
        notifications,
        visible,
    } = useTypedSelector(({ notification }) => notification);
    const overlayBackground = useRef<HTMLDivElement>(null);
    const dispatch = useTypedDispatch();

    if (!visible || notifications.length === 0) {
        return null;
    }

    const onOverlayBackgroundClick = (event: React.UIEvent<HTMLDivElement>) => {
        if (event.target === overlayBackground.current) {
            dispatch(setNotificationVisibility(false));
        }
    };

    return (
        <div
            ref={overlayBackground}
            className="notification-overlay-background"
            data-testid="background-close"
            onClick={onOverlayBackgroundClick}
        >
            <div className="notification-overlay">
                <button
                    aria-label="close"
                    type="button"
                    className="notification-overlay__close-button"
                    onClick={() => dispatch(setNotificationVisibility(false))}
                >
                    <CloseIcon />
                </button>
                {notifications.map(({ message, uid }) => (
                    <div className="notification-overlay__notification" key={uid}>
                        <CircleWarningOutlineIcon />
                        <div
                            className="notification-overlay__notification__message"
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationOverlay;

import type React from 'react';

const CloseIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
        <path
            d="M20.3 18.1L32 6.4c.3-.3.5-.7.5-1.1 0-.5-.2-.9-.5-1.2-.3-.3-.7-.4-1.1-.4-.4 0-.9.1-1.2.4L17.9 15.8 6.2
            4.2c-.2-.3-.6-.5-1.1-.5-.4 0-.8.2-1.1.5-.2.3-.4.7-.4 1.1 0 .4.1.9.4 1.2l11.6 11.6L4 29.8c-.3.3-.4.7-.4 1.1
            0 .4.1.9.4 1.2.1.2.3.3.5.4.2.1.4.1.6.1.2 0 .4 0 .6-.1.2-.1.4-.2.5-.4l11.6-11.7L29.7 32c.1.2.3.3.5.3.4.1.8.1 1.2 0 .2-.1.4-.2.5-.3.3-.3.5-.7.5-1.1 0-.5-.2-.9-.5-1.2L20.3 18.1z"
        />
    </svg>
);

export default CloseIcon;

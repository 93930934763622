import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { TextColorTile } from './TextColorTile';
import Slider from '../../../General/Slider/Slider';
import { useTextColor } from '../../../../Hooks/UseTextColor';
import './TextColorEditable.scss';
import { useTypedSelector } from '../../../../../productInformation/Store/connectors';

const TextColorEditable: React.FC = () => {
    const { setTextColor, textColor } = useTextColor();
    const colorConstraints = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.constraints?.color);
    const availableColors = useMemo(() => colorConstraints?.availableColors || [], [colorConstraints]);
    const [firstColor] = useState<string>(textColor);

    const sortedColors = useCallback(() => {
        const colors = [...availableColors];
        colors.sort((a, b) => Number(b === firstColor) - Number(a === firstColor));
        return colors;
    }, [availableColors, firstColor]);

    if (!colorConstraints?.allowed || availableColors.length === 0) {
        return null;
    }

    const tiles = sortedColors().map((color) => (
        <TextColorTile
            key={color}
            color={color}
            active={color === textColor}
            onSelect={() => setTextColor(color)}
        />
    ));

    return (
        <div className="text-color-tiles">
            <Slider>
                {tiles}
            </Slider>
        </div>
    );
};

export default TextColorEditable;

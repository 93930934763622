import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { EditorType } from './state';

interface MetaDataAction extends UnknownAction {
    payload: string;
    type: MetaDataActionType;
}

export enum MetaDataActionType {
    EDITOR_TYPE_SET = 'EDITOR_TYPE_SET',
}

export const editorTypeSet = createAction<EditorType | null, MetaDataActionType>(MetaDataActionType.EDITOR_TYPE_SET);

export type { MetaDataAction };

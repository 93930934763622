import type React from 'react';
import NotificationBox from '../../../../stories/Molecules/NotificationBox/NotificationBox';
import Translation from '../../../general/Translation/Translation';

const CoolerNotification: React.FC<{ isFree: boolean }> = ({ isFree }) => {
    const notificationPaid = <Translation pageString='productinformatie' stringId='temperature_notification_cooler_paid' />;
    const notificationFree = <Translation pageString='productinformatie' stringId='temperature_notification_cooler' />;

    return (<NotificationBox type="success" hasDefaultIcon={false} className="mt-4 mb-4">
        {isFree ? notificationFree : notificationPaid}
    </NotificationBox>);
};

export default CoolerNotification;

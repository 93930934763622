import type { RemoveNotificationEvent, ShowNotificationEvent } from '@yoursurprise/gift-editor';
import { useEffect } from 'react';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedDispatch } from '../../productInformation/Store/connectors';
import {
    addNotification,
    clearNotifications,
    removeNotification,
} from '../../productInformation/Store/Notification/action';

export const useGiftEditorNotificationsListener = (): void => {
    const { notificationApi } = useQuickEditorApi();
    const dispatch = useTypedDispatch();

    useEffect(() => {
        const showNotificationCallback = (event: ShowNotificationEvent) => dispatch(addNotification(event));
        const removeNotificationCallback = (event: RemoveNotificationEvent) => dispatch(removeNotification(event));
        const removeAllNotificationsCallback = () => dispatch(clearNotifications());

        const notificationListener = notificationApi.listenToNotificationRequests(showNotificationCallback, removeNotificationCallback, removeAllNotificationsCallback);

        return () => notificationListener();
    }, [notificationApi, dispatch]);
};

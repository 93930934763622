import type React from 'react';
import classNames from 'classnames';

export interface TextColorTileProps {
    active: boolean;
    color: string;
    onSelect: () => void;
}

const tileClasses = (active: boolean) => classNames('text-color-tiles__tile', {
    'text-color-tiles__tile--active': active,
});

export const TextColorTile: React.FC<TextColorTileProps> = ({ active, color, onSelect }) => (
    <div
        role="radio"
        tabIndex={0}
        aria-label={color}
        aria-checked={active}
        className={tileClasses(active)}
        onClick={onSelect}
        onKeyPress={onSelect}
        style={{ background: color }}
    />
);

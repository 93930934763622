import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { Middleware as GenericMiddleware } from '@reduxjs/toolkit';
import { rememberDesignSelectionMiddleware } from './Middleware/rememberDesignSelectionMiddleware';
import cartReducer from './Cart/reducer';
import combinedArticleReducer from './CombinedArticle/reducer';
import environmentReducer from './Environment/reducer';
import galleryReducer from './Gallery/reducer';
import metaDataReducer from './MetaData/reducer';
import { editorTypeMiddleware } from './Middleware/EditorTypeMiddleware';
import {
    productCanBeCombinedWithOtherProductsInCartMiddleware,
} from './Middleware/ProductCanBeCombinedWithOtherProductsInCartMiddleware';
import { openPageEditorMiddleware } from './Middleware/OpenPageEditorMiddleware';
import { primaryCtaButtonMiddleware } from './Middleware/primaryCtaButtonMiddleware';
import { scrollActivityMiddleware } from './Middleware/scrollActivityMiddleware';
import { trackerMiddleware } from './Middleware/trackerMiddleware';
import productReducer from './Product/reducer';
import shippingReducer from './Shipping/reducer';
import translationsReducer from './Translations/reducer';
import uiReducer from './UI/reducer';
import specificationsReducer from './Specifications/reducer';
import relatedProductsReducer from './RelatedProducts/action';
import { ctaButtonClickedMiddleware } from './Middleware/ctaButtonClickedMiddleware';
import { algoliaDesignResultsLoadedMiddleware } from './Middleware/algoliaDesignResultsLoadedMiddleware';
import { deliveryInformationMiddleware } from './Middleware/DeliveryInformationMiddleware';
import { cartEditMiddleware } from './Middleware/CartEditMiddleware';
import { notificationReducer } from './Notification/reducer';
import { personalisationReducer } from './Personalisation/reducer';
import { quickEditorGraphicReducer } from './QuickEditorGraphic/reducer';
import { quickEditorUiMiddleware } from './QuickEditorUi/middleware';
import { loggerMiddleware } from './Logger/middleware';
import { quickEditorUiReducer } from './QuickEditorUi/reducer';
import { initialQuickEditorUiState } from './QuickEditorUi/state';
import { initialPersonalisationState } from './Personalisation/state';
import { initialNotificationState } from './Notification/state';
import { initialQuickEditorGraphicState } from './QuickEditorGraphic/state';
import initialUiState from './UI/state';
import initialTranslationsState from './Translations/state';
import initialSpecificationsState from './Specifications/state';
import initialShippingState from './Shipping/state';
import initialRelatedProductsState from './RelatedProducts/state';
import initialProductState from './Product/state';
import initialMetaDataState from './MetaData/state';
import initialGalleryState from './Gallery/state';
import initialEnvironmentState from './Environment/state';
import initialCombinedArticleState from './CombinedArticle/state';
import initialCartState from './Cart/state';

export const initialProductPageState: ProductPageRootState = {
    cart: initialCartState,
    combinedArticle: initialCombinedArticleState,
    environment: initialEnvironmentState,
    gallery: initialGalleryState,
    meta: initialMetaDataState,
    notification: initialNotificationState,
    personalisation: initialPersonalisationState,
    product: initialProductState,
    quickEditorGraphic: initialQuickEditorGraphicState,
    quickEditorUi: initialQuickEditorUiState,
    relatedProducts: initialRelatedProductsState,
    shipping: initialShippingState,
    specifications: initialSpecificationsState,
    translations: initialTranslationsState,
    ui: initialUiState,
};

export const rootReducer = combineReducers({
    cart: cartReducer,
    combinedArticle: combinedArticleReducer,
    environment: environmentReducer,
    gallery: galleryReducer,
    meta: metaDataReducer,
    notification: notificationReducer,
    personalisation: personalisationReducer,
    product: productReducer,
    quickEditorGraphic: quickEditorGraphicReducer,
    quickEditorUi: quickEditorUiReducer,
    relatedProducts: relatedProductsReducer,
    shipping: shippingReducer,
    specifications: specificationsReducer,
    translations: translationsReducer,
    ui: uiReducer,
});

const productPageMiddleware: GenericMiddleware[] = [
    algoliaDesignResultsLoadedMiddleware,
    ctaButtonClickedMiddleware,
    trackerMiddleware,
    scrollActivityMiddleware,
    primaryCtaButtonMiddleware,
    editorTypeMiddleware,
    openPageEditorMiddleware,
    rememberDesignSelectionMiddleware,
    productCanBeCombinedWithOtherProductsInCartMiddleware,
    deliveryInformationMiddleware,
    cartEditMiddleware,
    quickEditorUiMiddleware,
];

type ProductPageRootState = ReturnType<typeof rootReducer>;

export const createStore = (preloadedState: Partial<ProductPageRootState>) => configureStore({
    devTools: { name: 'Product information' },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
        .concat(productPageMiddleware)
        .concat(loggerMiddleware),
    preloadedState,
    reducer: rootReducer,
});

type Store = ReturnType<typeof createStore>;
type TypedDispatch = Store['dispatch'];
// as Recommended by Redux keep using {} https://redux.js.org/usage/usage-with-typescript#type-checking-middleware
// eslint-disable-next-line @typescript-eslint/ban-types
type TypedMiddleware = GenericMiddleware<{}, ProductPageRootState, TypedDispatch>;

export type {
    TypedMiddleware,
    TypedDispatch,
    ProductPageRootState,
    Store,
};

import type React from 'react';
import { useEffect } from 'react';
import { DiscountPrice } from '../DiscountPrice/DiscountPrice';
import { PromotionPrice } from '../PromotionPrice/PromotionPrice';
import { RegularPrice } from '../RegularPrice/RegularPrice';
import { document } from '../../../../../../js/globals';

export interface PriceDisplayProps {
    dealPromotionPrice: number;
    discountPercentage: number;
    hasDealPromotionShipping: boolean;
    isDealPromotion: boolean;
    isDiscountPrice: boolean;
    isSoldOut: boolean;
    price: string;
    priceFrom: string;
    productId: number;
    shippingCostsText: string;
    siteUrl: string;
}

export const PriceDisplay: React.FC<PriceDisplayProps> = (
    {
        dealPromotionPrice,
        discountPercentage,
        hasDealPromotionShipping,
        isDealPromotion,
        isDiscountPrice,
        isSoldOut,
        price,
        priceFrom,
        productId,
        shippingCostsText,
        siteUrl,
    },
) => {
    useEffect(() => {
        // Because of timing issues when switching combined product we will always bind this again [YW-17090]
        document?.dispatchEvent(new CustomEvent('newFancy'));
    });

    if (isDiscountPrice) {
        return (
            <DiscountPrice
                priceFrom={priceFrom}
                price={price}
                discountPercentage={discountPercentage}
                isSoldOut={isSoldOut}
                siteUrl={siteUrl}
                productId={productId}
                shippingCostsText={shippingCostsText}
            />
        );
    }

    if (isDealPromotion) {
        return (
            <PromotionPrice
                dealPromotionPrice={dealPromotionPrice}
                hasDealPromotionShipping={hasDealPromotionShipping}
                price={price}
                shippingCostsText={shippingCostsText}
            />
        );
    }

    return (
        <RegularPrice
            price={price}
            isSoldOut={isSoldOut}
            siteUrl={siteUrl}
            productId={productId}
            shippingCostsText={shippingCostsText}
        />
    );
};

import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';
import NotificationBox from '../../../../stories/Molecules/NotificationBox/NotificationBox';

const DeliveryStatusInfo: React.FC = () => {
    const { isSoldOut } = useTypedSelector(({ product }) => product);
    const { deadlineText, isDelayed, isOutOfStock, outOfStockMessage } = useTypedSelector(({ shipping }) => shipping);

    if (isSoldOut) {
        return (
            <NotificationBox className="mt-6 mb-6" type="error">
                <span><Translation pageString="productinformatie" stringId="sold_out" /></span>
            </NotificationBox>
        );
    }

    if (isOutOfStock || isDelayed) {
        return (
            <NotificationBox className="mt-6 mb-6" type="error">
                <span>{outOfStockMessage}</span>
            </NotificationBox>
        );
    }

    return (
        <NotificationBox className="mt-6 mb-6" type="success">
            <span className="mb-1"><Translation pageString="productinformatie" stringId="in_stock"/></span>
            <span>{deadlineText}</span>
        </NotificationBox>
    );
};

export default DeliveryStatusInfo;

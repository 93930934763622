import type React from 'react';
import type { PropsWithChildren } from 'react';
import QuickEditor from '../../../../quickEditor/Components/App/QuickEditor';
import Loader from '../../../../quickEditor/Components/Mobile/Loading/Loader';
import LoadFailed from '../../../../quickEditor/Components/Mobile/Loading/LoadFailed';
import QuickEditorApiProvider from '../../../../quickEditor/Provider/QuickEditorApiProvider/QuickEditorApiProvider';
import './MobileQuickEditorOverlay.scss';

interface Props {
    onClose: () => void;
    showQuickEditor: boolean;
}

const Overlay: React.FC<PropsWithChildren> = ({ children }) => (
    <div className="mobile-quick-editor-overlay">
        <div className="mobile-quick-editor">
            {children}
        </div>
    </div>
);

const MobileQuickEditorOverlay: React.FC<Props> = ({ onClose, showQuickEditor }) => {
    const loader = showQuickEditor && (
        <Overlay>
            <Loader onClose={onClose} />
        </Overlay>
    );

    const loadFailed = showQuickEditor && (
        <Overlay>
            <LoadFailed onClose={onClose} />
        </Overlay>
    );

    return (
        <QuickEditorApiProvider loader={loader} loadFailed={loadFailed}>
            {showQuickEditor && <Overlay><QuickEditor onClose={onClose} /></Overlay>}
        </QuickEditorApiProvider>
    );
};

export default MobileQuickEditorOverlay;

import type React from 'react';
import { usePhotoCollection } from '../../../../../../Hooks/UsePhotoCollection';
import Slider from '../../../../../General/Slider/Slider';
import CloseGraphicButton from '../../../../FloatingActionBar/Button/CloseGraphicButton/CloseGraphicButton';
import NotificationButton from '../../../../FloatingActionBar/Button/NotificationButton/NotificationButton';
import { FloatingActionBar, HorizontalSlot } from '../../../../FloatingActionBar/FloatingActionBar';
import PhotoUploadInput from '../../../PhotoUploadInput/PhotoUploadInput';
import FailedPhotoCollection from '../../FailedPhotoCollection';
import PhotoCollection from '../../PhotoCollection';
import './PhotoControlsOverlayDisplay.scss';

const PhotoControlsOverlayDisplay: React.FC = () => {
    const {
        failedPhotos,
        hasFailedPhotos,
        stagedPhotos,
    } = usePhotoCollection();

    return (
        <>
            <FloatingActionBar horizontalSlot={HorizontalSlot.RIGHT}>
                <CloseGraphicButton />
                <NotificationButton />
            </FloatingActionBar>
            <div className="photo-controls__wrapper">
                {hasFailedPhotos() ? <FailedPhotoCollection failedPhotos={failedPhotos} /> : (
                    <Slider>
                        <>
                            <PhotoUploadInput allowMultipleFiles />
                            <PhotoCollection stagedPhotos={stagedPhotos} />
                        </>
                    </Slider>
                )}
            </div>
        </>
    );
};

export default PhotoControlsOverlayDisplay;

import { useEffect, useState } from 'react';
import type { SwiperClass } from 'swiper/react';
import { useTypedSelector } from '../Store/connectors';
import type { Device } from '../Util/Device';
import { useEnvironment } from './UseEnvironment';

interface UseSwiperNavigation {
    showNavigation: boolean;
    swiperShouldRebuild: boolean; // Rebuild the entire swiper instance (when navigation is shown/hidden)
    swiperShouldUpdate: boolean; // Update swiper contents (when the slides change)
}

export const useSwiperNavigation = (amountOfSlides: number, slidesPerSnap: number, swiper?: SwiperClass | null | undefined): UseSwiperNavigation => {
    const { device } = useEnvironment();
    const activeGallerySlide = useTypedSelector(({ gallery }) => gallery.activeGallerySlide);
    const [previousDevice, setPreviousDevice] = useState<Device | undefined>();
    const [previousAmountOfSlides, setPreviousAmountOfSlides] = useState<number>(amountOfSlides);
    const [swiperShouldRebuild, setSwiperShouldRebuild] = useState<boolean>(false);
    const hadNavigationBefore = previousAmountOfSlides > slidesPerSnap;
    const hasNavigation = amountOfSlides > slidesPerSnap;

    useEffect(() => {
        const activeSlideIsSnap = Math.floor(activeGallerySlide / slidesPerSnap);

        if (swiper?.realIndex && swiper?.realIndex !== activeSlideIsSnap) {
            swiper?.slideTo(activeGallerySlide);
        }
    }, [swiper, activeGallerySlide, slidesPerSnap]);

    useEffect(() => {
        if (hadNavigationBefore !== hasNavigation) {
            setSwiperShouldRebuild(true);
        }
    }, [hadNavigationBefore, hasNavigation]);

    useEffect(() => {
        if (device !== previousDevice) {
            setSwiperShouldRebuild(true);
            setPreviousDevice(device);
        }
    }, [device, previousDevice]);

    useEffect(() => setPreviousAmountOfSlides(amountOfSlides), [amountOfSlides, setPreviousAmountOfSlides]);

    useEffect(() => setSwiperShouldRebuild(false), [swiperShouldRebuild]);

    return {
        showNavigation: hasNavigation,
        swiperShouldRebuild,
        swiperShouldUpdate: true,
    };
};

export type { UseSwiperNavigation };

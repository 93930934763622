import type React from 'react';
import classNames from 'classnames';
import type { AvailableFont } from '@yoursurprise/gift-editor';

export interface TextFontTileProps {
    active: boolean;
    font: AvailableFont;
    onSelect: () => void;
}

const tileClasses = (active: boolean) => classNames('text-font-tiles__tile', {
    'text-font-tiles__tile--active': active,
});

export const TextFontTile: React.FC<TextFontTileProps> = ({ active, font, onSelect }) => (
    <div
        role="radio"
        tabIndex={0}
        aria-checked={active}
        className={tileClasses(active)}
        onClick={onSelect}
        onKeyPress={onSelect}
    >
        <img src={font.thumb} alt={font.name} />
    </div>
);

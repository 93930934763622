import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';

const ShippingMethods: React.FC = () => {
    const methods = useTypedSelector(({ shipping }) => shipping.methods);
    const deliveryInformationLoaded = useTypedSelector(({ shipping }) => shipping.deliveryInformationLoaded);
    const isGiftVoucher = useTypedSelector(({ product }) => product.isGiftVoucher);
    const isSoldOut = useTypedSelector(({ product }) => product.isSoldOut);

    if (isGiftVoucher || isSoldOut || methods.length === 0) {
        return null;
    }

    return (
        <div className="shipping-methods">
            <div className="shipping-methods__title">
                <Translation pageString="productinformatie" stringId="shipping_block_title"/>
            </div>
            <div className="shipping-method">
                {methods.map((method) => {
                    // We've loaded the additional delivery information, but there's no information for this method
                    // This means that the delivery option is not available in which case we'll hide it
                    if (deliveryInformationLoaded && !method.additionalInfoAvailable) {
                        return null;
                    }

                    return (
                        <div key={method.method} className="shipping-container">
                            <div className="shipping-information">
                                <div className="shipping-information__method">
                                    <div className="shipping-information__method__text">
                                        {deliveryInformationLoaded && (method.deliveryText ?? '')}
                                    </div>
                                    <div className="shipping-information__method__price">
                                        {deliveryInformationLoaded && (method.salePriceFormatted ?? '')}
                                    </div>
                                </div>
                                <div className="shipping-information__wrapper shipping-information__method__title">
                                    {deliveryInformationLoaded && (method.trackingText ?? '')}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ShippingMethods;

import { useQuickEditorApi } from './UseQuickEditorApi';

interface UseTextFont {
    setTextFontId: (textFontId: number) => void;
    textFontId: number;
}

export const useTextFont = (): UseTextFont => {
    const { textApi } = useQuickEditorApi();
    const setTextFontId = (textFontId: number) => textApi.getActiveTextGraphic().setFont(textFontId);

    return {
        setTextFontId,
        textFontId: textApi.getActiveTextGraphic().getFont(),
    };
};

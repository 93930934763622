import type React from 'react';

const OneFingerTapIcon: React.FC = () => (
    <svg className="icon" aria-label="tap icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
        <path
            d="M18.8 6.1c0-1.1-.4-2.2-1.2-3-.8-.8-1.9-1.2-3-1.2s-2.2.4-3 1.2c-.8.8-1.3 1.9-1.2
            3v3.4c-.5-.5-.8-1-1-1.6-.2-.6-.3-1.2-.3-1.8 0-1.4.5-2.8 1.6-3.8 1-1 2.4-1.6 3.8-1.6s2.8.5 3.8 1.6c1 1 1.6
            2.4 1.6 3.8 0 .6-.1 1.3-.3 1.8-.2.6-.5 1.1-.9 1.6l.1-3.4zm3 28.3c1.2 0 2.4-.2 3.5-.8 1.1-.5 2.1-1.2 2.9-2
            .8-.8 1.5-1.8 1.9-2.9.5-1.1.7-2.2.7-3.4v-7.2c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8
            1.8v.7H26v-3c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8V17.7h-1.2v-3c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8
            1.8V19h-1.2V6.1c0-1-.8-1.8-1.8-1.8-.5 0-.9.2-1.3.5-.3.3-.5.8-.5
            1.3v13.7c-1.2-1.3-2.6-2.5-4-3.6s-2.4-1.3-3.1-.6c-.3.4-.4 1-.1 1.5.3.9.8 1.7 1.3 2.5.6 1 1.4
            2.3 2.4 3.7 1 1.5 2.1 3.2 3.2 5.2 1 1.7 2.2 3.2 3.8 4.4 1.5 1 3.5 1.6 5.5 1.5zm10.2-9c0 1.4-.3 2.7-.8
            4-1.1 2.4-3 4.4-5.5 5.5-1.3.5-2.6.8-4 .8-1
            0-1.9-.1-2.8-.3-1-.3-1.9-.7-2.8-1.2-1-.6-1.8-1.3-2.6-2.1-.9-.9-1.7-2-2.3-3.1-.9-1.7-1.9-3.4-3-4.9-1-1.5-1.9-2.8-2.6-4-.6-1-1.1-2-1.5-3.1-.3-.8
            0-1.6.5-2.2.8-.9 2.1-1 3.2-.4 1.3.7 2.6 1.5 3.6 2.5V6.1c0-.8.3-1.6.9-2.1 1.2-1.2 3.1-1.2 4.2 0 .6.6.9 1.3.9
            2.1v6c.3-.2.5-.3.8-.4.3-.1.6-.2 1-.2.6 0 1.2.2 1.8.5.5.3.9.8 1.1 1.4.3-.2.6-.4.9-.5.3-.1.7-.2 1.1-.2 1.7 0 3
            1.4 3 3 .3-.2.5-.3.8-.4.3-.1.6-.2 1-.2.8 0 1.6.3 2.1.9.6.5.9 1.3.9 2.1v7.3z"
        />
    </svg>
);

export default OneFingerTapIcon;

import { createReducer } from '@reduxjs/toolkit';
import { cartEditPersonalisationLoaded, finishedPersonalising } from './action';
import { initialPersonalisationState } from './state';
import { designSelected } from '../Product/action';

export const personalisationReducer = createReducer(initialPersonalisationState, (builder) => {
    builder.addCase(cartEditPersonalisationLoaded, (state, { payload }) => ({
        ...state,
        isPersonalised: payload.isPersonalised,
        yimp: payload.yimp,
    }));

    builder.addCase(finishedPersonalising, (state, { payload }) => ({
        ...state,
        isPersonalised: payload.isPersonalised,
        yimp: payload.yimp,
    }));

    builder.addCase(designSelected, (state, { payload }) => {
        if (payload.algoliaData === undefined || payload.algoliaData.designHit === undefined) {
            return state;
        }

        return { ...state, yimpDesignYimpId: payload.algoliaData.designHit.yimpDesignYimpId };
    });
});

import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { document, window } from '../../../js/globals';
import { stickyFooterButtonToggled } from '../Store/UI/action';
import { useEnvironment } from './UseEnvironment';
import { useTypedDispatch, useTypedSelector } from '../Store/connectors';

export const useStickyFooterButton = (buttonRef: RefObject<HTMLElement>): void => {
    const dispatch = useTypedDispatch();
    const { isMobileBrowser } = useEnvironment();
    const quickEditorActive = useTypedSelector(({ product }) => product.quickEditorActive);

    const stickyFooterButtonSupported = isMobileBrowser
        && !quickEditorActive;

    const [isSticky, setSticky] = useState<boolean>();

    useEffect(() => {
        if (typeof isSticky === 'boolean') {
            dispatch(stickyFooterButtonToggled(isSticky));
        }
    }, [dispatch, isSticky]);

    const scrollListener = useCallback(() => {
        if (window && document && buttonRef.current) {
            if (!stickyFooterButtonSupported) {
                setSticky(false);
                return;
            }

            const { innerHeight, scrollY } = window;
            const { scrollHeight } = document.body;
            let offset = 0;

            const header = document.querySelector('header.header');
            if (header instanceof HTMLElement && window.getComputedStyle(header).position === 'sticky') {
                offset += header.clientHeight;
            }

            if (buttonRef.current.getBoundingClientRect().top < innerHeight && (buttonRef.current.getBoundingClientRect().bottom - offset) > 0) {
                setSticky(false);
                return;
            }

            if ((scrollHeight - scrollY) < 1850 - offset) {
                setSticky(false);
                return;
            }
            setSticky(true);
        }
    }, [stickyFooterButtonSupported, buttonRef]);

    useEffect(() => {
        scrollListener();
    }, [scrollListener]);

    useEffect(() => {
        if (stickyFooterButtonSupported) {
            document?.addEventListener('scroll', scrollListener);
        }

        return () => document?.removeEventListener('scroll', scrollListener);
    }, [scrollListener, stickyFooterButtonSupported]);
};

import { createReducer } from '@reduxjs/toolkit';
import { initialQuickEditorUiState } from './state';
import { composerLoaded, overlayDisplaySet } from './action';

export const quickEditorUiReducer = createReducer(initialQuickEditorUiState, (builder) => {
    builder.addCase(overlayDisplaySet, (state, { payload }) => ({
        ...state,
        overlay: {
            currentDisplay: payload,
            prevDisplay: state.overlay.currentDisplay,
        },
    }));
    builder.addCase(composerLoaded, (state) => ({ ...state, composer: { loaded: true } }));
});

import type { SearchResults } from 'algoliasearch-helper';
import type { Hit } from 'instantsearch.js';
import type { FC, ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperOptions } from 'swiper/types';
import type { SwiperEvents } from 'swiper/types/swiper-events';
import type { AlgoliaDesignHit } from '../DesignGalleryBlockVersionB';
import { Version } from '../../../Store/MetaData/state';
import { designSelected } from '../../../Store/Product/action';
import { useTypedDispatch, useTypedSelector } from '../../../Store/connectors';
import { activeGallerySlideSet } from '../../../Store/Gallery/action';

interface Props {
    algoliaResults: SearchResults<Hit<AlgoliaDesignHit>>;
    version: Version;
}

const DesignGallery: FC<Props> = ({ algoliaResults, version }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const activeGallerySlide = useTypedSelector(({ gallery }) => gallery.activeGallerySlide);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (swiperInstance && swiperInstance.realIndex !== activeGallerySlide) {
            swiperInstance?.slideTo(activeGallerySlide);
        }
    }, [swiperInstance, activeGallerySlide]);

    useEffect(() => {
        const slideChangeListener: SwiperEvents['slideChange'] = (currentSwiperInstance) => {
            dispatch(activeGallerySlideSet({ position: currentSwiperInstance.realIndex }));

            const selectedDesign = algoliaResults.hits[currentSwiperInstance.realIndex];
            const designId = selectedDesign?.designId;
            if (version === Version.B && designId) {
                dispatch(designSelected({ algoliaData: { designHit: selectedDesign, testVersion: version }, designId }));
            }
        };

        swiperInstance?.on('slideChange', slideChangeListener);

        return () => swiperInstance?.off('slideChange', slideChangeListener);
    }, [swiperInstance, algoliaResults.hits, dispatch, version]);

    const imageFastlyParams = new URLSearchParams({
        'bg-color': 'ffffff',
        canvas: version === Version.B ? '3:2' : '1:1',
        fit: 'bounds',
        format: 'jpg',
        height: version === Version.B ? '1200' : '1800',
        width: '1800',
    });

    const slides = algoliaResults.hits.map<ReactElement>((hit, index) => {
        const imgSrc = `${hit.imageUrl}?${imageFastlyParams.toString()}`;
        return <SwiperSlide key={ hit.imageUrl }>
            <img fetchpriority={ activeGallerySlide === index ? 'high' : 'auto' }
                loading={ activeGallerySlide === index ? 'eager' : 'lazy' }
                src={ imgSrc }
                alt={ productTitle }
                title={ productTitle }/>
        </SwiperSlide>;
    });

    const swiperConfig: SwiperOptions = {
        centeredSlides: true,
        setWrapperSize: false, // Leaving this on causes measurement issues with the swiper height when there is a composer preview as a slide
    };

    return <Swiper onSwiper={ setSwiperInstance } className='swiper-container gallerySliderContainer' { ...swiperConfig }>{ slides }</Swiper>;
};
export default DesignGallery;

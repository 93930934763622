import type React from 'react';

const CheckmarkIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" aria-label="checkmark" viewBox="0 0 36 36" width="36" height="36">
        <path
            d="M27.2 12.5c.1.1.1.3 0 .5L15.7 24.5c-.3.3-.6.4-1 .4s-.7-.2-.9-.4L9 19.7c-.1-.1-.2-.3-.1-.5l.1-.1 1.5-1.5c.2-.1.4-.1.5 0l3.8 3.9L25.3 11c.2-.1.4-.1.5 0l1.4 1.5z"
        />
    </svg>
);

export default CheckmarkIcon;

import type { Yimp } from '@yoursurprise/gift-editor';

interface PersonalisationState {
    isPersonalised: boolean;
    yimp: Yimp | null;
    yimpDesignYimpId: number | null;
}

export const initialPersonalisationState: PersonalisationState = {
    isPersonalised: false,
    yimp: null,
    yimpDesignYimpId: null,
};

export type { PersonalisationState };

import type { FC } from 'react';
import './DesignThumbSliderProgressBar.scss';

interface Props {
    amountOfDesigns: number;
    firstFullyVisibleSlideIndex?: number;
    lastFullyVisibleSlideIndex?: number;
}

const DesignThumbSliderProgressBar: FC<Props> = (
    {
        amountOfDesigns,
        firstFullyVisibleSlideIndex,
        lastFullyVisibleSlideIndex,
    },
) => {
    const totalProgression = amountOfDesigns - 1;
    let progress = 0;

    if (firstFullyVisibleSlideIndex !== undefined) {
        if (firstFullyVisibleSlideIndex === 0) {
            progress = 0;
        } else if (lastFullyVisibleSlideIndex === totalProgression) {
            progress = (100 / amountOfDesigns) * totalProgression;
        } else {
            progress = (firstFullyVisibleSlideIndex * 100) / totalProgression;
        }
    }

    return (
        <div className="design-thumb-slider-progress">
            <div className="design-thumb-slider-progress__bar">
                <div data-testid="progress-indicator" className="design-thumb-slider-progress__bar__indicator" style={
                    {
                        left: `${progress}%`,
                        width: `${100 / amountOfDesigns}%`,
                    }
                }></div>
            </div>
        </div>
    );
};

export default DesignThumbSliderProgressBar;

import type { GiftEditorApiFactory, ExistingPersonalisation, GiftEditorApiOptions } from '@yoursurprise/gift-editor';
import fetch from '../../../js/api/fetch';
import { window } from '../../../js/globals';
import type { ProductState } from '../../productInformation/Store/Product/state';
import InstanceProxy from '../Proxy/InstanceProxy';
import type { CartState } from '../../productInformation/Store/Cart/state';

interface GiftEditorWidgetDataResponse {
    baseUrl: string;
    cdnUrl: string;
    debug?: boolean;
    editHash?: string;
    partnerId: number;
    partnerKey: string;
    productEditorId?: number;
    productId: number;
    refId?: string;
    variantId: number;
}

type ApiOptionsRequestParameters =
    GiftEditorWidgetDataResponse
    & { existingPersonalisation?: ExistingPersonalisation };

const proxy = new InstanceProxy<Promise<GiftEditorApiOptions>>();
let giftEditorApiOptionsFactory: GiftEditorApiFactory | undefined;

const getGiftEditorApiFactory = async (): Promise<GiftEditorApiFactory> => {
    // The Gift Editor cannot be imported directly due to issues with SSR & DOM mutations,
    // so we have to load it later when the DOM is available.
    // We only create one instance to preserve internal caching.
    const { GiftEditorApiFactory: Factory } = await import('@yoursurprise/gift-editor');

    if (!giftEditorApiOptionsFactory) {
        giftEditorApiOptionsFactory = new Factory((window as Window).siteMetadata.siteUrl);
    }

    return giftEditorApiOptionsFactory;
};

export const getGiftEditorApiOptions = async (product: Pick<ProductState, 'productId' | 'variantId'>, cart: Omit<CartState, 'giftId' | 'isSvgCartEdit'>, existingPersonalisation: ExistingPersonalisation | undefined): Promise<GiftEditorApiOptions> => {
    const proxyInstance = proxy.getCachedInstance({ ...product, ...cart });

    if (proxyInstance) {
        return proxyInstance;
    }

    const createNewInstance = async (): Promise<GiftEditorApiOptions> => {
        const { productId, variantId } = product;
        const { editHash, isCartEdit, uniqueId } = cart;

        const url = `${(window as Window).siteMetadata.siteUrl}product-page/gift-editor-widget-data?`;
        const settingsDataParams = new URLSearchParams({
            productId: String(productId),
            variantId: String(variantId),
        });

        if (isCartEdit && uniqueId && editHash) {
            settingsDataParams.set('uniqueId', uniqueId);
            settingsDataParams.set('editHash', editHash);
        }

        const response = await fetch(url + settingsDataParams.toString());
        const settings = await response.json() as GiftEditorWidgetDataResponse;

        const apiOptions: ApiOptionsRequestParameters = { ...settings };
        if (existingPersonalisation) {
            apiOptions.existingPersonalisation = existingPersonalisation;
        }

        return (await getGiftEditorApiFactory()).getApiOptions(apiOptions);
    };

    const newInstance = createNewInstance();

    proxy.setInstance({ ...product, ...cart }, newInstance);

    return newInstance;
};

import type React from 'react';

const ChevronDownIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
        <path
            d="M35.3 7.7c-.6-.6-1.5-.6-2.1 0L18 24.4 2.7 7.7c-.3-.3-.7-.4-1-.4-.4 0-.8.1-1 .4-.7.5-.7 1.4-.1 2l.1.1
            16.2 17.8c.1.1.3.3.5.3.1.1.3.1.6.1.2 0 .4 0 .6-.1.2-.1.4-.2.5-.3L35.3 9.8c.6-.6.6-1.5 0-2.1z"
        />
    </svg>
);

export default ChevronDownIcon;

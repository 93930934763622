import type { ShowNotificationEvent } from '@yoursurprise/gift-editor';

export interface NotificationState {
    notifications: Array<ShowNotificationEvent & { uid: string }>;
    visible: boolean;
}

export const initialNotificationState: NotificationState = {
    notifications: [],
    visible: true,
};

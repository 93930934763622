import type React from 'react';
import { lazy, Suspense, useEffect, useState } from 'react';
import type { WebshopContextData } from '../../general/WebshopContext/WebshopContextProvider';

type EditorProps = {
    context: WebshopContextData;
    giftId?: string;
    isOpen: boolean;
    onClose: () => void;
    productId: number;
};

const LazyEditor = lazy(() => import('./GiftEditor'));

/**
 * The loader itself should have minimal impact on the page, and should lazy load the actual editor, once its needed.
 */
const GiftEditorLoader: React.FC<EditorProps> = ({ context, giftId, isOpen, onClose, productId }) => {
    const [hasBeenOpened, setHasBeenOpened] = useState(false);

    useEffect(() => {
        if (isOpen && !hasBeenOpened) {
            setHasBeenOpened(true);
        }
    }, [isOpen, hasBeenOpened]);

    if (hasBeenOpened) {
        return (
            <Suspense fallback={null}>
                <LazyEditor key={productId} context={context} productId={productId} onClose={onClose} isOpen={isOpen} giftId={giftId} />
            </Suspense>
        );
    }

    return null;
};

export default GiftEditorLoader;

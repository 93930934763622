import { finishedPersonalising } from '../Personalisation/action';
import type { ProductPageRootState, TypedMiddleware } from '../store';
import { primaryCtaButtonSet, productPageLoaded } from '../UI/action';
import { PrimaryCtaButton } from '../UI/state';

export const getInitialPrimaryButton = (state: ProductPageRootState): PrimaryCtaButton => {
    const { cart, personalisation, product } = state;
    const { isCartEdit } = cart;
    const { isPersonalizable, quickEditorActive } = product;
    const { isPersonalised } = personalisation;

    switch (true) {
        case !isPersonalizable:
            return PrimaryCtaButton.AddToCart;
        case !quickEditorActive:
        case isCartEdit:
        case !isPersonalised:
            return PrimaryCtaButton.StartPersonalising;
        default:
            return PrimaryCtaButton.AddToCart;
    }
};

export const primaryCtaButtonMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (productPageLoaded.match(action)) {
        const initialPrimaryButton = getInitialPrimaryButton(storeApi.getState());
        storeApi.dispatch(primaryCtaButtonSet(initialPrimaryButton));
    }

    if (finishedPersonalising.match(action)) {
        const { isPersonalised } = action.payload;

        if (isPersonalised) {
            storeApi.dispatch(primaryCtaButtonSet(PrimaryCtaButton.AddToCart));
        } else {
            storeApi.dispatch(primaryCtaButtonSet(PrimaryCtaButton.StartPersonalising));
        }
    }

    next(action);
};

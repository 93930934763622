import type React from 'react';
import { useEffect, useState } from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import Section from '../../../general/Section/Section';
import ProductBox from '../../../general/ProductBox/ProductBox';
import { useWithSiteUrl } from '../../../general/WebshopContext/WebshopContext';
import mapHitToProduct from '../../../algolia/general/Analytics/mapHitToProduct';
import Translation from '../../../general/Translation/Translation';
import tracker from '../../../general/Tracker/tracker';
import { useTypedSelector } from '../../Store/connectors';

const RelatedProducts: React.FC = () => {
    const currency = useTypedSelector(({ product }) => product.currency);
    const productId = useTypedSelector(({ product }) => product.productId);
    const relatedProducts = useTypedSelector((state) => state.relatedProducts);
    const [originalProductId] = useState(productId);

    const withSiteUrl = useWithSiteUrl();

    useEffect(() => {
        const products = relatedProducts.map((hit, position) => (
            mapHitToProduct(hit, currency, position + 1)
        ));
        tracker.trackProductListViewed('Related Products', products, { viewedProductId: originalProductId });
    }, [relatedProducts, currency, originalProductId]);

    if (relatedProducts.length === 0) {
        return null;
    }

    return (
        <Section hasNoPaddingXs>
            <div className="content">
                <div className="h2">
                    <Translation pageString="productinformatie" stringId="similar_gifts"/>
                </div>
            </div>
            <div className="product-list is-grid has-col-4-xl has-col-3-md has-col-2 has-row-gap-md">
                {relatedProducts.map((product) => (
                    <ProductBox
                        alt={product.productH1}
                        key={product.productId}
                        href={product.url}
                        image={product.image}
                        price={{ currency, value: product.price.current }}
                        priceFrom={{ currency, value: product.price.from }}
                        onProductClicked={() => {
                            tracker?.trackProductClicked({
                                currency,
                                image: product.image,
                                label: 'Related Products',
                                name: product.name,
                                price: product.price.current,
                                productId: product.productId,
                                url: withSiteUrl(product.url),
                                value: product.price.current,
                            } as Parameters<Tracker['trackProductClicked']>[0]);

                            return true;
                        }}
                        title={product.name}
                        hasFreeCoolerNotification={product.hasFreeCoolerNotification}
                    />
                ))}
            </div>
        </Section>
    );
};

export default RelatedProducts;

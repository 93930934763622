import { Tracker as BaseTracker } from '@yoursurprise/segment-analytics';
import { Tracker } from './Tracker';

export const createTracker = (
    isCartEdit: boolean,
    partnerId: number,
    productId: number,
    variantId: number,
): Tracker => new Tracker(
    new BaseTracker(),
    { isCartEdit, partnerId, productId, variantId },
);

import type React from 'react';
import ControlsDisplayWrapper from '../../Display/ControlsDisplayWrapper';
import PhotoControlsOverlayDisplay from './Display/PhotoControlsOverlayDisplay/PhotoControlsOverlayDisplay';
import './PhotoControls.scss';
import { useTypedSelector } from '../../../../../productInformation/Store/connectors';

const PhotoControls: React.FC = () => {
    const prevDisplay = useTypedSelector(({ quickEditorUi }) => quickEditorUi.overlay.prevDisplay);

    return (
        <ControlsDisplayWrapper shouldZoomPreview prevDisplay={prevDisplay} classNamePrefix='photo-controls'>
            <PhotoControlsOverlayDisplay />
        </ControlsDisplayWrapper>
    );
};

export default PhotoControls;

import type React from 'react';
import { useImageUrl } from '../../../Hooks/UseImageUrl';
import CloseQuickEditorButton from '../FloatingActionBar/Button/CloseQuickEditorButton/CloseQuickEditorButton';
import { FloatingActionBar, HorizontalSlot } from '../FloatingActionBar/FloatingActionBar';
import './Loader.scss';

interface Props {
    onClose?: () => void;
}

const Loader: React.FC<Props> = ({ onClose }) => {
    const loadingImage = useImageUrl('editor/loader/default.gif');

    return (
        <div className="mobile-quick-editor__loader">
            {onClose && (
                <FloatingActionBar horizontalSlot={HorizontalSlot.LEFT}>
                    <CloseQuickEditorButton onClose={onClose} />
                </FloatingActionBar>
            )}
            <img alt="Loading..." src={loadingImage} />
        </div>
    );
};

export default Loader;

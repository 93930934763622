import { useEffect, useState } from 'react';
import type { TextAlignment } from '../Types/TextAlignment';
import { useQuickEditorApi } from './UseQuickEditorApi';
import { useTypedSelector } from '../../productInformation/Store/connectors';

export interface UseTextAlignment {
    isAllowed: boolean;
    setTextAlignment: (textAlignment: TextAlignment) => void;
    textAlignment: TextAlignment;
}

export const useTextAlignment = (): UseTextAlignment => {
    const { textApi } = useQuickEditorApi();
    const [textAlignment, setTextAlignment] = useState<TextAlignment>(textApi.getActiveTextGraphic().getTextAlignment());
    const allowed = useTypedSelector(({ quickEditorGraphic }) => Boolean(quickEditorGraphic.constraints?.alignment.allowed));
    const graphicId = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.graphicId);
    useEffect(() => setTextAlignment(textApi.getActiveTextGraphic().getTextAlignment()), [textApi, graphicId]);

    useEffect(() => {
        if (textAlignment !== textApi.getActiveTextGraphic().getTextAlignment()) {
            textApi.getActiveTextGraphic().setTextAlignment(textAlignment);
        }
    }, [textApi, textAlignment, graphicId]);

    return {
        isAllowed: allowed,
        setTextAlignment,
        textAlignment,
    };
};

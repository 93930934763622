import { createAction } from '@reduxjs/toolkit';
import type { GraphicConstraints } from './state';
import type { ContentTypeEnum } from '../../../quickEditor/Types/ContentTypeEnum';

export enum QuickEditorGraphicActionType {
    ACTIVE_GRAPHIC_CLEARED = 'ACTIVE_GRAPHIC_CLEARED',
    ACTIVE_GRAPHIC_SET = 'ACTIVE_GRAPHIC_SET',
    GRAPHIC_CONSTRAINTS_LOADED = 'GRAPHIC_CONSTRAINTS_LOADED',
    LOAD_GRAPHIC_CONSTRAINTS = 'LOAD_GRAPHIC_CONSTRAINTS',
}

export const activeGraphicSet = createAction<{ contentType: ContentTypeEnum; graphicId: string }, QuickEditorGraphicActionType>(QuickEditorGraphicActionType.ACTIVE_GRAPHIC_SET);
export const activeGraphicCleared = createAction<void, QuickEditorGraphicActionType>(QuickEditorGraphicActionType.ACTIVE_GRAPHIC_CLEARED);
export const graphicConstraintsLoaded = createAction<GraphicConstraints, QuickEditorGraphicActionType>(QuickEditorGraphicActionType.GRAPHIC_CONSTRAINTS_LOADED);

import type React from 'react';
import PhotoControls from '../Photo/PhotoControls/PhotoControls';
import TextControls from '../Text/TextControls/TextControls';
import { useTypedSelector } from '../../../../productInformation/Store/connectors';
import { ContentTypeEnum } from '../../../Types/ContentTypeEnum';

const ControlsOverlay: React.FC = () => {
    const contentType = useTypedSelector(({ quickEditorGraphic }) => quickEditorGraphic.contentType);

    switch (contentType) {
        case ContentTypeEnum.TYPE_TEXT: // eslint-disable-line @typescript-eslint/no-unsafe-enum-comparison
            return <TextControls />;
        case ContentTypeEnum.TYPE_PHOTO: // eslint-disable-line @typescript-eslint/no-unsafe-enum-comparison
            return <PhotoControls />;
        default:
            return null;
    }
};

export default ControlsOverlay;

import type { TypedMiddleware } from '../store';
import { ctaButtonClicked } from '../UI/action';
import { trackDesignClicked } from './trackerMiddleware';

export const ctaButtonClickedMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (ctaButtonClicked.match(action)) {
        const {
            gallery: { activeGallerySlide },
            product: { algoliaHits, algoliaIndexName, algoliaQueryId, selectedDesignId },
        } = storeApi.getState();

        const selectedDesignHit = algoliaHits && algoliaHits[activeGallerySlide];

        if (!selectedDesignId && algoliaIndexName && selectedDesignHit && algoliaQueryId) {
            // fetch from hits
            trackDesignClicked(algoliaIndexName, selectedDesignHit, algoliaQueryId, activeGallerySlide + 1);
        }
    }

    next(action);
};

import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { PrimaryCtaButton } from './state';

interface UiAction extends UnknownAction {
    payload: string;
    type: UiActionType;
}

export enum UiActionType {
    CTA_BUTTON_CLICKED = 'CTA_BUTTON_CLICKED',
    PERSONALISATION_TEXT_UPDATED = 'PERSONALISATION_TEXT_UPDATED',
    PRIMARY_CTA_BUTTON_SET = 'PRIMARY_CTA_BUTTON_SET',
    PRODUCT_PAGE_LOADED = 'PRODUCT_PAGE_LOADED',
    QUICK_EDITOR_HIDDEN = 'QUICK_EDITOR_HIDDEN',
    QUICK_EDITOR_SHOWN = 'QUICK_EDITOR_SHOWN',
    SCROLLING_ACTIVITY_ALLOWED = 'SCROLLING_ACTIVITY_ALLOWED',
    SCROLLING_ACTIVITY_BLOCKED = 'SCROLLING_ACTIVITY_BLOCKED',
    STICKY_FOOTER_BUTTON_TOGGLED = 'STICKY_FOOTER_BUTTON_TOGGLED',
    SVG_EDITOR_HIDDEN = 'SVG_EDITOR_HIDDEN',
    SVG_EDITOR_SHOWN = 'SVG_EDITOR_SHOWN',
}

export const primaryCtaButtonSet = createAction<PrimaryCtaButton, UiActionType>(UiActionType.PRIMARY_CTA_BUTTON_SET);
export const productPageLoaded = createAction<void, UiActionType>(UiActionType.PRODUCT_PAGE_LOADED);
export const quickEditorHidden = createAction<void, UiActionType>(UiActionType.QUICK_EDITOR_HIDDEN);
export const quickEditorShown = createAction<void, UiActionType>(UiActionType.QUICK_EDITOR_SHOWN);
export const scrollingActivityAllowed = createAction<void, UiActionType>(UiActionType.SCROLLING_ACTIVITY_ALLOWED);
export const scrollingActivityBlocked = createAction<void, UiActionType>(UiActionType.SCROLLING_ACTIVITY_BLOCKED);
export const stickyFooterButtonToggled = createAction<boolean, UiActionType>(UiActionType.STICKY_FOOTER_BUTTON_TOGGLED);
export const ctaButtonClicked = createAction<void, UiActionType>(UiActionType.CTA_BUTTON_CLICKED);
export const svgEditorShown = createAction<void, UiActionType>(UiActionType.SVG_EDITOR_SHOWN);
export const svgEditorHidden = createAction<void, UiActionType>(UiActionType.SVG_EDITOR_HIDDEN);

export const personalisationTextUpdated = createAction<string, UiActionType>(UiActionType.PERSONALISATION_TEXT_UPDATED);

export type { UiAction };

import type React from 'react';
import AddProductToCartButton from '../AddProductToCartButton/AddProductToCartButton';
import StartPersonalisingButton from '../StartPersonalisingButton/StartPersonalisingButton';
import CtaNotifications from '../CtaButtons/CtaNotifications';
import { useTypedSelector } from '../../../Store/connectors';

interface Props {
    isCartEdit: boolean;
    onStartPersonalising: () => void;
}

const StickyFooterButton: React.FC<Props> = ({ isCartEdit, onStartPersonalising }) => {
    const showStickyFooterButton = useTypedSelector(({ ui }) => ui.showStickyFooterButton);
    const isPersonalizable = useTypedSelector(({ product }) => product.isPersonalizable);
    const canBeCombinedWithOtherProductsInCartRequestLoaded = useTypedSelector(({ product }) => product.strongAlcoholCheck.canBeCombinedWithOtherProductsInCartRequestLoaded);
    const canBeCombinedWithOtherProductsInCart = useTypedSelector(({ product }) => product.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart);

    if (!showStickyFooterButton) {
        return null;
    }

    if (!canBeCombinedWithOtherProductsInCartRequestLoaded || (canBeCombinedWithOtherProductsInCartRequestLoaded && !canBeCombinedWithOtherProductsInCart)) {
        return null;
    }

    const addProductToCartButton = (
        <AddProductToCartButton/>
    );

    const startPersonalizingButton = (
        <StartPersonalisingButton
            isCartEdit={isCartEdit}
            onClick={onStartPersonalising}
        />
    );

    return (
        <div className="product-information__sticky-cta">
            <CtaNotifications/>
            {isPersonalizable ? startPersonalizingButton : addProductToCartButton}
        </div>
    );
};

export default StickyFooterButton;

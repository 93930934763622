import type { FC } from 'react';
import classNames from 'classnames';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';
import Button from '../../../../stories/Atoms/Button/Button';
import styles from './IssuuPreview.module.scss';

const IssuuPreview: FC = () => {
    const issuuId = useTypedSelector(({ gallery }) => gallery.issuuId);

    if (!issuuId) {
        return null;
    }

    return <Button data-issuu-id={issuuId} className={classNames(styles.IssuuPreview__button, 'js-issuuButton')} white rounded>
        <Translation pageString="productinformatie" stringId="voorbeeld"/>
    </Button>;
};

export default IssuuPreview;

import type React from 'react';
import { shallowEqual } from 'react-redux';
import Stars from '../../../../general/Icon/Stars';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../Store/connectors';

interface Props {
    minimumRequiredRatingsToShow?: number;
}

const Rating: React.FC<Props> = ({ minimumRequiredRatingsToShow = 5 }) => {
    const {
        ratings,
        ratingsFormatted,
        reviewsCount,
        stars,
    } = useTypedSelector(({ product }) => ({
        ratings: product.ratings,
        ratingsFormatted: product.ratingsFormatted,
        reviewsCount: product.reviewsCount,
        stars: product.stars,
    }), shallowEqual);

    if (minimumRequiredRatingsToShow > ratings) {
        return null;
    }
    const ratingContent = <span data-testid="rating-text" >{ratingsFormatted}{' '}<Translation pageString="productinformatie" stringId="votes" /></span>;

    return (
        <div className="product-rating">
            <Stars amount={stars} />
            {reviewsCount ? <a href="#giftReviews" data-tab-id="giftReviews" data-scrollto-id="giftReviews">{ratingContent}</a> : ratingContent }
        </div>
    );
};

export default Rating;

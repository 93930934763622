import type React from 'react';
import { shallowEqual } from 'react-redux';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import { useTypedSelector } from '../../../Store/connectors';

const SizeChart: React.FC = () => {
    const sizesPopupLink = useTypedSelector(({ product }) => product.sizesPopupLink, shallowEqual);

    if (sizesPopupLink === null) {
        return null;
    }

    return (
        <div className="info-popup-link js-sizePopupLink">
            <a rel="nofollow" href={sizesPopupLink.url} className="fancybox-iframe-large">
                <Icon name="fa-circle-info" />
                {sizesPopupLink.text}
            </a>
        </div>
    );
};

export default SizeChart;

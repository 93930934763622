import type { SearchClient } from 'algoliasearch';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import type { Store } from '../../Store/store';
import AbTestGalleryBlock from '../GalleryBlock/AbTestGalleryBlock';
import InformationBlock from '../InformationBlock/InformationBlock';
import type { WebshopContextData } from '../../../general/WebshopContext/WebshopContextProvider';
import WebshopContextProvider from '../../../general/WebshopContext/WebshopContextProvider';
import Section from '../../../general/Section/Section';
import Usps from '../InformationBlock/Usps/Usps';
import Specifications from '../Specifications/Specifications';
import SpecificationsLink from '../Specifications/SpecificationsLink';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import { productPageLoaded } from '../../Store/UI/action';

export interface ProductInformationProps {
    algoliaSearchClient?: SearchClient;
    algoliaServerState?: InstantSearchServerState;
    locale: string;
    now: Date;
    store: Store;
    webshopContextData: WebshopContextData;
}

const ProductInformation: React.FC<ProductInformationProps> = ({
    algoliaSearchClient,
    algoliaServerState,
    locale,
    store,
    webshopContextData,
}) => {
    store.dispatch(productPageLoaded());

    return <React.StrictMode>
        <WebshopContextProvider data={webshopContextData}>
            <TranslationProvider dictionary={store.getState().translations}>
                <IntlProvider locale={locale}>
                    <ReduxProvider store={store}>
                        <Section noPadding>
                            <div className="product-information">
                                <AbTestGalleryBlock algoliaSearchClient={algoliaSearchClient} algoliaServerState={algoliaServerState} />
                                <InformationBlock />
                            </div>
                        </Section>
                        <SpecificationsLink />
                        <RelatedProducts />
                        <Section>
                            <Usps />
                        </Section>
                        <Specifications />
                    </ReduxProvider>
                </IntlProvider>
            </TranslationProvider>
        </WebshopContextProvider>
    </React.StrictMode>;
};
export default ProductInformation;

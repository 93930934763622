import type React from 'react';
import CloseGraphicButton from '../../../../FloatingActionBar/Button/CloseGraphicButton/CloseGraphicButton';
import NotificationButton from '../../../../FloatingActionBar/Button/NotificationButton/NotificationButton';
import { FloatingActionBar, HorizontalSlot } from '../../../../FloatingActionBar/FloatingActionBar';
import TextAlignmentEditable from '../../../TextAlignmentEditable/TextAlignmentEditable';
import TextColorEditable from '../../../TextColorEditable/TextColorEditable';
import TextContentEditable from '../../../TextContentEditable/TextContentEditable';
import TextEmphasisEditable from '../../../TextEmphasis/TextEmphasisEditable';
import TextFontEditable from '../../../TextFontEditable/TextFontEditable';

interface TextControlsOverlayDisplayProps {
    onTextContentFocus: (lineIndex: number) => void;
}

const TextControlsOverlayDisplay: React.FC<TextControlsOverlayDisplayProps> = ({ onTextContentFocus }) => (
    <>
        <FloatingActionBar horizontalSlot={HorizontalSlot.RIGHT}>
            <CloseGraphicButton />
            <NotificationButton />
        </FloatingActionBar>
        <div className="text-controls__overlay">
            <TextContentEditable
                readOnly
                onFocus={onTextContentFocus}
            />
            <TextFontEditable />
            <TextColorEditable />
            <TextAlignmentEditable />
            <TextEmphasisEditable />
        </div>
    </>
);

export default TextControlsOverlayDisplay;

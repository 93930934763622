import type React from 'react';
import './FailedPhotoControls.scss';
import classNames from 'classnames';
import Translation from '../../../../../general/Translation/Translation';

interface Props {
    allowRetry: boolean;
    continueAction: () => void;
    retryAction: () => void;
}

const FailedPhotoControls: React.FC<Props> = ({ allowRetry, continueAction, retryAction }) => {
    const continueButtonClass = classNames('failed-photo-controls__actions__continue', {
        'failed-photo-controls__actions__continue--no-retry': !allowRetry,
    });

    return (
        <div className="failed-photo-controls__actions">
            { allowRetry
            && (
                <button type="button" className="failed-photo-controls__actions__retry" onClick={retryAction}>
                    <Translation stringId="photo_upload_retry" pageString="GiftEditorWidget" />
                </button>
            ) }

            <button type="button" className={continueButtonClass} onClick={continueAction}>
                <Translation stringId="photo_upload_continue" pageString="GiftEditorWidget" />
            </button>
        </div>
    );
};

export default FailedPhotoControls;

import type React from 'react';

const AlignLeftIcon: React.FC = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 42" width="39" height="42">
        <path
            d="M33.4 34.1L5.6 34c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7l27.7.1c1 0 1.7.8 1.7 1.7.1 1-.7 1.7-1.6
            1.7zM26.2 26.4H5.6c-1 0-1.7-.8-1.7-1.7S4.7 23 5.6 23h20.6c1 0 1.7.8 1.7 1.7s-.7 1.7-1.7
            1.7zM32 18.9H5.6c-1 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7H32c1 0 1.7.8 1.7 1.7s-.7 1.7-1.7 1.7zM19.2 11.3H5.6c-1
            0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7h13.6c1 0 1.7.8 1.7 1.7s-.7 1.7-1.7 1.7z"
        />
    </svg>
);

export default AlignLeftIcon;

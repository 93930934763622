import type React from 'react';
import NotificationBox from '../../../../stories/Molecules/NotificationBox/NotificationBox';
import Translation from '../../../general/Translation/Translation';

const TemperatureWarning: React.FC = () => (
    <NotificationBox customIcon="fa-circle-info" type="info" className="mt-4 mb-4">
        <Translation pageString="productinformatie" stringId="temperature_notification" />
    </NotificationBox>
);

export default TemperatureWarning;

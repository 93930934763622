import type React from 'react';
import NotificationBox from '../../../../../stories/Molecules/NotificationBox/NotificationBox';
import Translation from '../../../../general/Translation/Translation';
import { useWithSiteUrl } from '../../../../general/WebshopContext/WebshopContext';
import { useTypedSelector } from '../../../Store/connectors';

const CtaNotifications: React.FC = () => {
    const canBeCombinedWithOtherProductsInCart = useTypedSelector(({ product }) => product.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart);
    const withSiteUrl = useWithSiteUrl();

    if (canBeCombinedWithOtherProductsInCart) {
        return null;
    }

    return (
        <NotificationBox type='info' customIcon='fa-circle-exclamation' className="mt-4 mb-4">
            <Translation pageString='productinformatie' stringId='combining_liquor_explanation'/>
            {' '}
            <a href={withSiteUrl('/cart')} className="underline pointer">
                <Translation pageString='productinformatie' stringId='go_to_cart'/>
            </a>
        </NotificationBox>
    );
};

export default CtaNotifications;

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface Props {
    label: string;
    onClick: () => void;
}

const ActionButton: React.FC<PropsWithChildren<Props>> = ({ children, label, onClick }) => (
    <button type="button" aria-label={label} onClick={onClick}>
        {children}
    </button>
);

export default ActionButton;

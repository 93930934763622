import { withSiteUrl } from '../api/url';
import fetch from '../api/fetch';

const recentlyViewed = (productId: number): void => {
    fetch(withSiteUrl(`product-page/recently-viewed?productId=${productId}`), {
        method: 'POST',
    }).catch(() => null);
};

export default recentlyViewed;

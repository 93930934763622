import type { Tracker as Analytics } from '@yoursurprise/segment-analytics';

interface EventProperties {
    cartEdit: boolean;
    category: string;
    editorType: string;
    label: string;
    partnerId: number;
    previousProductId?: number;
    productId: number;
    variantId: number;
}

export interface TrackerOptions {
    isCartEdit: boolean;
    partnerId: number;
    productId: number;
    variantId: number;
}

export class Tracker {
    private readonly analytics: Analytics;

    private readonly editorType = 'Gift Editor Widget';

    private readonly googleAnalyticsEventCategory = 'gift-editor-widget';

    private options: TrackerOptions;

    constructor(analytics: Analytics, options: TrackerOptions) {
        this.analytics = analytics;
        this.options = options;
    }

    public editorOpened(): void {
        this.analytics.track('Editor Opened', this.getBaseEventProperties());
    }

    public editorClosed(): void {
        this.analytics.track('Editor Closed', this.getBaseEventProperties());
    }

    public personalisationStarted(): void {
        this.analytics.track('User Started Personalising', this.getBaseEventProperties());
    }

    public personalisationConfirmed(): void {
        this.analytics.track('Personalisation Confirmed', this.getBaseEventProperties());
    }

    public productSwitched(productId: number): void {
        const properties = this.getBaseEventProperties();
        properties.productId = productId;
        properties.label = String(productId);
        properties.previousProductId = this.options.productId;

        this.analytics.track('Product Switched', properties);
    }

    public setOptions(options: TrackerOptions): void {
        this.options = options;
    }

    private getBaseEventProperties(): EventProperties {
        const { isCartEdit, partnerId, productId, variantId } = this.options;

        return {
            cartEdit: Boolean(isCartEdit),
            category: this.googleAnalyticsEventCategory,
            editorType: this.editorType,
            label: String(productId),
            partnerId,
            productId,
            variantId,
        };
    }
}

import type React from 'react';
import Icon from '../../../../../../stories/Atoms/Icon/Icon';
import Translation from '../../../../../general/Translation/Translation';

export interface PromotionPriceProps {
    dealPromotionPrice: number;
    hasDealPromotionShipping: boolean;
    price: string;
    shippingCostsText: string;
}

export const PromotionPrice: React.FC<PromotionPriceProps> = ({ dealPromotionPrice, hasDealPromotionShipping, price, shippingCostsText }) => {
    if (dealPromotionPrice) {
        return (
            <div className="article-price">
                <span className="article-price__total">
                    { price }
                </span>
            </div>
        );
    }

    return (
        <>
            <span className="already_payed js-dealPromotionPayed">
                <Icon name="fa-check" />
                {' '}
                <Translation pageString="productinformatie" stringId="betaald" />
            </span>

            {hasDealPromotionShipping && (
                <p className="small-text">
                    <Translation pageString="productinformatie" stringId="dealpromotion_incl" />
                </p>
            )}
            {!hasDealPromotionShipping && (
                <div className="article-price__shipping">
                    { shippingCostsText }
                </div>
            )}
        </>
    );
};

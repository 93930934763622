import type { ChangeEvent } from 'react';
import type React from 'react';
import { useCallback, useState } from 'react';
import { useDesigns } from '../../../Hooks/UseDesigns';
import Slider from '../../General/Slider/Slider';
import DesignFilter from '../DesignFilter/DesignFilter';
import DesignTile from '../../General/DesignTile/DesignTile';
import './DesignPicker.scss';

const DesignPicker: React.FC = () => {
    const { availableDesigns, availableThemes, designId, designsBackgroundColor, setDesignId } = useDesigns();

    const [activeDesignCategory, setActiveDesignCategory] = useState<number | undefined>(availableThemes.find((theme) => theme.selected)?.id);
    const [firstDesignId] = useState<number>(designId);

    const sortedDesigns = useCallback(
        () => (
            [...availableDesigns].sort((a, b) => Number(b.id === firstDesignId) - Number(a.id === firstDesignId))),
        [availableDesigns, firstDesignId],
    );

    const filteredSortedDesigns = useCallback(() => {
        if (!activeDesignCategory) {
            return sortedDesigns();
        }

        return [...sortedDesigns()].filter((design) => design.themeIds.includes(activeDesignCategory));
    }, [activeDesignCategory, sortedDesigns]);

    if (!(sortedDesigns().length > 1)) {
        return null;
    }

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setActiveDesignCategory(Number(e.target.value));
    };

    const designTiles = filteredSortedDesigns()
        .map((design) => (
            <DesignTile
                key={design.id}
                design={design}
                active={design.id === designId}
                backgroundColor={designsBackgroundColor}
                setActiveDesign={setDesignId}
            />
        ));

    return (
        <div className="design-picker">
            {availableThemes.length > 1
            && (
                <DesignFilter
                    activeDesignCategory={activeDesignCategory}
                    handleChange={handleChange}
                    themes={availableThemes}
                />
            )}
            <div className="design-picker__tiles">
                <Slider>
                    {designTiles}
                </Slider>
            </div>
        </div>
    );
};

export default DesignPicker;
